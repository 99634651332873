
<div class="hold-transition bg-img login-page-bg" style="background-image: url(assets/images/banner1.jpg)"
	data-overlay="1">
	<div class="container h-p100 for_mobile_view">
		
		<div class="row align-items-center justify-content-md-center h-p100">
			<div class="col-12">


				<div class="row no-gutters justify-content-md-center">
					<div class="col-lg-12 col-md-12 col-12">
						<div class="content-top-agile content-top-agile-white h-p100">
							<img src="assets/images/short-logo.png">
						</div>
					</div>
					<div class="col-lg-12 col-md-12 col-12">
						<h4 class="page-heada">Login</h4>
						<div class="p-40 bg-white content-bottom h-p100 login-bg">
							<!-- <span *ngIf="isFormSubmitted && pageMessage" class="error pdl-40">
								<span *ngIf="pageMessage">{{ pageMessage }}</span>
							</span> -->
							<!--<h3 class="text-center mb-4 mt-0 heading_border">Account Login</h3>-->
							<form class="form-element" [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
								<div class="form-group">
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text bg-info border-info"><i class="ti-user"></i></span>
										</div>
										<input type="text" class="form-control pl-15" placeholder="Username" tabindex=1
											id="username"
											[ngClass]="{ 'is-invalid': isFormSubmitted && loginFormControl.username.errors }"
											formControlName="username" required>
									</div>
									<span *ngIf="isFormSubmitted && loginFormControl.username.errors"
										class="error pdl-40">
										<span *ngIf="loginFormControl.username.errors.required">{{ validationMessage.login.usernameRequired }}</span>
									</span>
								</div>
								<div class="form-group">
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text bg-info border-info"><i class="ti-lock"></i></span>
										</div>
										<input type="password" class="form-control pl-15" placeholder="Password" tabindex=2
											id="password"
											[ngClass]="{ 'is-invalid': isFormSubmitted && loginFormControl.password.errors }"
											formControlName="password" required>
									</div>

									
									<span *ngIf="isFormSubmitted && loginFormControl.password.errors"
										class="error pdl-40">
										<span *ngIf="loginFormControl.password.errors.required">{{ validationMessage.login.passwordRequired }}</span>
									</span>
								</div>
								<div class="form-group">
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text bg-info border-info"><i class="fa fa-building-o"></i></span>
										</div>
										<div class="form-control pl-15"  style="top: 1px;" 
											*ngFor="let companyList of companyListObj" >
											<div class="mt-2">
												{{companyList.companyName}}
											</div>
										</div>
									</div>
								</div>
								<!-- <div class="form-group">
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text bg-info border-info"><i class="fa fa-building-o"></i></span>
										</div>
										<select class="form-control pl-15" placeholder="Select Company"  
										[ngClass]="{ 'is-invalid': isFormSubmitted && loginFormControl.company.errors }" 
										formControlName="company" >
											<option value="">Select Company</option>
											<option *ngFor="let companyList of companyListObj; let i=index;" [value]="i">{{companyList.companyName}}</option>
										</select>
									</div>
									<span *ngIf="isFormSubmitted && loginFormControl.company.errors" class="error pdl-40">
										<span *ngIf="loginFormControl.company.errors.required">{{ validationMessage.login.companyRequired }}</span>
									</span>
								</div> -->
								<!--<recaptcha [formControl]="myRecaptcha"></recaptcha> -->
								<div class="clear"></div>
								<div class="row">
									<div class="col-7">
										<span *ngIf="isFormSubmitted && pageMessage" class="error pdl-40 ">
											<span *ngIf="pageMessage">{{ pageMessage }}</span>
										</span>
										<!--  <div class="checkbox">
									<input type="checkbox" id="basic_checkbox_1" >
									<label for="basic_checkbox_1">Remember Me</label>
								  </div>-->
									</div>
									<!-- /.col -->
									<div class="col-5">

										<div class="fog-pwd text-right">
											<a routerLinkActive="active" routerLink="/forgot_password"
												class="underline_txt"><i class="ion ion-locked"></i> Forgot
												Password?</a><br>
										</div>
									</div>
									<!-- /.col -->
									<div class="col-12 text-center">
										<button tabindex=3 class="btn btn-lg btn-primary btn-block margin-top-10 sign-btn">SIGN
											IN</button>
									</div>
									<!-- /.col -->
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>