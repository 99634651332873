import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse

} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from "./auth.service";
import { ApiService } from 'src/app/services/api.service';
import { UtilityService } from 'src/app/services/utility.service';

import { SessionStorageService } from "../storage/session-storage.service";
import { LocalStorageService } from "../storage/local-storage.service";

import Swal from 'sweetalert2'

@Injectable({
  providedIn: "root"
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,    
    // private http: HttpClient,    
    private apiService: ApiService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private utilityService: UtilityService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = {};
    headers["ApplicationUserAgent"] = "dmi-web";
    if (!req.url.includes("/auth/")) {
      headers["Authorization"] = "Bearer " + this.authService.getToken();
      // headers["Content-Type"] = "application/json;";
    }
    else{
      if(req.body && req.body.company && req.body.company.companySlug){
        headers["CompanySlug"] = req.body.company.companySlug;
      }else{
        headers["CompanySlug"] = "DMI_HFC";
      }
    }
    if(req.url.includes("/loan/")){
      headers["Content-Type"] = "application/json;";
    }
    if (!req.url.includes("/document/")) {
      headers["Content-Type"] = "application/json;charset=UTF-8";
    }
    if(req.url.includes("/loan/reschedule/batch/upload-file")){
      delete headers["Content-Type"];
    }
    const newReq = req.clone({
      setHeaders: headers
    });
    return next.handle(newReq).pipe(
      map((event: HttpEvent<any>) => {          
        return event;
      }),
      catchError((error) => {
          let handled: boolean = false;
          let showMsg: boolean = true;
          if (error instanceof HttpErrorResponse) {                  
            if (error.error instanceof ErrorEvent) {
              console.error("Error Event");
            } else {
              console.log(`error status : ${error.status} ${error.statusText}`);
              switch (error.status) {
                case 0:      //bad internet
                  this.sessionStorageService.deleteAll();
                  this.router.navigate(["/login"]);
                  handled = true;
                  Swal.fire({
                    allowOutsideClick: false,
                    text: 'just text',
                    html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">Session expired due to inactivity or bad internet connection.<br><br> Please login again.</p>',
                  });
                  break;
                case 440:     //
                  this.apiService.logoutUser();
                  this.sessionStorageService.deleteAll();
                  this.utilityService.saveCurrentURL(this.router.url);
                  this.router.navigate(["/login"]);
                  handled = true;
                  Swal.fire({
                    allowOutsideClick: false,
                    text: 'just text',
                    html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + error.error.responseMsg + '</p>',
                  })
                  break;
                case 403:     // password chnage require
                  this.router.navigate(["/change_password"]);
                  handled = true;
                  Swal.fire({
                    allowOutsideClick: false,
                    text: 'just text',
                    html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + error.error.responseMsg + '</p>',
                  })
                  break;
                case 429:     // too many request
                  Swal.fire({
                    allowOutsideClick: false,
                    text: 'just text',
                    html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + error.error.responseMsg + '</p>',
                  })
                break;
                case 504:     // api taking too much time
                  Swal.fire({
                    allowOutsideClick: false,
                    text: 'just text',
                    html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">Unexpected error occured, Kindly contact administrator.</p>',
                  })
                break;
              }
            }
            } else {
              // console.error("Other Errors");
            }
     
            if (handled) {
              console.log('return back ');
              return of(error);
            } else {
              return throwError(error);
            }
          
          // console.log('error intercepter')
          // console.error(error);
          // return throwError(error);
        })
      );
  }
}





