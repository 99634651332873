import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators,FormGroupDirective } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';
import { ApiService } from 'src/app/services/api.service';
import { APIConstant } from "src/app/constants/apiConstants";
import { BaseComponent } from 'src/app/components/basecomponent/basecomponent.component';
import { ValidationMessage } from "src/app/constants/validationMessage";
import { NavigationConstants } from "src/app/constants/navigationConstant";
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent implements OnInit {

  LoginDashboardData: any = [];
  SanctionDashboardData: any = [];
  DisbursalDashboardData: any = [];
  showLogin: boolean = true;
  showSanction: boolean = false;
  showDisbursal: boolean = false;
  loginDataloaded:boolean = false;
  sanctionDataloaded:boolean = false;
  disbursementDataloaded:boolean = false;
  searchText = '';
  formPermissionData:any;
  // totalMonthlyAmount: number = 0;
  // totalMonthlyFile: number = 0;
  // totalTodayAmount: number = 0;
  // totalTodayFile: number = 0;
  // totalTargetAmount: number = 0;
  // totalTargetFile: number = 0;


  constructor(public _fb: FormBuilder, private modalService: NgbModal,
    private utilityService: UtilityService,private apiService: ApiService) {
    super(utilityService);
    this.formPermissionData = this.utilityService.getPermission('Dashboard', 'Dashboard');
    console.log(this.formPermissionData);    
    if(this.formPermissionData && this.formPermissionData.Dashboard && this.formPermissionData.Dashboard.isView==true){
  
    } else {
      this.utilityService.reDirectToUrl(NavigationConstants.MYTASK);
    }
  }

  ngOnInit() {    
    this.loginDashboard();
  }

  loginDashboard() {
    this.showLogin = true;
    this.showSanction = false;
    this.showDisbursal = false;
    if(this.loginDataloaded === false ){
      this.utilityService.showLoader();
    this.apiService.get(APIConstant.GETLOGINDASHBOARD,{}, resObj => {
      this.LoginDashboardData = resObj.webLoginDashboardChildrens;
      this.loginDataloaded = true;
      this.utilityService.hideLoader();
    },err=>{
      this.utilityService.hideLoader();
    });
    }else return;
    
  }

  sanctionDashboard() {
    this.showLogin = false;
    this.showSanction = true;
    this.showDisbursal = false;
    if(this.sanctionDataloaded === false){
      this.utilityService.showLoader();
      this.apiService.get(APIConstant.GETSANCTIONDASHBOARD,{}, resObj => {
        this.SanctionDashboardData = resObj.webLoginDashboardChildrens;
        this.sanctionDataloaded = true;
        this.utilityService.hideLoader(); 
      },err=>{
        this.utilityService.hideLoader();
      });
    }else return;
  }

  disbursalDashboard() {
    this.showLogin = false;
    this.showSanction = false;
    this.showDisbursal = true;
    if(this.disbursementDataloaded === false){
      this.utilityService.showLoader();
      this.apiService.get(APIConstant.GETDIBSURDALDASHBOARD,{}, resObj => {
        this.DisbursalDashboardData = resObj.webLoginDashboardChildrens;
        this.disbursementDataloaded = true;
        this.utilityService.hideLoader();
      },err=>{
        this.utilityService.hideLoader();
      });
    }else return 
  }

}
