export const CustomStorage = {
  sessionKeys: {
    authorizationSequence: 'authorizationSequence',
    userBranches: 'userBranches',
    roleId: 'roleId',
    PriveldgeList: 'PriveldgeList',
    userLoginDetails: 'userLoginDetails',
    logeedinUserData:'authorizationSequence',
    documentCodes:'documentCodes',
    redirectFrom:'redirectFrom',
    dcbRedirect:'dcbRedirect',
    dcbSearchData:'dcbSearchData',
    dcbDataObj:'dcbDataObj',
    loginRedirect:'loginRedirect',
  },
  localKeys: {
    token: 'token',
    testkey: 'testkey',
    masters: 'masters',
    version: 'version'
  }
};
