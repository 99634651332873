<div class="wrapper">
  <header class="main-header">
    <!-- Desktop menu start-->
    <div class="desktop_menu web-responsive-header-sec" *ngIf="(urlKey != true)">
      <!-- Logo -->
      <a href="#" class="logo_blk">
        <img src="assets/images/logo.jpg" alt="logo">
      </a>
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <ul class="nav nav-pills header_main_menu">
          <li class="nav-item" *ngFor="let module of moduleList; let i = index;">
            <a class="nav-link"  *ngIf="module && module.application==true" [ngClass]="{'active': i === selectedIndexVal}">
              <i class="ti-pencil-alt"></i>{{module.label}}
              <li class="submenu" *ngFor="let checkModule of sideMenuList;">
                <a href="#" *ngIf="module.label == checkModule.moduleName">
                  <ng-container *ngFor="let submenu of checkModule.subModuleList;let val = index" >
                    <li *ngIf="submenu.isView == true" (click)="selectedSubMenuIndex(val,submenu)">
                      <a class="submenu-link"  [routerLink]="[submenu.path,loanNumber]" [ngClass]="{'active': i === selectedIndexVal && val === selectedSubMenuIndexVal}" *ngIf="submenu.path!='/collateral/builderapf'">
                        <i [ngClass]="'mdi1 ' + submenu.screenName"></i> {{submenu.screenDisplayName}}
                      </a>
                      <a class="submenu-link"  [routerLink]="[submenu.path]" [ngClass]="{'active': i === selectedIndexVal && val === selectedSubMenuIndexVal}" *ngIf="submenu.path=='/collateral/builderapf'">
                        <i [ngClass]="'mdi1 ' + submenu.screenName"></i> {{submenu.screenDisplayName}}
                      </a>
                    </li>
                  </ng-container>
                </a>
              </li>
            </a>
            <a class="nav-link"  *ngIf="module && module.application!=true" [ngClass]="{'active': i === selectedIndexVal}">
              <i class="ti-pencil-alt"></i>{{module.label}}
              <li class="submenu" *ngFor="let checkModule of sideMenuList">
                <a href="#" *ngIf="module.label == checkModule.moduleName">
                  <ng-container *ngFor="let submenu of checkModule.subModuleList;let val = index">
                    <li *ngIf="submenu.isView == true" (click)="selectedSubMenuIndex(val,submenu)">
                      <a class="submenu-link"  [routerLink]="[submenu.path]" [ngClass]="{'active': i === selectedIndexVal && val === selectedSubMenuIndexVal}" *ngIf="submenu.path!='/mytask/loan-journey'">
                        <i [ngClass]="'mdi1 ' + submenu.screenName"></i> {{submenu.screenDisplayName}}
                      </a>
                      <a class="submenu-link"  [routerLink]="[submenu.path,loanNumber]" [ngClass]="{'active': i === selectedIndexVal && val === selectedSubMenuIndexVal}" *ngIf="submenu.path=='/mytask/loan-journey'">
                        <i [ngClass]="'mdi1 ' + submenu.screenName"></i> {{submenu.screenDisplayName}}
                      </a>
                    </li>
                  </ng-container>
                </a>
              </li>
            </a>
          </li>
        </ul>
        <div class="navbar-custom-menu">
          <ul class="nav navbar-nav">
            <li class="dropdown" *ngIf="showDashboard">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                <i title="Dashboard" class="mdi mdi-view-dashboard passwordIcon" (click)="dashboard()" *ngIf="showDashboard"></i>
                <span>&nbsp;|</span>
              </a>
            </li>

            <li class="dropdown user user-menu">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <!-- {{userNameShort}} -->
                <img src="assets/images/kycuser.png" class="user-image rounded-circle" alt="User Image">
              </a>
              <ul class="dropdown-menu animated flipInY">
                <!-- User image -->
                <li class="user-header bg-img user-bg-color">
                  <div class="flexbox align-self-center">
                    <!-- <img src="assets/images/avatar/7.jpg" class="float-left rounded-circle" alt="User Image"> -->
                    <!-- <span class="profile-icon-inner">{{userNameShort}}</span> -->
                    <h4 class="user-name align-self-center">
                        <span>Hi! {{userName}}</span>
                        <!-- <small>samuel@gmail.com</small> -->
                    </h4>
                  </div>
                </li>
                <!-- Menu Body -->
                <li class="user-body">
                  <a class="dropdown-item" href="javascript:void(0)" (click)="changePassword()" *ngIf="showChangePassword">
                    <i class="mdi mdi-key"></i> Change Password</a>
                  <div class="dropdown-divider" *ngIf="showChangePassword"></div>
                  <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                    <i class="mdi mdi-logout"></i> Logout</a>
                  <!-- <div class="p-10">
                    <a href="javascript:void(0)" class="btn btn-sm btn-rounded btn-primary" (click)="logout()">
                      <i class="mdi mdi-logout"></i>Logout</a>
                  </div> -->
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </nav>
    </div>

    <div class="desktop_menu mob-responsive-header-sec" *ngIf="(urlKey != true)">
      <div class="mob-header-section">
        <div>
        <a href="#" class="logo_blk" [ngClass]="router.url === '/dashboard' ? 'desktop_menu-dashboard-logo' : 'logo_blk'">
          <img src="assets/images/dmi.png" alt="logo">
        </a>
        <mat-icon aria-hidden="false" aria-label="Example home icon" class="main-menu-icon" (click)="navService.openNav()" *ngIf="router.url !== '/dashboard'">menu</mat-icon>
        </div>
        <div *ngIf="userName" class="web-custom-hMenu-section"><span class="mob-welcome-text">Welcome</span><p class="mob-header-username">{{userName}}</p></div>
        <a class="mob-selected-header">{{activeLinkName}}</a>
        <div>
        <nav class="navbar navbar-static-top">
          <i title="Dashboard" class="mdi mdi-view-dashboard passwordIcon" (click)="dashboard()"></i>
          <mat-icon aria-hidden="false" aria-label="Example home icon" class="v-menu-icon" [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu #menu="matMenu" class="responsive-header-menu-bg nav-pills">
            <ul class="nav nav-pills header_main_menu">
            <li class="nav-item" *ngFor="let module of moduleList">
              <a class="nav-link" routerLinkActive="active" [routerLink]="[module.path,loanNumber]" *ngIf="module && module.application==true" (click)="getActiveLink(module)">
                <i class="ti-pencil-alt"></i>{{module.label}}
              </a>
              <a class="nav-link" routerLinkActive="active" [routerLink]="[module.path]" *ngIf="module && module.application!=true" (click)="getActiveLink(module)">
                <i class="ti-pencil-alt"></i>{{module.label}}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="changePassword()">
                <i class="ti-pencil-alt"></i>Change Password
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="logout()">
                <i class="ti-pencil-alt"></i>Logout
              </a>
            </li>
            </ul>
          </mat-menu>
        </nav>
        </div>
        </div>
    </div>
    <!-- Desktop menu end-->
  </header>
