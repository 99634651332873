<div class="hold-transition bg-img login-page-bg" style="background-image: url(/assets/images/banner1.jpg)"
  data-overlay="1">
  <div id="wrapper" class="otpscreen">
    <div id="dialog">
      <h3>Please enter the 4-digit verification code we sent via SMS:</h3>
      <span>(we want to make sure it's you before we contact )</span>
      <form [formGroup]="verify_otp" #formOtpVerify="ngForm" (ngSubmit)="verifyOTP(formOtpVerify)" autocomplete="off">
        <div class="row">
          <div class="form-group col-md-3">
            <mat-form-field>
              <input matInput numbersOnly type="text" class="otpInput"
                [ngClass]="{ 'is-invalid': otpVerify && otpForm.Ba_Otp1.errors }" formControlName="Ba_Otp1"
                maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onKeyUp($event.value,'Ba_Otp1')"
                #Ba_Otp1 />
            </mat-form-field>
          </div>
          <div class="form-group col-md-3">
            <mat-form-field>
              <input matInput numbersOnly type="text" class="otpInput"
                [ngClass]="{ 'is-invalid': otpVerify && otpForm.Ba_Otp2.errors }" formControlName="Ba_Otp2"
                maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onKeyUp($event.value,'Ba_Otp2')"
                #Ba_Otp2 />
            </mat-form-field>
          </div>
          <div class="form-group col-md-3">
            <mat-form-field>
              <input matInput numbersOnly type="text" class="otpInput"
                [ngClass]="{ 'is-invalid': otpVerify && otpForm.Ba_Otp3.errors }" formControlName="Ba_Otp3"
                maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onKeyUp($event.value,'Ba_Otp3')"
                #Ba_Otp3 />
            </mat-form-field>
          </div>
          <div class="form-group col-md-3">
            <mat-form-field>
              <input matInput numbersOnly type="text" [ngClass]="{ 'is-invalid': otpVerify && otpForm.Ba_Otp4.errors }"
                formControlName="Ba_Otp4" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}"
                class="Otp4_valsdasd otpInput" (keyup)="onKeyUp($event.value,'Ba_Otp4')" #Ba_Otp4 />
            </mat-form-field>
          </div>
          <span class="error" *ngIf="isInvalid">
            <span
              *ngIf="otpForm.Ba_Otp1.errors.required || otpForm.Ba_Otp2.errors.required || otpForm.Ba_Otp3.errors.required || otpForm.Ba_Otp4.errors.required">{{ validationMessage.common.OTP_ERROR }}</span>
          </span>
          <br>
        </div>
        <div class="form-group" align="center">
          <button class="btn btn-primary btn-embossed custombutn" #Ba_Otp5>Verify OTP</button>
        </div>
      </form>
      <div>
        Didn't receive the code?<br>
        <a href="#">Send code again</a><br>
        <!-- <a routerLink="/forgot_password">Change User Details</a> -->
        <a routerLink="/forgot_password">Back</a>
      </div>
    </div>
  </div>
</div>