<div class="container" style="margin-top: 70px; background-color: #fff">
  <div class="row">
    <mat-card
      style="margin-top: 15px; border: 1px solid black"
      class="chart col-md-6 col-md-offset-5"
      *ngFor="let chart of barCharts"
    >
      <mat-card-header>{{ chart.title }}</mat-card-header>
      <mat-card-content>
        <div style="display: block">
          <canvas
            baseChart
            [datasets]="chart.data.chartData"
            [labels]="chart.data.labels"
            [options]="chart.options"
            [plugins]="barChartPlugins"
            [legend]="chart.data.legend"
            [chartType]="chart.type"
            [colors]="chart.data.colors"
            (chartClick)="chartClicked($event)"
          >
          </canvas>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card
      style="margin-top: 15px; border: 1px solid black"
      class="chart col-md-6 col-md-offset-5"
      *ngFor="let chart of lineCharts"
    >
      <mat-card-header>{{ chart.title }}</mat-card-header>
      <mat-card-content>
        <div style="display: block">
          <canvas
            baseChart
            [datasets]="chart.data.chartData"
            [labels]="chart.data.labels"
            [options]="chart.options"
            [plugins]="barChartPlugins"
            [legend]="chart.data.legend"
            [chartType]="chart.type"
            (chartClick)="chartClicked($event)"
          >
          </canvas>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card
      style="margin-top: 15px; border: 1px solid black"
      class="col-md-6" [ngClass]="(chart.data.labels).length >= 10 ? 'col-md-5' : 'col-md-5'"
      *ngFor="let chart of pieCharts"
    >
      <mat-card-header>{{ chart.title }}</mat-card-header>
      <mat-card-content>
        <div style="display: block"
        >
          <canvas
            baseChart
            [data]="chart.data.chartData"
            [labels]="chart.data.labels"
            [chartType]="chart.type"
            [options]="chart.data.options"
            [legend]="chart.data.legend"
            [colors]="pieChartColors"
            (chartClick)="chartClicked($event)"
            style="z-index: 2"
          ></canvas>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
