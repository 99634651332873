import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'total'
})
export class TotalPipe implements PipeTransform {

  transform(items: any[], field: string): Number {
    if(!items) return 0;  
    return items.reduce((a, b) => a + (Number(b[field]) || 0), 0);
   }

}
