import { Injectable, isDevMode } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "./api.service";
import { LocalStorageService } from "../storage/local-storage.service";
import { SessionStorageService } from "../storage/session-storage.service";
import { CustomStorage } from "../storage/storageKeys";
import { APIConstant } from "../constants/apiConstants";
import { NavigationConstants } from "../constants/navigationConstant";
import { DocumentUploadService } from "src/app/services/document-upload.service";
import { SelectedApplication } from "../models/selected-application.model";
// import { element } from '@angular/core/src/render3';
declare var $: any;

@Injectable({
  providedIn: "root"
})
export class UtilityService {
  // show and hide transtion on click
  private login = new Subject<any>();
  private loader = new Subject<any>();
  private appSelected = new BehaviorSubject<any>({});
  globalApplicationSelected = this.appSelected.asObservable();
  private addressSelected = new BehaviorSubject<any>({});
  globalApplicantAddress = this.addressSelected.asObservable();
  private firingSelected = new BehaviorSubject<any>({});
  globalFiringSelected = this.firingSelected.asObservable();

  submenuPriveldgeList: any = {};
  componentPrivileg: any = [];
  currentSubModuleObject: any = [];
  sectiongoupname: any;
  selectedApplication: any = {};
  hexBase = 15;
  isSidebarOpen:boolean = false;
  userLoginDetails: any = {};
  loaderObject:any=[];
  codeIDList: any;
  documentSubTypeList: any;
  applicantNumber:any;
  salarySlipStatus:any;

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private toastr: ToastrService,
    private _uploadDocumentService: DocumentUploadService
  ) { }

  saveCurrentURL(url){
    let urlLink=this.localStorageService.getData(CustomStorage.sessionKeys.loginRedirect);
    if(!urlLink && (url!='/' && url!='/login')){
      this.localStorageService.setData(CustomStorage.sessionKeys.loginRedirect,url);
    }
  }

  checkScreenPermission(urlGet){
    let moduleObj={
      "all-dashboard":"Dashboards",
      "loan":"Login",
      "sanction":"Sanction",
      "collateral":"Collateral",
      "master":"Master",
      "mytask":"My Task",
      "postdisbursal":"Post Disbursal",
      "report":"Report",
      "disbursal":"Disbursal",
      "change_password":"Change Password",
      "dashboard":"Dashboard",
    }
    let redirectURLArr=urlGet.split('/');
    if(redirectURLArr.length>2 && redirectURLArr[1] && redirectURLArr[1]!=''){
      let moduleName=redirectURLArr[1].replace('/','');
      let submenuPriveldgeList: any[] = JSON.parse(sessionStorage.getItem("PriveldgeList"));
      for (const appModule of submenuPriveldgeList) {
        if(appModule.moduleName==moduleObj[moduleName]){
          for (const subModule of appModule.subModuleList) {
            if(urlGet.includes(subModule.path) && subModule.isView){
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  shareApplicationSelected(appObj){
    this.appSelected.next(appObj);
  }
  shareFiringData(firingDataObj){
    this.firingSelected.next(firingDataObj);
  }
  shareApplicantAddress(addressObj){
    this.addressSelected.next(addressObj);
  }
  notify(noticationType: string, message: string, title?: string) {
    this.toastr.clear();
    this.toastr[noticationType](message, title || noticationType, {
      positionClass: "toast-bottom-right",
      timeOut: 5000,
      maxOpened: 1,
      preventDuplicates: true,
      autoDismiss: true
    });
  }
  notifySuccess(message: string, title?: string) {
    this.notify("success", message, title);
  }
  notifyError(message: string, title?: string) {
    this.notify("error", message, title);
  }

  log(message: string, logType?: string) {
    if (isDevMode()) {
      if (logType === "error") {
      } else {
      }
    }
  }
  logInfo(message: string) {
    this.log(message, "");
  }
  logError(message: string) {
    this.log(message, "error");
  }

  onLoader(): Observable<any> {
    return this.loader.asObservable();
  }
  showLoader(loaderName='',loaderMsg='') {
    let messageShow=loaderMsg;
    if(loaderMsg==''){
      messageShow=loaderName;
    }
    if(loaderName!=''){
      this.loaderObject.push({
        [loaderName]:messageShow
      });
    }
    this.loader.next({ show: true,loaderMessage:loaderMsg });
  }
  hideLoader(loaderName='') {
    if(loaderName!=''){
      this.loaderObject=this.removeArrayObj(this.loaderObject, loaderName, loaderName);
    }
    let loaderMsg='';
    if(this.loaderObject.length==0){
      this.loader.next({ show: false,loaderMsg:loaderMsg });
    } else {
      let keyObj=Object.keys(this.loaderObject[0]);
      if(keyObj[0]!=this.loaderObject[0][keyObj[0]]){
        loaderMsg=this.loaderObject[0][keyObj[0]];
      }
      this.loader.next({ show: true, loaderMessage:loaderMsg });
    }
  }
  removeArrayObj(arr, attr, value){
      var i = arr.length;
      while(i--){
        if( arr[i]  && arr[i].hasOwnProperty(attr) ){
            arr.splice(i,1);
        }
      }
      return arr;
  }

  loadMasters() {
    this.loadDocumentCode();
    //this.notifySuccess("Loading data...", "Loading");
    this.apiService.get(APIConstant.ALLMASTERS, {}, res => {
      if (res) {
        const masterJson = JSON.stringify(res);
        this.localStorageService.setData(
          CustomStorage.localKeys.masters,
          masterJson
        );
      }
    });
  }
  loadDocumentCode(){
    this.apiService.get(APIConstant.DOCUMENT_AND_TYPE_LIST, {}, resObj => {
      const masterDocumentCode = JSON.stringify(resObj.documentTypes);
        this.sessionStorageService.setData(CustomStorage.sessionKeys.documentCodes,masterDocumentCode);
    });
  }

  getMasters() {
    return JSON.parse(
      this.localStorageService.getData(CustomStorage.localKeys.masters)
    );
  }

  getAuthorizationSequence(){
    return JSON.parse(
      this.sessionStorageService.getData(CustomStorage.sessionKeys.authorizationSequence)
    );
  }

  onLogin(): Observable<any> {
    return this.login.asObservable();
  }
  setLoggedIn() {
    this.login.next({ logged: true });
  }

  redirectToDashboard(showMsg=true) {
    if(showMsg){
      this.logInfo("Login successful...");
    }
    let redirectURL=this.localStorageService.getData(CustomStorage.sessionKeys.loginRedirect);
    if(redirectURL && redirectURL!='' && this.checkScreenPermission(redirectURL)){
      this.localStorageService.deleteData(CustomStorage.sessionKeys.loginRedirect);
      this.router.navigate([redirectURL]);
    } else {
      this.router.navigate([NavigationConstants.MYTASK]);
    }
  }

  redirectToChangePassword() {
    this.router.navigate([NavigationConstants.CHANGEPASSWORD]);
  }

  logout() {
    this.clearSession();
    // this.apiService.post(APIConstant.LOGOUT,{},resObj=>{
    // });
    this.router.navigate([NavigationConstants.LOGIN]);

  }
  clearSession() {
    this.sessionStorageService.deleteAll();
  }

  encrypt(value: number) {
    return this.Base64EncodeUrl(btoa(value.toString(this.hexBase)));
  }

  decrypt(value: string) {
    try {
      return parseInt(this.Base64DecodeUrl(atob(value)), this.hexBase);
    } catch {
      this.router.navigate(["/404"]);
    }
    return 0;
  }

  Base64EncodeUrl(str) {
    return str
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/\=+$/, "");
  }
  Base64DecodeUrl(str) {
    if (str.length % 4 != 0) str += "===".slice(0, 4 - (str.length % 4));
    return str.replace(/-/g, "+").replace(/_/g, "/");
  }

  reloadUrl(){
    this.router.navigated = false;
    this.router.navigate([this.router.url]);
  }

  reDirectToUrl(url){
    this.router.navigate([url]);
  }

  getFormatedDateTxt(dateVal,format=''){
    if(dateVal!=null && dateVal!='null' && dateVal!='undefined' && dateVal!=''){
      let statusDate= new Date(dateVal);
      let dayGen=statusDate.getUTCDate();
      let monthGen=statusDate.getMonth()+1;
      let yearGen=statusDate.getFullYear();
      let statusDateTxt=dayGen+'-'+monthGen+'-'+yearGen;
      if(format=='Y-m-d'){
        statusDateTxt=yearGen+'-'+monthGen+'-'+dayGen;
      }
      return statusDateTxt;
    } else {
      return '';
    }
  }
  getFormatedDateTxtUTC(dateVal,format=''){
    if(dateVal!=null && dateVal!='null' && dateVal!='undefined' && dateVal!=''){
      let statusDate= new Date(dateVal);
      console.log(statusDate);
      let dayGen=statusDate.getDate();
      let monthGen=statusDate.getMonth()+1;
      let yearGen=statusDate.getFullYear();
      let statusDateTxt=dayGen+'-'+monthGen+'-'+yearGen;
      if(format=='Y-m-d'){
        statusDateTxt=yearGen+'-'+monthGen+'-'+dayGen;
      }
      return statusDateTxt;
    } else {
      return '';
    }
  }
  getFormatedDateObj(dateVal){
    if(Object.prototype.toString.call(dateVal) === '[object Date]'){
      dateVal=this.getFormatedDateTxt(dateVal);
    }
    if(dateVal!=null && dateVal!=''){
      let datetxt=dateVal.toString();
      let searchRes=datetxt.search("/");
      let dateArr=datetxt.split('-');
      if(searchRes!=-1){
        dateArr=datetxt.split('/');
      }
      if(dateArr[2] && dateArr[2].length==4){
        return new Date(dateArr[2]+'-'+dateArr[1]+'-'+dateArr[0]);
      } else if(dateArr[2].length==2) {
        return new Date(dateArr[0]+'-'+dateArr[1]+'-'+dateArr[2]);
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getTechnicalFormatedDateObj(dateVal){
    if(Object.prototype.toString.call(dateVal) === '[object Date]' || Object.prototype.toString.call(dateVal) === '[object String]'){
      dateVal=this.getFormatedDateTxt(dateVal);
    }
    if(dateVal!=null && dateVal!=''){
      let datetxt=dateVal.toString();
      let searchRes=datetxt.search("/");
      let dateArr=datetxt.split('-');
      if(searchRes!=-1){
        dateArr=datetxt.split('/');
      }
      if(dateArr[2] && dateArr[2].length==4){
        return new Date(dateArr[2]+'-'+dateArr[1]+'-'+dateArr[0]);
      } else if(dateArr[2].length==2) {
        return new Date(dateArr[0]+'-'+dateArr[1]+'-'+dateArr[2]);
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  // getTechnicalFormatedDateObj(dateVal){
  //   if(Object.prototype.toString.call(dateVal) === '[object Date]' || Object.prototype.toString.call(dateVal) === '[object String]'){
  //     dateVal=this.getFormatedDateTxt(dateVal);
  //   }
  //   if(dateVal!=null && dateVal!=''){
  //     let datetxt=dateVal.toString();
  //     let searchRes=datetxt.search("/");
  //     let dateArr=datetxt.split('-');
  //     if(searchRes!=-1){
  //       dateArr=datetxt.split('/');
  //     }
  //     if(dateArr[2] && dateArr[2].length==4){
  //       return new Date(dateArr[2]+'-'+dateArr[1]+'-'+dateArr[0]);
  //     } else if(dateArr[2].length==2) {
  //       return new Date(dateArr[0]+'-'+dateArr[1]+'-'+dateArr[2]);
  //     } else {
  //       return '';
  //     }
  //   } else {
  //     return '';
  //   }
  // }
  getTypeDetailIDFromDisplayTxt(masterCode,txtDisplay:string){
    if(masterCode!=null && txtDisplay!=null){
      let typeDetailID=null;
      let mastersList=this.getMasters();
      const master = mastersList[masterCode];
      if (master) {
        const m = master.filter(item => {
          let displayText=item.typeDetailDisplayText.toLowerCase();
          let compareText=txtDisplay.trim().toLowerCase();
          return displayText == compareText;
        })[0];
        if (m) {
          return m.typeDetailID;
        } else {
          return typeDetailID;
        }
      }
      return typeDetailID;
    }
  }

  RemoveElementFromArray(arrayList,element: number) {
    if(arrayList && arrayList.length){
      arrayList.forEach((value,index)=>{
          if(value==element){
            arrayList.splice(index,1);
          }
      });
    }
    return arrayList;
}

  filterObject(items: any, filter: any, defaultFilter: boolean): any{
    if (!filter){
      return items;
    }

    if (!Array.isArray(items)){
      return items;
    }

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (defaultFilter) {
        return items.filter(item =>
            filterKeys.reduce((x, keyName) =>
                (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
      }
      else {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
          });
        });
      }
    }
  }

  public setDocumentUploadValue(documentParams: any, thisCmp: any, callback?: any) {
    this._uploadDocumentService.setDocumentUploadValue(documentParams, thisCmp, callback);
  }
  public setpermission(currentComp,selectedApplication:SelectedApplication){
    let submenuPriveldgeList: any[] = JSON.parse(sessionStorage.getItem("PriveldgeList"));
    const componentDetails=currentComp.listFormGroups;
    const formDetails=componentDetails.formGroups;

    if (!currentComp) {
      return;
    }
    if (!submenuPriveldgeList) {
      return;
    }
    if (!selectedApplication) {
      return;
    }
    if(componentDetails.hasOwnProperty('nonEditableCheck') && componentDetails.nonEditableCheck==true){
      selectedApplication.isEditable=true;
    }
    for (const appModule of submenuPriveldgeList) {
      if(appModule.moduleName==componentDetails.module){
        for (const subModule of appModule.subModuleList) {
          if(subModule.screenName==componentDetails.screen){
            if(subModule.isView==true){
              if(subModule.sectionList.length){
                subModule.sectionList.forEach(section => {
                  //if(currentComp.hasOwnProperty(section.sectionCaptionName)){
                  if(formDetails.hasOwnProperty(section.sectionCaptionName)){
                    if(selectedApplication.isEditable){
                      if(formDetails[section.sectionCaptionName].formType && formDetails[section.sectionCaptionName].formType=='array'){
                        let formGroupArr=formDetails[section.sectionCaptionName].form.get(formDetails[section.sectionCaptionName].formKey);
                        if(section.isEdit==true){
                          section.componentPrivileges.forEach((subModuleFormControl)=>{
                            formGroupArr.controls.forEach((formGroup)=>{
                              const control = formGroup.controls[subModuleFormControl.componentCaptionName];
                              if(control){
                                if(subModuleFormControl.isView==false || subModuleFormControl.isEdit==false){
                                  control.disable();
                                }
                              }
                            });
                          });
                        } else {
                          formGroupArr.disable();
                        }
                      } else {
                        let formGroup=formDetails[section.sectionCaptionName].form;
                        if(section.isEdit==true){
                          section.componentPrivileges.forEach((subModuleFormControl)=>{
                            const control = formGroup.controls[subModuleFormControl.componentCaptionName];
                            if(control){
                              if(subModuleFormControl.isView==false || subModuleFormControl.isEdit==false){
                                control.disable();
                              }
                            }
                          });
                        } else {
                          formGroup.disable();
                        }
                      }
                    } else {
                      if(formDetails[section.sectionCaptionName].formType && formDetails[section.sectionCaptionName].formType=='array'){
                        formDetails[section.sectionCaptionName].form.get(formDetails[section.sectionCaptionName].formKey).disable();
                      } else {
                        formDetails[section.sectionCaptionName].form.disable();
                      }
                    }
                  }
                  // enable disable view,print,export etc formwise
                });
              }
            } else {
              // user has no preveledge to view the page will handle in view section
            }
          }

        }
      }
    }
  }
  public setViewPermission(currentComp,selectedApplication:SelectedApplication){
    let submenuPriveldgeList: any[] = JSON.parse(sessionStorage.getItem("PriveldgeList"));
    const componentDetails=currentComp.listFormGroups;
    const formDetails=componentDetails.formGroups;

    if (!currentComp) {
      return;
    }
    if (!submenuPriveldgeList) {
      return;
    }
    if (!selectedApplication) {
      return;
    }

    for (const appModule of submenuPriveldgeList) {
      if(appModule.moduleName==componentDetails.module){
        for (const subModule of appModule.subModuleList) {
          if(subModule.screenName==componentDetails.screen){
            if(subModule.isView==true){
              if(subModule.sectionList.length){
                subModule.sectionList.forEach(section => {
                  // enable disable view,print,export etc formwise
                  if(section.isView==true){
                    if(formDetails.hasOwnProperty(section.sectionCaptionName)){
                      if(section.componentPrivileges.length){
                        let formGroup=formDetails[section.sectionCaptionName].form;
                        if(formDetails[section.sectionCaptionName].formType && formDetails[section.sectionCaptionName].formType=='array'){
                          let formGroupArr=formDetails[section.sectionCaptionName].form.get(formDetails[section.sectionCaptionName].formKey);
                          section.componentPrivileges.forEach((subModuleFormControl)=>{
                            formGroupArr.controls.forEach((formGroup)=>{
                              const control = formGroup.controls[subModuleFormControl.componentCaptionName];
                              if(control && subModuleFormControl.isView==false){
                                $("[data-permissionID='"+subModuleFormControl.componentCaptionName+"']").closest('.form-group').hide();
                                $("[data-headPermissionID='"+subModuleFormControl.componentCaptionName+"']").hide();
                              } else if(!control && subModuleFormControl.isView==false) {
                                $("[data-viewPermissionID='"+subModuleFormControl.componentCaptionName+"']").hide();
                              } else {
                              }
                            });
                          });

                        } else {
                          section.componentPrivileges.forEach((subModuleFormControl)=>{
                            const control = formGroup.controls[subModuleFormControl.componentCaptionName];
                            if(control && subModuleFormControl.isView==false){
                              $("[data-permissionID='"+subModuleFormControl.componentCaptionName+"']").closest('.form-group').hide();
                            } else if(!control && subModuleFormControl.isView==false) {
                              $("[data-viewPermissionID='"+subModuleFormControl.componentCaptionName+"']").hide();
                            } else {
                            }
                          });
                        }
                      }
                    } else {
                      if(section.componentPrivileges.length){
                        section.componentPrivileges.forEach((subModuleFormControl)=>{
                          if(subModuleFormControl.isView==false){
                            $("[data-viewPermissionID='"+subModuleFormControl.componentCaptionName+"']").hide();
                          }
                        });
                      }
                    }
                  } else {
                    $("[data-viewPermissionID='"+section.sectionCaptionName+"']").hide();
                  }
                  if(section.isDelete==false){
                    $("[data-deletePermissionID='"+section.sectionCaptionName+"']").hide();
                  }
                  if(section.isPrint==false){
                    $("[data-printPermissionID='"+section.sectionCaptionName+"']").hide();
                  }
                  if(section.isExport==false){
                    $("[data-exportPermissionID='"+section.sectionCaptionName+"']").hide();
                  }
                  if(section.isApproved==false || section.isApproved==0){
                    $("[data-approvePermissionID='"+section.sectionCaptionName+"']").hide();
                  }
                });
              } else {
                // no section added in view
              }
            } else {
              // user has no view access
              $("[data-viewPage='"+subModule.screenName+"']").hide();
            }
          }
        }
      }
    }
  }
  getPermission(moduleName,screenName){
    let submenuPriveldgeList: any[] = JSON.parse(sessionStorage.getItem("PriveldgeList"));
    let permissionData={};
    if (!submenuPriveldgeList) {
      return;
    }
    for (const appModule of submenuPriveldgeList) {
      if(appModule.moduleName==moduleName){
        for (const subModule of appModule.subModuleList) {
          if(subModule.screenName==screenName){
            if(subModule.sectionList.length){
              // later save on other session storage and get from there if exist
              subModule.sectionList.forEach(section => {
                let componentPrivileges:any={};
                if(section.componentPrivileges.length){
                  section.componentPrivileges.forEach(component => {
                    componentPrivileges[component.componentCaptionName]=component;
                  });
                }
                section.componentPrivileges=componentPrivileges;
                permissionData[section.sectionCaptionName]=section;
              });
            }
          }
        }
      }
    }
    return permissionData;
  }


}
