<div class="hold-transition  bg-img login-page-bg row" style="background-image: url(assets/images/banner1.jpg)"
	data-overlay="1">
	<div class="container h-p100 kyc_main-table col-md-4 col-sm-6 col-4">
		<div class="row align-items-center justify-content-md-center h-p100">
      <div class="col-lg-12 col-md-12 col-12">
          <div class=" bg-white h-p100 login-bg">
            <section class="content based-form kyc_form">
              <div class="text-center">
                <img  class="kyc_form--img" src="assets/images/short-logo.png">
              </div>
                <div class="text-center">
                    <h4 class="page-header">e-KYC</h4>
                </div>
                <div id="myWizard" class="mybasicform_blk">
                  <div class=" tabl-form-controll">  
                    <div class="kyc_table">
                     <h1>KYC TEST</h1>
                    </div>                                          
                    
                  </div>
                </div>
              </section>
          </div>
      </div>
    </div>
  </div>
</div>