import { Component, OnInit, OnDestroy } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  RoutesRecognized,
  NavigationEnd,
  ActivatedRouteSnapshot
} from "@angular/router";
import { NavigationConstants } from "src/app/constants/navigationConstant";
import { SessionStorageService } from "src/app/storage/session-storage.service";
import { UtilityService } from "src/app/services/utility.service";
import { ApiService } from 'src/app/services/api.service';
import { APIConstant } from "src/app/constants/apiConstants";
import { NavService } from 'src/app/services/nav.service';
import { currentSelectedModule } from 'src/app/services/currentModule.service';
import { Subscription } from 'rxjs';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit,OnDestroy {
  myTaskUrl = "/mytask/queue/my"; //NavigationConstants.MYTASK;
  moduleList: any = [];
  loanNumber: String='MA';
  userName:any;
  userNameShort:any;
  activeLinkName:string = "My Task";
  showDashboard:boolean=false;
  showChangePassword:boolean=false;
  subscription: Subscription;
  menuRoute=[];
  sideMenuList:any = [];
  menuItems: any = [
    {
      label: "Login",
      path: "/loan/loan-info",
      sequence: 1,
      application:true
    },
    {
      label: "Sanction",
      path: "/sanction/cvp-verification",
      sequence: 2,
      application:true
    },
    {
      label: "Collateral",
      path: "/collateral/firing",
      sequence: 3,
      application:true
    },
    {
      label: "Disbursal",
      path: "/disbursal/forward-lms",
      sequence: 4,
      application:true
    },
    {
      label: "Report",
      path: "/report/lead-report",
      sequence: 5,
      application:false
    },
    {
      label: "My Task",
      path: "/mytask/queue/my",
      sequence: 6,
      application:false
    },
    {
      label: "Master",
      path: "/master",
      sequence: 7,
      application:false
    },
    {
      label: "Post Disbursal",
      path: "/postdisbursal/payment-status-update",
      sequence: 8,
      application:true
    },
    {
      label: "Change Password",
      path: "/change_password",
      sequence: 8,
      application:true
    },
    {
      label: "Dashboard",
      path: "/dashboard",
      sequence: 8,
      application:true
    },
    {
      label: "Dashboards",
      path: "/all-dashboard/dsadashboard",
      sequence: 9,
      application:false
    },
  ];

  baseRoute: ActivatedRouteSnapshot;
  urlKey: any;

  private _opened: boolean = false;
  selectedIndexVal: any;
  selectedIndexValue:any;

  private _onDestroy = new Subject<void>();

  private _toggleOpened(): void {
    this._opened = !this._opened;
  }

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    public navService: NavService,private currentModuleService: currentSelectedModule
  ) {
    this.subscription = this.currentModuleService.getModule().subscribe(item => {
      this.activeLinkName = item;
    });
  }

  ngOnInit() {
    let authData=this.sessionStorageService.getData('authorizationSequence');
    if(authData!='' && authData!=null){
      let authDataObj=JSON.parse(authData);
      let userNameArr=[];
      if(authDataObj && authDataObj.employeeName){
        this.userName = authDataObj.employeeName;
        userNameArr=authDataObj.employeeName.split(' ');
      } else {
        this.userName = authDataObj.userName;
        userNameArr=authDataObj.userName.split(' ');
      }
      if(userNameArr && userNameArr.length){
        if(userNameArr.length>=2){
          this.userNameShort=userNameArr[0].charAt(0)+userNameArr[1].charAt(0);
        } else {
          this.userNameShort=userNameArr[0].charAt(0);
        }
        this.userNameShort=this.userNameShort.toUpperCase();
      }
    }
    this.urlKey = ((window.location.href).includes('/kyc/start/') || (window.location.href).includes('/success'));
    //this.moduleList = this.menuItems;
    this.loanNumber = this.utilityService.encrypt(0);
    this.selectedIndex(5,this.menuItems);
    this.selectedSubMenuIndex(0,this.menuItems)
    this.getPrivilegeList();
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.baseRoute = event.state.root.firstChild;
        const modulePath = this.baseRoute.routeConfig.path;
        if (
          modulePath == "loan" ||
          modulePath == "sanction" ||
          modulePath == "collateral" ||
          modulePath == "disbursal" ||
          modulePath == "postdisbursal"
        ) {

         this.menuRoute.forEach((item,index)=>{
          let label = item.label.replace(/\s/g,'');
          if(label.toUpperCase() == modulePath.toUpperCase() || (label == 'Login' && modulePath == 'loan')){
            this.selectedIndexValue = index;
          }
         })
         let splitData:any;
         splitData = event.url.split('/');
         let subMenuSplitData;
         this.sideMenuList.forEach(element => {
          if(element.moduleName.toLowerCase() == splitData[1].toLowerCase() || (element.moduleName == 'Login' && splitData[1] == 'loan')){
           element.subModuleList.forEach((element,index) => {
            subMenuSplitData = element.path.split('/')
            if(subMenuSplitData[2] == splitData[2]){
              this.selectedSubMenuIndex(index,this.menuItems)
            }
           });
          }
         });
          this.selectedIndex(this.selectedIndexValue,this.menuItems);
          this.loanNumber = this.baseRoute.firstChild.firstChild.params.loanNumber;
        } else {
          this.menuRoute.forEach((item,index)=>{
            let label = item.label.replace(/\s/g,'');
            if(label.toUpperCase() == modulePath.toUpperCase()|| (modulePath == 'all-dashboard' && label == 'Dashboards')){
              this.selectedIndexValue = index;
            }
           })
           let splitData:any;
         splitData = event.url.split('/');
         let subMenuSplitData;
         this.sideMenuList.forEach(element => {
          if(element.moduleName.toLowerCase() == splitData[1].toLowerCase() || (element.moduleName == 'Dashboards' && splitData[1] == 'all-dashboard') || (element.moduleName == 'My Task' && splitData[1] == 'mytask')){
           element.subModuleList.forEach((element,index) => {
            subMenuSplitData = element.path.split('/')
            if(subMenuSplitData.length > 3){
              if(subMenuSplitData[3] == splitData[3]){
                  this.selectedSubMenuIndex(index,this.menuItems)
                }
            }else{
              if(subMenuSplitData[2] == splitData[2]){
                  this.selectedSubMenuIndex(index,this.menuItems)
                }
            }

           });
          }
         });
            this.selectedIndex(this.selectedIndexValue,this.menuItems);
          this.utilityService.globalApplicationSelected.pipe(takeUntil(this._onDestroy)).subscribe(dataShared => {
            if(dataShared && dataShared.leadID){
              this.loanNumber=this.utilityService.encrypt(dataShared.leadID);
            } else {
              this.loanNumber=this.utilityService.encrypt(0);
            }
          });
        }
      }
    });
  }

  getPrivilegeList() {
    const privList: any = this.sessionStorageService.getData("PriveldgeList");
    if (privList) {
      const objPrivList: any[] = JSON.parse(privList);
      const mList: any[] = [];
      this.menuItems.forEach(menuItem => {
        const objModule: any = objPrivList.filter(item => {
          return item.moduleName.toUpperCase() === menuItem.label.toUpperCase();
        });
        if (objModule && objModule.length > 0) {
          if(objModule[0].isView==true){
            if(objModule[0].subModuleList && objModule[0].subModuleList.length){
              let objSubModule: any = objModule[0].subModuleList.filter(item => {
                return item.isView==true;
              });
              menuItem['path']=objSubModule[0].path;
            }
            if(objModule[0].moduleName.toUpperCase()!='DASHBOARD' && objModule[0].moduleName.toUpperCase()!='CHANGE PASSWORD'){
              mList.push(menuItem);
            }
          }
          if(objModule[0].isView==true && objModule[0].moduleName.toUpperCase()=='DASHBOARD'){
            this.showDashboard=true;
          }
          if(objModule[0].isView==true && objModule[0].moduleName.toUpperCase()=='CHANGE PASSWORD'){
            this.showChangePassword=true;
          }
          // const submenuPriveldgeList: any[] = objModule[0].subModuleList;
          // const subModulesWithViewPermission: any[] = submenuPriveldgeList.filter(
          //   submenuPriveldgeItem => {
          //     return submenuPriveldgeItem.isView;
          //   }
          // );
          // if (subModulesWithViewPermission.length > 0) {
          //   mList.push(menuItem);
          // }
        }
      });

      this.moduleList = mList.sort((obj1, obj2) => {
        return obj1.sequence - obj2.sequence;
      });
      this.menuRoute=this.moduleList;
      mList.forEach(menuItem => {
        objPrivList.filter(item => {
           if(item.moduleName.toUpperCase() === menuItem.label.toUpperCase()){
             this.sideMenuList.push(item);
           }
         });
       })
    }
  }

  public logout() {
    this.apiService.post(APIConstant.LOGOUT_USER,{},resObj=>{
      sessionStorage.clear();      
      this.router.navigate(["/login"]);
      return;
    },err=>{
      sessionStorage.clear();
      this.router.navigate(["/login"]);
    })
  }

  public changePassword() {
    // sessionStorage.clear();
    return this.router.navigate(["/change_password"]);
  }
  public dashboard() {
    return this.router.navigate(["/dashboard"]);
  }
  getActiveLink(activeLinkData){
    this.activeLinkName = activeLinkData.label;
  }
  selectedIndex(val: any, module) {
    this.selectedIndexVal = val;
  }
  ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
  }
  selectedSubMenuIndexVal:any;
  selectedSubMenuIndex(index,submodule){
    this.selectedSubMenuIndexVal = index;
  }
}
