import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { APIConstant } from 'src/app/constants/apiConstants';
import { ValidationMessage } from 'src/app/constants/validationMessage';
import { ApiService } from 'src/app/services/api.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';
import { SessionStorageService } from 'src/app/storage/session-storage.service';
import { LocalStorageService } from 'src/app/storage/local-storage.service';
import { CustomStorage } from 'src/app/storage/storageKeys';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Event, Router, NavigationStart } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-login-otp',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login-otp.component.css']
})
export class LoginOtpComponent implements OnInit {

  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;
  @ViewChild('ngOtpInput') ngOtpInputRef:any;

  otpVerify = false;
  validationMessage = ValidationMessage;
  isInvalid = false;
  timer: any;
  maxTime: number = 180;
  hidevalue: boolean;
  otp: string = '';
  userDetails: any;
  navigationCheck=false;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '30px',
      'height': '35px',
      'border-radius': 5,
      'border': '1px solid #1A90F7',
      'font-size': '22px',
      'outline': 'none',
      'margin-right': '10px',
      'margin-left': '0px'
    }
  };

  constructor(public _fb: FormBuilder, private apiservice: ApiService, private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService,private commonService: CommonService,
    private localStorageService: LocalStorageService,private router: Router,) {

      this.router.events.subscribe((RouterEvent: Event) => {
        if (RouterEvent instanceof NavigationStart) {
          if(!this.navigationCheck){
            this.sessionStorageService.deleteAll();
            this.localStorageService.deleteAll(); 
          }
        }
      });

    }

  ngOnInit() {
    this.userDetails = JSON.parse(this.sessionStorageService.getData("userLoginDetails"));
    this.startTimer();
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  verifyOTP() {
    this.utilityService.showLoader();
    this.otpVerify = true;    
    if (this.otp.length != 6 || this.otp == undefined ) {
      this.otp = "";
      let message = "Enter Valid OTP";
      this.swalFire(message);
      this.utilityService.hideLoader();
      return;
    } else {
      let otpVerifyPayload = {
        otpValue: this.otp,
        userName: this.userDetails.loginPayload.username,
        echId: this.userDetails.echID,
        company: {
          companyId: 1,
          companySlug: "DMI_HFC"
        }
      }
      this.apiservice.post(APIConstant.MFA_OTP, otpVerifyPayload, resObj => {
        this.commonService.getDocumentCode().subscribe({
          next:(resObj) => {
            this.commonService.getMasterData().subscribe({
              next:(resObj) => {
                if(resObj.responseObj){
                  let masterJson = JSON.stringify(resObj.responseObj);
                  this.localStorageService.setData(CustomStorage.localKeys.masters,masterJson);
                  this.navigationCheck=true;
                  this.utilityService.hideLoader();
                  this.utilityService.redirectToDashboard();
                } else {
                  this.utilityService.hideLoader();
                  this.utilityService.logout();
                  Swal.fire({
                    allowOutsideClick: false,
                    text: 'just text',
                    html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.applicationError + '</p>',
                  })
                }
              },
              error:(err)=> {
                this.utilityService.hideLoader();
                this.utilityService.logout();
                Swal.fire({
                  allowOutsideClick: false,
                  text: 'just text',
                  html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.applicationError + '</p>',
                })
              }
            });
          },
          error:(err)=> {
            this.utilityService.hideLoader();
            this.utilityService.logout();
            Swal.fire({
              allowOutsideClick: false,
              text: 'just text',
              html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.applicationError + '</p>',
            })
          }
        });
      }, error => {
        this.utilityService.hideLoader();
        this.ngOtpInputRef.setValue('');
        this.otp = '';
        this.swalFire(error.responseMsg);
      });
    }
  }

  startTimer() {
    this.timer = setTimeout(x => {
      if (this.maxTime <= 0) { }
      this.maxTime -= 1;
      if (this.maxTime > 0) {
        this.hidevalue = false;
        this.startTimer();
      }
      else {
        this.hidevalue = true;
      }
    }, 1000);
  }

  onResendCode() {
    this.maxTime = 180;
    this.startTimer();
    this.utilityService.showLoader();
    this.apiservice.post(
      APIConstant.LOGIN_API,
      this.userDetails.loginPayload,
      resObj => {
        const userDetailsObj = resObj.responseObj;
        if (this.isValidUser(userDetailsObj)) {
          let token = userDetailsObj.token;
          let role_id = userDetailsObj.userDetails.roleList[0].roleId;
          this.userDetails.echID = userDetailsObj.echId;
          this.sessionStorageService.setData(
            CustomStorage.sessionKeys.userLoginDetails,
            JSON.stringify(this.userDetails)
          );
          if (token && role_id) { 
            this.saveToken(token);
          } else {
            this.utilityService.clearSession();
          }
        } else {
          this.utilityService.hideLoader();
          this.swalFire(this.validationMessage.login.invalidLogin);
        }
        this.utilityService.hideLoader();
      },
      error => {
        this.utilityService.hideLoader();
        this.utilityService.clearSession();
      }
    )
  }

  isValidUser(userDetailsObj){
    return userDetailsObj?.userDetails?.userBasicDetails?.userType != 'customerTypeUser' 
    && userDetailsObj?.userDetails?.userBasicDetails?.userType != 'DsaUser'
  }

  saveToken(token){
    this.sessionStorageService.setData(CustomStorage.localKeys.token, JSON.stringify(token));
    this.localStorageService.setData(CustomStorage.localKeys.token, JSON.stringify(token));
  }

  swalFire(message){
    Swal.fire({
      allowOutsideClick: false,
      text: 'just text',
      html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + message + '</p>',
    })
  }

}
