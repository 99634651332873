import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';
import { ApiService } from 'src/app/services/api.service';
import { APIConstant } from "src/app/constants/apiConstants";
import { BaseComponent } from 'src/app/components/basecomponent/basecomponent.component';
import { ValidationMessage } from "src/app/constants/validationMessage";
import { NavigationConstants } from "src/app/constants/navigationConstant";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dsa-dashboard',
  templateUrl: './dsa-dashboard.component.html',
  styleUrls: ['./dsa-dashboard.component.css']
})
export class DsaDashboardComponent extends BaseComponent implements OnInit {
  zoneDashboardData: any = [];
  regionDashboardData: any = [];
  branchDashboardData: any = [];
  showZoneData: boolean = false;
  showRegionData: boolean = false;
  showBranchData: boolean = false;
  showRegionName = '';
  searchText: string = '';
  showBranchName = '';
  currentZoneID: any;
  currentType: any;
  formPermissionData: any;
  showFOSData: boolean = false;
  showFOSName = '';
  FOSDashboardData: any = [];
  currentRegionID: any;
  currentFOSZoneID: any;
  currentRegionType: any;
  isShowSales: boolean = false
  fromdate : any;
  todate: any;
  maxFromDate = new Date();
  minToDate = new Date();
  screenName: any;
  zoneID: any;
  type: any;
  regionID: any;
  branchID: any;
  channelType: any = 'All';
  today = new Date();
  previousMonthDate = new Date();


  constructor(public _fb: FormBuilder, private modalService: NgbModal,
    private utilityService: UtilityService, private apiService: ApiService) {
    super(utilityService);
    this.formPermissionData = this.utilityService.getPermission('Dashboard', 'Dashboard');
    console.log(this.formPermissionData);
    if (this.formPermissionData && this.formPermissionData.Dashboard && this.formPermissionData.Dashboard.isView == true) {

    } else {
      this.utilityService.reDirectToUrl(NavigationConstants.MYTASK);
    }
  }

  ngOnInit() {
    this.previousMonthDate = new Date(Date.UTC(this.today.getFullYear(), this.today.getMonth(), 1));
    this.fromdate = this.utilityService.getFormatedDateTxt(this.previousMonthDate, 'Y-m-d');
    this.todate = this.utilityService.getFormatedDateTxt(this.today,'Y-m-d');
    this.minToDate = new Date(Date.UTC(this.today.getFullYear(), this.today.getMonth(), 1));
    this.zoneDashboard();
  }
  showSalesDashboard() {
    this.isShowSales = !this.isShowSales;
  }
  zoneDashboard() {
    this.showZoneData = true;
    this.showRegionData = false;
    this.showBranchData = false;
    this.showFOSData = false;
    this.utilityService.showLoader();
    this.screenName = 'zone';
    let apiUrl = APIConstant.DSA_DASHBOARD.replace('{screen}', 'zone');
    this.apiService.get(apiUrl, {startDate: this.fromdate || '',endDate: this.todate || '', channelPartner: this.channelType}, resObj => {
      this.zoneDashboardData = resObj;
      this.utilityService.hideLoader();
      this.searchText = '';
    }, err => {
      this.utilityService.hideLoader();
    });
  }
  regionDashboard(type, zoneID) {
    this.zoneID = zoneID;
    this.type = type
    this.screenName = 'region';
    this.showZoneData = false;
    this.showRegionData = true;
    this.showBranchData = false;
    this.showFOSData = false;
    this.currentType = type;
    this.utilityService.showLoader();
    let apiUrl = APIConstant.DSA_DASHBOARD.replace('{screen}', 'region');
    this.apiService.get(apiUrl, { type: type, zoneID: zoneID,startDate: this.fromdate || '',endDate: this.todate || '', channelPartner: this.channelType }, resObj => {
      this.regionDashboardData = resObj;
      if (type == 'ALL') {
        this.showRegionName = 'All Region';
      }
      else {
        this.showRegionName = this.regionDashboardData[0].zoneName + '>>Region';
      }
      this.utilityService.hideLoader();
      this.searchText = '';
    }, err => {
      this.utilityService.hideLoader();
    });
  }

  branchDashboard(type, regionID, zoneID) {
    this.type = type;
    this.regionID = regionID;
    this.zoneID = zoneID
    this.screenName = 'branch';
    this.showZoneData = false;
    this.showRegionData = false;
    this.showBranchData = true;
    this.showFOSData = false;
    this.currentRegionType = type;
    this.currentZoneID = zoneID;
    this.currentRegionID = regionID;
    this.utilityService.showLoader();
    let apiUrl = APIConstant.DSA_DASHBOARD.replace('{screen}', 'branch');
    this.apiService.get(apiUrl, { type: type, regionID: regionID, zoneID: zoneID, startDate: this.fromdate || '',endDate: this.todate || '' ,channelPartner: this.channelType}, resObj => {
      this.branchDashboardData = resObj;
      if (type == 'ALL') {
        this.showBranchName = 'All Branch';
      }
      else {
        this.showBranchName = this.branchDashboardData[0].zoneName + '>>' + this.branchDashboardData[0].regionName + '>>Branch';
      }
      this.utilityService.hideLoader();
      this.searchText = '';
    }, err => {
      this.utilityService.hideLoader();
    });
  }
  getFOSData(branchID) {
    this.branchID = branchID;
    this.screenName = 'fos';
    this.showZoneData = false;
    this.showRegionData = false;
    this.utilityService.showLoader();
    let apiUrl = APIConstant.DSA_DASHBOARD.replace('{screen}', 'fos');
    this.apiService.get(apiUrl, { branchID: branchID, startDate: this.fromdate || '',endDate: this.todate || '',channelPartner: this.channelType}, resObj => {
      if (resObj == null || resObj.length == 0) {
        this.showBranchData = true;
        this.utilityService.hideLoader();
      }
      else {
        this.showFOSData = true;
        this.showBranchData = false;
        this.FOSDashboardData = resObj;
        this.currentFOSZoneID = this.FOSDashboardData[0].zoneID;
        this.currentRegionID = this.FOSDashboardData[0].regionID;
        this.showFOSName = this.FOSDashboardData[0].zoneName + '>>' + this.FOSDashboardData[0].regionName + '>>' + this.FOSDashboardData[0].branchName;
        this.utilityService.hideLoader();
        this.searchText = '';
      }
    }, err => {
      this.utilityService.hideLoader();
      Swal.fire({
        allowOutsideClick: false,
        text: 'just text',
        html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + err.responseMsg + '</p>',
      })
    });
  }


  selectedChannel(event){
    this.channelType = event;
    if (this.screenName == 'zone') {
      this.zoneDashboard();
    } else if (this.screenName == 'region') {
      this.regionDashboard(this.type, this.zoneID)
    } else if (this.screenName == 'branch') {
      this.branchDashboard(this.type, this.regionID, this.zoneID)
    } else if (this.screenName == 'fos') {
      this.getFOSData(this.branchID)
    }
  }

  changeFromdateEvent(event) {
    this.fromdate = this.utilityService.getFormatedDateTxt(event, 'Y-m-d');
    this.minToDate = event;
    if (this.screenName == 'zone') {
      this.zoneDashboard();
    } else if (this.screenName == 'region') {
      this.regionDashboard(this.type, this.zoneID)
    } else if (this.screenName == 'branch') {
      this.branchDashboard(this.type, this.regionID, this.zoneID)
    } else if (this.screenName == 'fos') {
      this.getFOSData(this.branchID)
    }
  }

  changeTodateEvent(event) {
    this.todate = this.utilityService.getFormatedDateTxt(event, 'Y-m-d');
    this.maxFromDate = event;
    if (this.screenName == 'zone') {
      this.zoneDashboard();
    } else if (this.screenName == 'region') {
      this.regionDashboard(this.type, this.zoneID)
    } else if (this.screenName == 'branch') {
      this.branchDashboard(this.type, this.regionID, this.zoneID)
    } else if (this.screenName == 'fos') {
      this.getFOSData(this.branchID)
    }  
  }

}
