 

<!-- /.content-wrapper -->
<footer class="main-footer">
    <div class="inside-footer-2 text-left">
        DMI RAPID
        <button class="btn btn-primary insurance_btn btn-xs my_short_btn pull-right"
        title="Back" *ngIf="dcbRedirect" (click)="backToDCB()">Back to Partner Trade</button>
    </div>
    <div class="inside-footer text-right">
        &copy; 2020 DMI. All Rights Reserved.
    </div>
</footer>