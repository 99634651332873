import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {
  private subject = new Subject<any>();

  // Data for document upload
  setDocumentUploadValue(DocumentUploadData?: any,cmp?:any,_callback?:any) {
    const evt = {
      data:DocumentUploadData,
      callback:_callback,
      component:cmp
    }
    this.subject.next(evt);
  }

  getDocumentUploadValue(): Observable<any> {
    return this.subject.asObservable();
  }

  clearDocumentUploadValue() {
    this.subject.next(null);
  }

}


