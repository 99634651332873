<app-new-dashboard *ngIf="isShowSales"></app-new-dashboard>

<div class="content-wrapper finalprevi" style="margin: 20px 20px; font-size: 11.8px !important;" *ngIf="!isShowSales">
    <section class="content based-form" style="background-color: white;">
        <div id="myWizard" class="mybasicform_blk">

            <div class="dashboard-nav">
                <div class="col-auto mt-0">
                    <button (click)="zoneDashboard()" mat-raised-button class="login-dashboard-color btn-click-effect">
                        <span>DSA Zone</span></button>
                </div>
                <div class="col-auto mt-0">
                  <button (click)="regionDashboard('ALL','')" mat-raised-button
                        class="sanction-dashboard-color btn-click-effect">
                        <span>DSA Region</span></button>
                </div>

                <div class="col-auto mt-0">
                    <button (click)="branchDashboard('ALL','','')" mat-raised-button
                        class="disbursal-dashboard-color btn-click-effect">
                        <span>DSA Branch</span></button>
                </div>

                <div class="col-auto mt-0">
                    <button (click)="showSalesDashboard()" mat-raised-button class="button-1">
                        <span>Sales <i class="mdi mdi-view-dashboard passwordIcon"></i></span></button>
                </div>
                <!-- <div class="col-auto mt-0" style="left: 50px;">
                    <mat-form-field>
                        <input matInput [matDatepicker]="fromDate" placeholder="From Date" [value]="previousMonthDate"
                            autocomplete="off" (dateChange)="changeFromdateEvent($event.value)" [max]="maxFromDate">
                        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                        <mat-datepicker #fromDate></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-auto mt-0" style="left: 50px;">
                    <mat-form-field>
                        <input matInput [matDatepicker]="toDate" placeholder="To Date" [max]="today" [min]="minToDate" [value]="today"
                            autocomplete="off" (dateChange)="changeTodateEvent($event.value)">
                        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                        <mat-datepicker #toDate></mat-datepicker>
                    </mat-form-field>
                </div>  -->
                <div class=" col-sm-4 col-auto mt-0" align="right" style="left: 550px;">
                    <input type="text" [(ngModel)]="searchText" placeholder='Search' class="dashboard-searchbar">
                </div>
            </div>


            <div class="tab-pane first-tab active" id="employment_info"
                style="padding-left: 5px; padding-bottom: 18px; text-align: center;">
                <div class="box py-0 px-0 my_tab_blk" style="margin-bottom: 0px;">
                    <ul class="nav nav-tabs nav-tabs-inverse-mode">
                        <li>
                            <input type="radio" id="All" name="channel" value="All" (click)="selectedChannel('ALL')" [(ngModel)]="channelType">
                            <label for="All">All</label>
                        </li>
                        <li class="nav-item ng-star-inserted col-2">
                            <input type="radio" id="DSA" name="channel" value="DSA" (click)="selectedChannel('DSA')">
                            <label for="DSA">DSA</label>
                        </li>
                        <li class="nav-item ng-star-inserted col-1">
                            <input type="radio" id="RP" name="channel" value="RP" (click)="selectedChannel('RP')">
                            <label for="RP">RP/Connector</label>
                        </li>
                        <li class="nav-item ng-star-inserted col-2" style="left: 470px;">
                            <mat-form-field>
                                <input matInput [matDatepicker]="fromDate" placeholder="From Date" [value]="previousMonthDate"
                                    autocomplete="off" (dateChange)="changeFromdateEvent($event.value)" [max]="maxFromDate">
                                <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                                <mat-datepicker #fromDate></mat-datepicker>
                            </mat-form-field>
                        </li>
                        <li class="nav-item ng-star-inserted col-2" style="left: 470px;">
                            <mat-form-field>
                                <input matInput [matDatepicker]="toDate" placeholder="To Date" [max]="today" [min]="minToDate"
                                    [value]="today" autocomplete="off" (dateChange)="changeTodateEvent($event.value)">
                                <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                                <mat-datepicker #toDate></mat-datepicker>
                            </mat-form-field>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="accordion" id="dashboardAccordion">
                <div class="card" *ngIf="showZoneData">
                    <div class="card-header">
                        <h4 class="inte-box-title display_flex col-12 login-dashboard-color" data-toggle="collapse"
                            data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Zone
                        </h4>

                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#dashboardAccordion">
                        <div class="card-body">
                            <div class="table-responsive lead-fixed-table-long">
                                <table id="example"
                                    class="table my_bank_table table-bordered mb-4 leadTableScrollable zone-tbl-bg">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">Zone</th>
                                            <th colspan="5">DSA Onboard</th>
                                            <th colspan="2">Agreement Status</th>
                                            <th colspan="3">WIP</th>
                                            <th colspan="2">FI Status</th>

                                        </tr>
                                        <tr>
                                            <th>Total DSA</th>
                                            <th>Active</th>
                                            <th>Inactive</th>
                                            <th>Initiated</th>
                                            <th>Cleared DSA</th>
                                            <th>Approved</th>
                                            <th>Pending</th>
                                            <th>WIP-Branch</th>
                                            <th>WIP-HO</th>
                                            <th>WIP-FCU</th>
                                            <th>Positive</th>
                                            <th>Negative</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let zone of zoneDashboardData| grdFilter: {zoneName: searchText} ;let i=index">
                                            <td><a href="#"
                                                    (click)="regionDashboard('zone',zone.zoneID)">{{zone.zoneName}}</a>
                                            </td>
                                            <td>{{zone.totalDSA | number}}</td>
                                            <td>{{zone.activeDSA | number}}</td>
                                            <td>{{zone.inActiveDSA | number}}</td>
                                            <td>{{zone.initiatedDSA | number}}</td>
                                            <td>{{zone.approvedDSA | number}}</td>
                                            <td>{{zone.totalAgreementReceived | number}}</td>
                                            <td>{{zone.totalAgreementPending | number}}</td>
                                            <td>{{zone.dsaPendingWithBranch | number}}</td>
                                            <td>{{zone.dsaPendingWithHO | number}}</td>
                                            <td>{{zone.dsaPendingWithFCU | number}}</td>
                                            <td>{{zone.fiPositiveCount | number}}</td>
                                            <td>{{zone.fiNegativeCount | number}}</td>

                                        </tr>
                                        <tr style="background: #8bf1ae; font-weight: bold;">
                                            <td>TOTAL</td>
                                            <td>{{zoneDashboardData | total:'totalDSA' | number}}</td>
                                            <td>{{zoneDashboardData | total:'activeDSA' | number}}</td>
                                            <td>{{zoneDashboardData | total:'inActiveDSA' | number}}</td>
                                            <td>{{zoneDashboardData | total:'initiatedDSA' | number}}</td>
                                            <td>{{zoneDashboardData | total:'approvedDSA' | number}}</td>
                                            <td>{{zoneDashboardData | total:'totalAgreementReceived' | number}}</td>
                                            <td>{{zoneDashboardData | total:'totalAgreementPending' | number}}</td>
                                            <td>{{zoneDashboardData | total:'dsaPendingWithBranch' | number}}</td>
                                            <td>{{zoneDashboardData | total:'dsaPendingWithHO' | number}}</td>
                                            <td>{{zoneDashboardData | total: 'dsaPendingWithFCU' | number}}</td>
                                            <td>{{zoneDashboardData | total: 'fiPositiveCount' | number}}</td>
                                            <td>{{zoneDashboardData | total:'fiNegativeCount' | number}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="showRegionData">
                    <div class="card-header" id="headingTwo">
                        <h4 class="inte-box-title display_flex col-12 sanction-dashboard-color" data-toggle="collapse"
                            data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            {{showRegionName}}
                        </h4>
                    </div>
                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                        data-parent="#dashboardAccordion">
                        <div class="card-body" *ngIf="showRegionData">
                            <div class="table-responsive lead-fixed-table-long">
                                <table id="example"
                                    class="table my_bank_table table-bordered mb-4 leadTableScrollable region-tbl-bg">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">Zone</th>
                                            <th colspan="5">DSA Onboard</th>
                                            <th colspan="2">Agreement Status</th>
                                            <th colspan="3">WIP</th>
                                            <th colspan="2">FI Status</th>
                                        </tr>
                                        <tr>
                                            <th>Total DSA</th>
                                            <th>Active</th>
                                            <th>Inactive</th>
                                            <th>Initiated</th>
                                            <th>Cleared DSA</th>
                                            <th>Approved</th>
                                            <th>Pending</th>
                                            <th>WIP-Branch</th>
                                            <th>WIP-HO</th>
                                            <th>WIP-FCU</th>
                                            <th>Positive</th>
                                            <th>Negative</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let region of regionDashboardData| grdFilter: {regionName: searchText} ;let i=index">
                                            <td><a href="#"
                                                    (click)="branchDashboard('region',region.regionID,region.zoneID)">{{region.regionName}}</a>
                                            </td>
                                            <td>{{region.totalDSA | number}}</td>
                                            <td>{{region.activeDSA | number}}</td>
                                            <td>{{region.inActiveDSA | number}}</td>
                                            <td>{{region.initiatedDSA | number}}</td>
                                            <td>{{region.approvedDSA | number}}</td>
                                            <td>{{region.totalAgreementReceived | number}}</td>
                                            <td>{{region.totalAgreementPending | number}}</td>
                                            <td>{{region.dsaPendingWithBranch | number}}</td>
                                            <td>{{region.dsaPendingWithHO | number}}</td>
                                            <td>{{region.dsaPendingWithFCU | number}}</td>
                                            <td>{{region.fiPositiveCount | number}}</td>
                                            <td>{{region.fiNegativeCount | number}}</td>

                                        </tr>
                                        <tr style="background: #8bf1ae; font-weight: bold;">
                                            <td>TOTAL</td>
                                            <td>{{regionDashboardData | total:'totalDSA' | number}}</td>
                                            <td>{{regionDashboardData | total:'activeDSA' | number}}</td>
                                            <td>{{regionDashboardData | total:'inActiveDSA' | number}}</td>
                                            <td>{{regionDashboardData | total:'initiatedDSA' | number}}</td>
                                            <td>{{regionDashboardData | total:'approvedDSA' | number}}</td>
                                            <td>{{regionDashboardData | total:'totalAgreementReceived' | number}}</td>
                                            <td>{{regionDashboardData | total:'totalAgreementPending' | number}}</td>
                                            <td>{{regionDashboardData | total:'dsaPendingWithBranch' | number}}</td>
                                            <td>{{regionDashboardData | total:'dsaPendingWithHO' | number}}</td>
                                            <td>{{regionDashboardData | total: 'dsaPendingWithFCU' | number}}</td>
                                            <td>{{regionDashboardData | total: 'fiPositiveCount' | number}}</td>
                                            <td>{{regionDashboardData | total:'fiNegativeCount' | number}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-auto mt-0 mb-4" *ngIf="!(showRegionName=='All Region')">
                                <button (click)="zoneDashboard()" mat-raised-button
                                    class="login-dashboard-color btn-click-effect">
                                    <span>Back</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="showBranchData">
                    <div class="card-header" id="headingThree">
                        <h5 class="inte-box-title display_flex col-12 disbursal-dashboard-color" data-toggle="collapse"
                            data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            {{showBranchName}}
                        </h5>
                    </div>
                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
                        data-parent="#dashboardAccordion">
                        <div class="card-body">
                            <div class="table-responsive lead-fixed-table-long">
                                <table id="example"
                                    class="table my_bank_table table-bordered mb-4 leadTableScrollable branch-tbl-bg">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">Zone</th>
                                            <th colspan="5">DSA Onboard</th>
                                            <th colspan="2">Agreement Status</th>
                                            <th colspan="3">WIP</th>
                                            <th colspan="2">FI Status</th>
                                        </tr>
                                        <tr>
                                            <th>Total DSA</th>
                                            <th>Active</th>
                                            <th>Inactive</th>
                                            <th>Initiated</th>
                                            <th>Cleared DSA</th>
                                            <th>Approved</th>
                                            <th>Pending</th>
                                            <th>WIP-Branch</th>
                                            <th>WIP-HO</th>
                                            <th>WIP-FCU</th>
                                            <th>Positive</th>
                                            <th>Negative</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let branch of branchDashboardData| grdFilter: {branchName: searchText};let i=index">
                                            <td><a href="#"
                                                    (click)="getFOSData(branch.branchID)">{{branch.branchName}}</a></td>
                                            <td>{{branch.totalDSA | number}}</td>
                                            <td>{{branch.activeDSA | number}}</td>
                                            <td>{{branch.inActiveDSA | number}}</td>
                                            <td>{{branch.initiatedDSA | number}}</td>
                                            <td>{{branch.approvedDSA | number}}</td>
                                            <td>{{branch.totalAgreementReceived | number}}</td>
                                            <td>{{branch.totalAgreementPending | number}}</td>
                                            <td>{{branch.dsaPendingWithBranch | number}}</td>
                                            <td>{{branch.dsaPendingWithHO | number}}</td>
                                            <td>{{branch.dsaPendingWithFCU | number}}</td>
                                            <td>{{branch.fiPositiveCount | number}}</td>
                                            <td>{{branch.fiNegativeCount | number}}</td>

                                        </tr>
                                        <tr style="background: #8bf1ae; font-weight: bold;">
                                            <td>TOTAL</td>
                                            <td>{{branchDashboardData | total:'totalDSA' | number}}</td>
                                            <td>{{branchDashboardData | total:'activeDSA' | number}}</td>
                                            <td>{{branchDashboardData | total:'inActiveDSA' | number}}</td>
                                            <td>{{branchDashboardData | total:'initiatedDSA' | number}}</td>
                                            <td>{{branchDashboardData | total:'approvedDSA' | number}}</td>
                                            <td>{{branchDashboardData | total:'totalAgreementReceived' | number}}</td>
                                            <td>{{branchDashboardData | total:'totalAgreementPending' | number}}</td>
                                            <td>{{branchDashboardData | total:'dsaPendingWithBranch' | number}}</td>
                                            <td>{{branchDashboardData | total:'dsaPendingWithHO' | number}}</td>
                                            <td>{{branchDashboardData | total: 'dsaPendingWithFCU' | number}}</td>
                                            <td>{{branchDashboardData | total: 'fiPositiveCount' | number}}</td>
                                            <td>{{branchDashboardData | total:'fiNegativeCount' | number}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-auto mt-0 mb-4" *ngIf="!(showBranchName=='All Branch')">
                                <button (click)="regionDashboard(currentType,currentZoneID)" mat-raised-button
                                    class="login-dashboard-color btn-click-effect">
                                    <span>Back</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="showFOSData">
                    <div class="card-header" id="headingThree">
                        <h5 class="inte-box-title display_flex col-12 disbursal-dashboard-color" data-toggle="collapse"
                            data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            {{showFOSName}}
                        </h5>
                    </div>
                    <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
                        data-parent="#dashboardAccordion">
                        <div class="card-body">
                            <div class="table-responsive lead-fixed-table-long">
                                <table id="example"
                                    class="table my_bank_table table-bordered mb-4 leadTableScrollable branch-tbl-bg">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">Created By</th>
                                            <th colspan="5">DSA Onboard</th>
                                            <th colspan="2">Agreement Status</th>
                                            <th colspan="3">WIP</th>
                                            <th colspan="2">FI Status</th>
                                        </tr>
                                        <tr>
                                            <th>Total DSA</th>
                                            <th>Active</th>
                                            <th>Inactive</th>
                                            <th>Initiated</th>
                                            <th>Cleared DSA</th>
                                            <th>Approved</th>
                                            <th>Pending</th>
                                            <th>WIP-Branch</th>
                                            <th>WIP-HO</th>
                                            <th>WIP-FCU</th>
                                            <th>Positive</th>
                                            <th>Negative</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let fosdata of FOSDashboardData| grdFilter: {employeeName: searchText};let i=index">
                                            <td>{{fosdata.createdByName}}</td>
                                            <td>{{fosdata.totalDSA | number}}</td>
                                            <td>{{fosdata.activeDSA | number}}</td>
                                            <td>{{fosdata.inActiveDSA | number}}</td>
                                            <td>{{fosdata.initiatedDSA | number}}</td>
                                            <td>{{fosdata.approvedDSA | number}}</td>
                                            <td>{{fosdata.totalAgreementReceived | number}}</td>
                                            <td>{{fosdata.totalAgreementPending | number}}</td>
                                            <td>{{fosdata.dsaPendingWithBranch | number}}</td>
                                            <td>{{fosdata.dsaPendingWithHO | number}}</td>
                                            <td>{{fosdata.dsaPendingWithFCU | number}}</td>
                                            <td>{{fosdata.fiPositiveCount | number}}</td>
                                            <td>{{fosdata.fiNegativeCount | number}}</td>

                                        </tr>
                                        <tr style="background: #8bf1ae; font-weight: bold;">
                                            <td>TOTAL</td>
                                            <td>{{FOSDashboardData | total:'totalDSA' | number}}</td>
                                            <td>{{FOSDashboardData | total:'activeDSA' | number}}</td>
                                            <td>{{FOSDashboardData | total:'inActiveDSA' | number}}</td>
                                            <td>{{FOSDashboardData | total:'initiatedDSA' | number}}</td>
                                            <td>{{FOSDashboardData | total:'approvedDSA' | number}}</td>
                                            <td>{{FOSDashboardData | total:'totalAgreementReceived' | number}}</td>
                                            <td>{{FOSDashboardData | total:'totalAgreementPending' | number}}</td>
                                            <td>{{FOSDashboardData | total:'dsaPendingWithBranch' | number}}</td>
                                            <td>{{FOSDashboardData | total:'dsaPendingWithHO' | number}}</td>
                                            <td>{{FOSDashboardData | total: 'dsaPendingWithFCU' | number}}</td>
                                            <td>{{FOSDashboardData | total: 'fiPositiveCount' | number}}</td>
                                            <td>{{FOSDashboardData | total:'fiNegativeCount' | number}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-auto mt-0 mb-4">
                                <button (click)="branchDashboard(currentRegionType,currentRegionID,currentFOSZoneID)"
                                    mat-raised-button class="login-dashboard-color btn-click-effect">
                                    <span>Back</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
