<div class="hold-transition bg-img login-page-bg" style="background-image: url(/assets/images/banner1.jpg)"
  data-overlay="1">
  <div id="wrapper" class="otpscreen">
    <div id="dialog">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="content-top-agile content-top-agile-white h-p100">
          <img src="assets/images/short-logo.png">
        </div>
      </div>

      <h3>Please enter the verification code we sent via SMS and E-mail:</h3>
      <div style="padding-top: 10px;">
        <ng-otp-input #ngOtpInput numbersOnly (onInputChange)="onOtpChange($event)" [config]="config"> </ng-otp-input>
        <div class="form-group" align="center">
          <button class="btn btn-primary btn-embossed custombutn" (click)="verifyOTP()">Verify</button>
        </div>
      </div>      

      <span *ngIf="!hidevalue">
        Resend OTP in {{maxTime}} seconds
      </span>

      <div>
        <span *ngIf="hidevalue">
          Didn't receive the code?<br>
          <a (click)="onResendCode()" [disabled]="hidevalue" style="color: blue;">Resend code</a><br>
        </span>
      </div>

    </div>
  </div>
</div>