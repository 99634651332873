<div class="hold-transition bg-img login-page-bg" style="background-image: url(assets/images/banner1.jpg)"
  data-overlay="1">
  <div class="container h-p100 for_mobile_view">
    <div class="row align-items-center justify-content-md-center h-p100">
      <div class="col-12">
        <div class="row no-gutters justify-content-md-center">

          <div class="col-lg-12 col-md-12 col-12">
            <div class="content-top-agile content-top-agile-white h-p100">
              <img src="assets/images/short-logo.png">
            </div>

          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <h4 class="page-heada">Change Password</h4>
            <div class="p-40 bg-white content-bottom h-p100 login-bg">
              <!--<h3 class="text-center mb-4 mt-0 heading_border">Account Login</h3>-->
              <form class="form-element" [formGroup]="ResetPasswordForm" (ngSubmit)="resetPasswordSubmit()">

                <div *ngIf="this.userDetails.userName" class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-info border-info"><i class="ti-lock"></i></span>
                    </div>
                    <input title="abhishek" type="password" class="form-control pl-15" placeholder="Old Password" id="old_password"
                      [ngClass]="{ 'is-invalid': resetFormSubmit && forget_control.old_password.errors }"
                      formControlName="old_password" required>
                  </div>
                  <span *ngIf="resetFormSubmit && forget_control.old_password.errors" class="error pdl-40">
                    <span *ngIf="forget_control.old_password.errors.required">{{ requireerror }}</span>
                  </span>
                </div>

                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-info border-info"><i class="ti-lock"></i></span>
                    </div>
                    <input type="password" class="form-control pl-15" placeholder="New Password" id="new_password"
                      [ngClass]="{ 'is-invalid': resetFormSubmit && forget_control.new_password.errors}"
                      title="Password must be at least 8 characters long with one uppercase, one lowercase & one numeric character."
                      formControlName="new_password" required>
                  </div>
                  <span *ngIf="resetFormSubmit && forget_control.new_password.errors" class="error pdl-40">
                    <span *ngIf="forget_control.new_password.errors.required">{{ validationMessage.common.requireError }}</span>
                    <!-- <span *ngIf="forget_control.new_password.errors.pattern">{{ validationMessage.common.passwordFormat }}</span> -->
                    <!-- <span *ngIf="forget_control.new_password.errors.minLength">{{ validationMessage.common.invalidData }}</span> -->
                  </span>
                </div>

                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-info border-info"><i class="ti-lock"></i></span>
                    </div>
                    <input #showPass type="password" class="form-control pl-15" placeholder="Confirm Password" id="confirm_password"
                      [ngClass]="{ 'is-invalid': resetFormSubmit && ResetPasswordForm.errors?.misMatch }"
                      title="Confirm password must be similar to new password." formControlName="confirm_password" required>
                  </div>
                  <span class="error pdl-40">
                    <!-- <small class="text-danger" *ngIf="ResetPasswordForm.errors?.misMatch">Passwords do not match</small> -->
                    <span *ngIf="ResetPasswordForm.errors?.misMatch">{{ validationMessage.common.mismatchpassowrd }}</span>
                  </span>
                  <!-- <span *ngIf="resetFormSubmit && forget_control.errors?.misMatch" class="error pdl-40">
                                    <span *ngIf="forget_control.confirm_password.errors.required">{{ requireerror }}</span>
                                    <span *ngIf="forget_control.errors?.misMatch">Password do not match.</span>
                                  </span> -->
                </div>                
                <mat-checkbox style="margin-left: 25px;" (click)="showPass.type=showPass.type=='password'?'text':'password'">Show Password </mat-checkbox>

                <div class="clear"></div>
                <div class="row">


                  <!-- /.col -->
                  <div class="col-12 text-center">
                    <button type="submit" class="btn btn-lg btn-primary btn-block margin-top-10 sign-btn">Reset Password</button>
                  </div>

                </div>
                  <!-- /.col -->
                  <!-- /.col -->
                  <div class="row">

                    <!-- <div class="col-md-6 login-pwd text-left">
                      <a routerLinkActive="active" routerLink="/mytask/queue/my" class="underline_txt"> Back</a>
                    </div> -->

                    <div class="col-md-12 login-pwd text-right">
                      <!-- <a routerLinkActive="active" routerLink="/login" class="underline_txt"> Login Here</a><br> -->
                      <a routerLinkActive="active" (click)="logout()" class="underline_txt"> Login Here</a><br>
                    </div>
                  </div>

                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>