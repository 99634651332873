import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder,  FormGroup, FormControl, Validators} from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ValidationMessage } from 'src/app/constants/validationMessage';
import { BaseComponent } from 'src/app/components/basecomponent/basecomponent.component';
import { UtilityService } from 'src/app/services/utility.service';
import { APIConstant } from 'src/app/constants/apiConstants';
import { regExConstant } from 'src/app/constants/regExConstant';
import { NavigationConstants } from "src/app/constants/navigationConstant";
import Swal from 'sweetalert2';
import { PasswordValidator } from 'src/app/shared/password.validator';
import { Event, Router, NavigationStart } from '@angular/router';
import { SessionStorageService } from 'src/app/storage/session-storage.service';
import { LocalStorageService } from 'src/app/storage/local-storage.service';

declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ChangePasswordComponent implements OnInit {

  // error list
  commonPageValidationMessage=ValidationMessage.common;
  validationMessage = ValidationMessage;
  requireerror: any = ['This is required'];
  

  // Password Form
  ResetPasswordForm : FormGroup;
  resetFormSubmit = false;
  userDetails: any = {};
  userName: string;
  formPermissionData:any;
  navigationCheck:boolean=false;

  constructor(private router: Router, public _fb: FormBuilder, private apiService: ApiService, private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService, private localStorageService: LocalStorageService,) {
    // super(utilityService);
    // this.utilityService = utilityService;
    this.formPermissionData = this.utilityService.getPermission('Change Password', 'ChangePassword');
    if (sessionStorage.getItem('authorizationSequence')) {
      this.userDetails = JSON.parse(sessionStorage.getItem('authorizationSequence'));
      this.userName = this.userDetails.userName;
    }
    if(this.formPermissionData && this.formPermissionData.ChangePassword && this.formPermissionData.ChangePassword.isView==true){
  
    } else {
      if(this.userDetails.passwordChangeRequired!=true){
        this.utilityService.reDirectToUrl(NavigationConstants.MYTASK);
      }
    }
    this.router.events.subscribe((RouterEvent: Event) => {
      if (RouterEvent instanceof NavigationStart) {
        if(!this.navigationCheck){
          this.sessionStorageService.deleteAll();
          this.localStorageService.deleteAll();
        }
      }
    });
  }

  getFormGroups(): FormGroup[] {
    return [this.ResetPasswordForm];
  }

 ngOnInit() {

    this.ResetPasswordForm = this._fb.group({
      old_password:[''  , Validators.required], 
      new_password: ['' , [ 
        Validators.required,
        Validators.pattern(regExConstant.password),
        Validators.minLength(8)
        ]],
      confirm_password: [''  , Validators.required],
   }, { validator: PasswordValidator })
 }


 logout() {
  sessionStorage.clear();
  return this.router.navigate(["/login"]);
  }

 get forget_control(){ return this.ResetPasswordForm.controls; }

 resetPasswordSubmit() {
  

     this.resetFormSubmit = true;
     let NewPassword = this.ResetPasswordForm.getRawValue().new_password;
     let ConfirmPassword = this.ResetPasswordForm.getRawValue().confirm_password;
    //  stop here if form is invalid
    if (NewPassword != ConfirmPassword) {
       Swal.fire({
       allowOutsideClick: false,
       text:'just text',
       html:'<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">'+this.validationMessage.common.differentPassword+'</p>',
     })
       }

     else if (this.ResetPasswordForm.invalid) {
        Swal.fire({
        allowOutsideClick: false,
        text:'just text',
        html:'<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">'+this.validationMessage.common.passwordFormat+'</p>',
      })
        }
         else {
          this.saveRoiData();
     } 
     
   }

   saveRoiData() {
    const resetPasswordData = this.ResetPasswordForm.getRawValue();

    let resetPasswordObj = {
      oldPassword: resetPasswordData.old_password,
      newPassword: resetPasswordData.new_password , 
      confirmPassword:resetPasswordData.confirm_password,
      changeType:"reset"
    }

    this.apiService.post(APIConstant.CHANGEPASSWORD, resetPasswordObj, resObj => {
      Swal.fire({
        allowOutsideClick: false,
        text:'just text',
        html:'<img src="assets/images/success.png" class="alertModalImg"><br><p class="successMsgAlert">'+resObj.responseMsg+'</p>',
      })
      this.navigationCheck=true;
      this.logout();
    },error=>{
      Swal.fire({
        allowOutsideClick: false,
        text:'just text',
        html:'<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">'+error.responseMsg+'</p>',
      })
    });
  }

}
