export const NavigationConstants = {
  MYTASK: "/mytask/queue/my",
  LOGIN: "/login",
  CHANGEPASSWORD:"/change_password",
  PERSONALDETAILS:"/loan/personal-details/{leadID}",
  DEDUPE:"/loan/dupe-verific/{leadID}",
  EMPLOYMENT:"/loan/emp-details/{leadID}",
  BANK_DETAILS: "/loan/bank-details/{leadID}",
  LIABILITY_AND_ASSETS: "/loan/assets/{leadID}",
  REFERENCE:"/loan/ref-details/{leadID}",
  PROPERTY: "/loan/prop-details/{leadID}",
  SUBMIT_APPLICATION: "/loan/submit-application/{leadID}",
  LEGAL_DOCUEMNT: "/disbursal/legal-document/{leadID}",
  LEGAL_REPORT: "/collateral/legal-report/{leadID}",
  DSA_ADD:"master/add_dsa",
  DSA_LIST:"master/manage_dsa",
  BRANCH_ADD:"master/manage_branch",
  VIDEO:"/dmi-video-conference",
  VIDEO2:"/mytask/jitsi",
  THANKYOU: "/thank-you",
  THANKYOU2: "/mytask/thank-you-jitsi",
  CONFERENCE:"/dmi-video-conference",
  PROPERTY_DEDUPE: "/collateral/property-dedupe/{leadID}",
  TECHNICAL_REPORT: "/collateral/report/{leadID}",
  DRAFT_DSA: "/mytask/draft-dsa/",
  PAYMENT_UPDATE: "/postdisbursal/payout-update/paymentUpdate/{leadID}",
  PAYMENT_VERIFICATION: "/postdisbursal/payout-update/paymentVerification/{leadID}",
  PAYOUT_UPDATE: "/postdisbursal/payout-update/{leadID}",
  VIEW_PAYOUT: "/postdisbursal/payout-update/viewPayout/{leadID}",
  PENDING_CLARIFICATION:"/postdisbursal/payout-update/payout-clarification/{leadID}",
  LOGIN_OTP: "/login_otp",
  DCB: "/mytask/tradepartner",
  BUILDER_APF: "/collateral/builderapf",
};
