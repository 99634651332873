import { Injectable } from "@angular/core";
import { Route, CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { SessionStorageService } from "../storage/session-storage.service";
import { UtilityService } from "./utility.service";

@Injectable({
  providedIn: "root"
})
export class AuthService implements CanActivate {  

  constructor(
    private sessionStorageService: SessionStorageService,
    private utility: UtilityService
  ) {}

  //for preventing routing based on token in localStorage
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.sessionStorageService.getData("token");
    const _masters = this.utility.getMasters();

    if (token) {
      return true;
    } else if (!_masters || _masters === null || _masters === undefined) {
      this.utility.saveCurrentURL(state.url);
      this.utility.logout();
    } else {
      this.utility.saveCurrentURL(state.url);
      this.utility.logout();
      return false;
    }
  }

  getToken() {
    return JSON.parse(this.sessionStorageService.getData("token"));
  }

 
}
