import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SearchPipe'
})
export class SearchPipe implements PipeTransform{
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    return this.searchItems(items, searchText.toLowerCase());
   }
  
   private searchItems(items :any[], searchText): any[] {
     let results = [];
      items.forEach(item => {
        if (item.regionName.toLowerCase().includes(searchText) || item.branchName.toLowerCase().includes(searchText)) {
            results.push(item);
        }
      });

      return results;
   }
  }
