import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIConstant } from "src/app/constants/apiConstants";
import { environment } from "src/environments/environment";
import { EmployeeDetails } from "../pages/baseapplication-new/employment-details/employment-details.model";
import { SessionStorageService } from "../storage/session-storage.service";
import { CustomStorage } from "../storage/storageKeys";
import Swal from 'sweetalert2'
import { Router } from '@angular/router';
import { LocalStorageService } from "../storage/local-storage.service";
import { version } from "process";
declare var $: any;

@Injectable({
  providedIn: "root"
})
export class ApiService {
  token: any = "";
  private url = environment.apiUrl;
  documentResObj: any = {};
  docUploadedPath: any;
  reloadedByUserFlag = false;
  autoReloadedFlag = false;
  isVersionChanged: boolean;

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  getAsObservable<T>(action: string, model: any): Observable<T> {
    model = model || {};
    model["_x"] = new Date().getTime();
    return this.http
      .get<T>(`${this.url}${action}`, {
        params: model
      });
  }

  get(action: string, model: any, callback: any, errorCalback?: any) {
    model = model || {};
    model["_x"] = new Date().getTime();
    this.http
      .get<any>(`${this.url}${action}`, {
        params: model
      })
      .subscribe(
        res => {
          if (res && res.responseObj && res.responseObj !== "Failure") {
            callback(res.responseObj);
          } else {
            console.log("resPonse>>>>"+res.responseMsg)
            this.sendError(res.responseMsg || "", errorCalback);
          } if (res && res.appVersion) {
            if (this.localStorageService.getData(CustomStorage.localKeys.version)) {
              if(this.localStorageService.getData(CustomStorage.localKeys.version) != res.appVersion){
                this.isVersionChanged === true
                Swal.fire({
                  title: 'New Version',
                  text: 'A new version of Rapid is available. Please Refresh your browser to load the latest features.',
                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: 'Refresh',
                  cancelButtonText: `Not Now`,
                }).then((result) => {
                  if (result.value === true) {
                    this.reloadedByUserFlag = true;
                    this.localStorageService.setData(CustomStorage.localKeys.version, res.appVersion);
                    window.location.reload();
                    this.isVersionChanged = false;
                  } else {
                    this.autoReloadedFlag = true;
                    this.isVersionChanged = false;
                  }
                });
              }else{
                this.isVersionChanged = false
              }
            }else{
                this.isVersionChanged = false;
                this.localStorageService.setData(CustomStorage.localKeys.version, res.appVersion);
            }
            // this.localStorageService.setData(
            //   CustomStorage.localKeys.version,
            //   res.appVersion
            // );
          }
        },
        err => {          
          this.sendError(err.error || "", errorCalback);
        }
      );
  }
  
  getCopyBankingTransaction(action: string, model: any, callback: any, errorCalback?: any) {
    model = model || {};
    model["_x"] = new Date().getTime();
    this.http
      .get<any>(`${this.url}${action}`, {
        params: model
      })
      .subscribe(
        res => {
          if (res.responseCode == "200") {
            callback(res);
          } else {
            console.log("resPonse>>>>"+res.responseMsg)
            this.sendError(res.responseMsg || "", errorCalback);
          } if (res && res.appVersion) {
            if (this.localStorageService.getData(CustomStorage.localKeys.version)) {
              if(this.localStorageService.getData(CustomStorage.localKeys.version) != res.appVersion){
                this.isVersionChanged === true
                Swal.fire({
                  title: 'New Version',
                  text: 'A new version of Rapid is available. Please Refresh your browser to load the latest features.',
                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: 'Refresh',
                  cancelButtonText: `Not Now`,
                }).then((result) => {
                  if (result.value === true) {
                    this.reloadedByUserFlag = true;
                    this.localStorageService.setData(CustomStorage.localKeys.version, res.appVersion);
                    window.location.reload();
                    this.isVersionChanged = false;
                  } else {
                    this.autoReloadedFlag = true;
                    this.isVersionChanged = false;
                  }
                });
              }else{
                this.isVersionChanged = false
              }
            }else{
                this.isVersionChanged = false;
                this.localStorageService.setData(CustomStorage.localKeys.version, res.appVersion);
            }
          }
        },
        err => {          
          this.sendError(err.error || "", errorCalback);
        }
      );
  }

  getDailyBankTransactions(action: string, model: any, callback: any, errorCalback?: any){
    model = model || {};
    model["_x"] = new Date().getTime();
    this.http
      .get<any>(`${this.url}${action}`, {
        params: model
      })
      .subscribe(
        res => {
          if (res.responseCode == "200") {
            callback(res.responseObj);
          } else {
            this.sendError(res.responseMsg || "", errorCalback);
          } if (res && res.appVersion) {
            if (this.localStorageService.getData(CustomStorage.localKeys.version)) {
              if(this.localStorageService.getData(CustomStorage.localKeys.version) != res.appVersion){
                this.isVersionChanged === true
                Swal.fire({
                  title: 'New Version',
                  text: 'A new version of Rapid is available. Please Refresh your browser to load the latest features.',
                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: 'Refresh',
                  cancelButtonText: `Not Now`,
                }).then((result) => {
                  if (result.value === true) {
                    this.reloadedByUserFlag = true;
                    this.localStorageService.setData(CustomStorage.localKeys.version, res.appVersion);
                    window.location.reload();
                    this.isVersionChanged = false;
                  } else {
                    this.autoReloadedFlag = true;
                    this.isVersionChanged = false;
                  }
                });
              }else{
                this.isVersionChanged = false
              }
            }else{
                this.isVersionChanged = false;
                this.localStorageService.setData(CustomStorage.localKeys.version, res.appVersion);
            }
          }
        },
        err => {          
          this.sendError(err.error || "", errorCalback);
        }
      );
  }
  post(action: string, model: any, callback: any, errorCalback?: any) {
    const t = new Date().getTime();
    this.http
      .post<any>(`${this.url}${action}?_x=${t}`, model, {
      })
      .subscribe(
        res => {
          if (res) {
            callback(res);
          } else {
            this.sendError("", errorCalback);
          }
        },
        err => {
          this.sendError(err.error || "", errorCalback);
        }
      );
  }

  put(action: string, model: any, callback: any, errorCalback?: any) {
    const t = new Date().getTime();
    return this.http
      .put<any>(`${this.url}${action}?_x=${t}`, model, {
      })
      .subscribe(
        res => {
          if (res) {
            callback(res);
          }
          else {
            this.sendError("", errorCalback);
          }
        },
        err => {          
          this.sendError(err.error || "", errorCalback);
        }
      );
  }


  patch(action: string, model: any, callback: any, errorCalback?: any) {
    const t = new Date().getTime();
    return this.http
      .patch<any>(`${this.url}${action}?_x=${t}`, model, {
      })
      .subscribe(
        res => {
          if (res) {
            callback(res);
          }
          else {
            this.sendError("", errorCalback);
          }
        },
        err => {
          this.sendError(err.error || "", errorCalback);
        }
      );
  }

  delete(action: string, model: any, callback: any, errorCalback?: any) {
    const hasValues = obj =>
      Object.values(obj).some(v => v !== null && typeof v !== "undefined");
    if (hasValues(model)) {
      const params = {};
    }
    const t = new Date().getTime();
    this.http
      .delete<any>(`${this.url}${action}?_x=${t}`, {
        params: model
      })
      .subscribe(
        res => {
          if (res) {
            callback(res);
          } else {
            this.sendError("", errorCalback);
          }
        },
        err => {
          this.sendError(err.error || "", errorCalback);
        }
      );
  }

  sendError(msg: string, callbackFn?: any) {
    if (msg == "" || !msg) {
      msg = "Error: Please Contact IT Support Team.";
    }
    if (callbackFn) {
      try {
        callbackFn(msg);
      } catch {
        Swal.fire({
          allowOutsideClick: false,
          text: 'just text',
          html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + msg + '</p>',
        })
      }
    }
    else {
      Swal.fire({
        allowOutsideClick: false,
        text: 'just text',
        html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + "Error: Please Contact IT Support Team." + '</p>',
      })
    }
  }

  downloadFile(applicationDocumentID) {
    // this.http.get<HttpResponse<Blob>>(`${this.url}`+'v1/document/application-document/'+applicationDocumentID, {  headers: this.getHeaders(),responseType:'blob' as 'json'}).subscribe(res=>{
    //     window.open(window.URL.createObjectURL(res), '_blank')
    // });

    this.http.get(`${this.url}` + 'v1/document/application-document/' + applicationDocumentID).subscribe((res) => {
      this.documentResObj = res;
      this.docUploadedPath = this.documentResObj.responseObj.documentPath;
      return window.open(this.docUploadedPath, "download");
    },
      err => {
        Swal.fire({
          allowOutsideClick: false,
          text: 'just text',
          html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + err.error.responseMsg + '</p>',
        })
      });
  }


  // Method for multipart/form-data
  multipartFormPost(action: string, model: any, callback: any) {
    const t = new Date().getTime();
    this.http
      .post<any>(`${this.url}${action}?_x=${t}`, model, {
      })
      .subscribe(
        res => {
          if (res) {
            callback(res);
          } else {
          }
        },
        err => {
        }
      );
  }

  logoutUser(){
    this.http.post(`${this.url}` + APIConstant.LOGOUT_USER, {}, {});
  }


}