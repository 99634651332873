<!-- *ngIf="showLaodingIndiacator" -->
<div *ngIf="showLaodingIndiacator" class="mainloader">
    <div class="newloader2">

        <div id="spinners" class="rectangle-bounce selected">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
        <p *ngIf="loaderText && loaderText!=''" class="loading-text px-10">{{loaderText}}</p>
    </div>
</div>
<app-document-upload></app-document-upload>
<!-- <div *ngIf="showLaodingIndiacator" class="mainloader"><span class="lds-hourglass"></span></div> -->
<app-header
    *ngIf="!(router.url === '/login' || router.url === '/forgot_password' || router.url === '/verify_otp' || router.url === '/login_otp' || router.url === '/change_password' || router.url === '/reset_password' ||
    router.url === '/dmi-video-conference' || router.url === '/thank-you' || router.url === '/dmi-meet')">
</app-header>
<router-outlet></router-outlet>
<app-footer
    *ngIf="!(router.url === '/login' || router.url === '/forgot_password' || router.url === '/verify_otp' || router.url === '/login_otp' || router.url === '/change_password' || router.url === '/reset_password' ||
    router.url === '/dmi-video-conference' || router.url === '/thank-you')">
</app-footer>