import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Colors, Label, SingleDataSet } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
// import * as outLabels from 'chartjs-plugin-piechart-outlabels';


@Component({
  selector: 'app-chart-dashboard',
  templateUrl: './chart-dashboard.component.html',
  styleUrls: ['./chart-dashboard.component.css']
})
export class ChartDashboardComponent implements OnInit {

  showCharts: boolean = false;
  // public pieChartOptions: ChartOptions = {
  //   responsive: true,
  //   legend: {
  //     display: true, // Set this to true or false based on your requirement
  //   }
  // };

  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  // public pieChartPlugins = [pluginDataLabels];
  public barChartPlugins = [pluginDataLabels];
  // public pieChartPlugins = [outLabels];

  public pieChartColors: Array < any > = [{
    backgroundColor: ['#3ba1d9', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400','#3ba1d9', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400','#3ba1d9', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400','#3ba1d9', '#1F618D', '#F1C40F', '#27AE60', '#884EA0', '#D35400'],
    // borderColor: [this.getRandomColor(),this.getRandomColor(),this.getRandomColor(),this.getRandomColor()]
 }];

  public charts: any = [
    {
      type: 'bar',
      title: 'Monthy Trends by files',
      data: {
        legend: true,
        labels: ['Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22', 'Jan 23', 'Feb 23', 'Mar 23', 'Apr 23', 'May23', 'June 23'],
        chartData: [
          { data: [747, 756, 787, 753, 805, 921, 874, 889, 925, 810, 883, 803, 824],
            label: 'Login',
            stack: 'a',
            backgroundColor: '#884EA0',
            hoverBackgroundColor: '#884EA0'
          },
          { data: [435, 513, 491, 467, 504, 528, 562, 554, 586, 475, 515, 468, 485],
            label: 'Sanction',
            stack: 'a',
            backgroundColor: '#1F618D',
            hoverBackgroundColor:'#1F618D'
           },
          { data: [283, 317, 335, 316, 334, 365, 347, 368, 441, 284, 327, 335, 287],
            label: 'FDs',
            stack: 'a',
            backgroundColor: '#F1C40F',
            hoverBackgroundColor: '#F1C40F'
          }
        ],
        plugins: []
      },
      options: {
        responsive: true,
        scales: { xAxes: [{}], yAxes: [{}] },
        plugins: {
          datalabels: {
            display: true,
            anchor: 'middle',
            align: 'middle',
            color: "#fff",
            font: {
              size: 12,
              color: '#fff',
            }
          }
        }
      }
    },
    {
      type: 'line',
      title: 'Day wise Login',
      data: {
        legend: true,
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
        chartData: [
          { data: [0,27,28,93,152,153,153,153,211,262,316,370,450,474,474,510,556,588,641,673,695,695,723,761,779,796,805,809,810,810],
            label: 'April 2023',
            fill: false,
            stack: 'a',
            borderColor: '#884EA0',
            pointBackgroundColor: '#884EA0',
            hoverBackgroundColor: '#884EA0'
          },
          { data: [67,122,173,234,234,234,249,264,295,338,382,423,423,468,502,541,595,649,717,717,747,772,792,833,849,867,888,902,911,929],
            label: 'May 2023',
            fill: false,
            stack: 'a',
            borderColor: '#1F618D',
            pointBackgroundColor: '#1F618D',
            hoverBackgroundColor: '#1F618D'
           },
          { data: [57,58,58,106,154,208,255,296,296,296,361,409,449,492,533,598,598,635,660,689,720,757,775,781,800,825,830,838,839,839],
            label: 'June 2023',
            fill: false,
            stack: 'a',
            borderColor: '#F1C40F',
            pointBackgroundColor: '#F1C40F',
            hoverBackgroundColor: '#F1C40F'
          },
          { data: [0,47,80,125,178,217,217,217,251,306,359,400,443,502,502,545,577,605,634,670,711,711,746,778,798,824,845,856,858,865],
            label: 'July 2023',
            fill: false,
            stack: 'a',
            borderColor:'#1E618C',
            pointBackgroundColor:'#1E618C',
            hoverBackgroundColor:'#1E618C'
          },
          { data: [81,134,134],
            label: 'August 2023',
            fill: false,
            stack: 'a',
            borderColor:  '#B1A40F',
            pointBackgroundColor:  '#B1A40F',
            hoverBackgroundColor:  '#B1A40F'
          },
        ],
        plugins: []
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          }
        },
        plugins: {
          datalabels: {
            display: false,
            anchor: 'top',
            align: 'top',
            font: {
              size: 10,
            }
          }
        }
      }
    }, {
      type: 'line',
      title: 'Day wise Sanction',
      data: {
        legend: true,
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
        chartData: [
          { data: [0, 0, 0, 10, 22, 22, 22, 23, 50, 84, 97, 122, 148, 165, 165, 195, 228, 250, 289, 311, 333, 333, 352, 384, 400, 426, 441, 457, 475, 475],
            label: 'April 2023',
            fill: false,
             stack: 'a',
            borderColor: '#884EA0',
            pointBackgroundColor: '#884EA0',
            hoverBackgroundColor: '#884EA0'
          },
          { data: [9, 21, 40, 57, 57, 57, 58, 60, 70, 103, 128, 156, 157, 188, 207, 237, 259, 293, 314, 314, 340, 380, 402, 425, 451, 466, 477, 496, 518, 539],
            label: 'May 2023',
            fill: false,
            stack: 'a',
            borderColor: '#1F618D',
            pointBackgroundColor: '#1F618D',
            hoverBackgroundColor: '#1F618D'
          },
          { data: [11, 11, 11, 23, 40, 62, 80, 96, 99, 99, 129, 156, 188, 209, 240, 258, 260, 285, 312, 331, 343, 360, 381, 408, 427, 441, 456, 476, 492, 492],
            label: 'June 2023',
            fill: false,
            stack: 'a',
            borderColor: '#F1C40F',
            pointBackgroundColor: '#F1C40F',
            hoverBackgroundColor: '#F1C40F'
          },
          { data: [1, 6, 13, 27, 49, 62, 62, 63, 81, 104, 126, 149, 175, 193, 196, 230, 256, 271, 295, 313, 334, 335, 365, 386, 407, 431, 443, 465, 479, 498],
            label: 'July 2023',
            fill: false,
             stack: 'a',
            borderColor:'#1E618C',
            pointBackgroundColor:'#1E618C',
            hoverBackgroundColor:'#1E618C'
          },
          { data: [2, 13, 29, 31],
            label: 'August 2023',
            fill: false,
             stack: 'a',
            borderColor:  '#B1A40F',
            pointBackgroundColor:  '#B1A40F',
            hoverBackgroundColor:  '#B1A40F'
          },
        ],
        plugins: []
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          }
        },
        plugins: {
          datalabels: {
            display: false,
            anchor: 'top',
            align: 'top',
            font: {
              size: 10,
            }
          }
        }
      }
    }, {
      type: 'line',
      title: 'Day wise Disbursement',
      data: {
        legend: {
          display: true,
          labels: {
            padding: 50,
          }
        },
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
        chartData: [
          {
            data: [0, 0, 2, 2, 8, 19, 20, 20, 20, 41, 56, 73, 82, 93, 108, 108, 120, 135, 149, 184, 199, 215, 215, 229, 260, 286, 311, 353, 408, 498],
            label: 'April 2023',
            fill: false,
            stack: 'a',
            borderColor: '#884EA0',
            pointBackgroundColor: '#884EA0',
            hoverBackgroundColor: '#884EA0'
          },
          {
            data: [2, 8, 16, 20, 38, 38, 38, 38, 40, 49, 64, 75, 83, 83, 106, 136, 158, 178, 195, 241, 241, 252, 266, 282, 313, 356, 382, 405, 440, 478],
            label: 'May 2023',
            fill: false,
            stack: 'a',
            borderColor: '#1F618D',
            pointBackgroundColor: '#1F618D',
            hoverBackgroundColor: '#1F618D'
          },
          {
            data: [8, 12, 12, 12, 20, 28, 44, 61, 77, 77, 77, 87, 126, 145, 161, 176, 191, 191, 231, 252, 267, 288, 302, 342, 356, 382, 413, 450, 502, 588],
            label: 'June 2023',
            fill: false,
            stack: 'a',
            borderColor: '#F1C40F',
            pointBackgroundColor: '#F1C40F',
            hoverBackgroundColor: '#F1C40F'
          },
          {
            data: [1, 1, 2, 8, 18, 25, 51, 51, 51, 54, 68, 79, 92, 116, 130, 130, 147, 161, 183, 214, 236, 259, 259, 289, 309, 331, 357, 392, 420, 456],
            label: 'July 2023',
            fill: false,
            stack: 'a',
            borderColor:'#1E618C',
            pointBackgroundColor:'#1E618C',
            hoverBackgroundColor:'#1E618C'
          },
          {
            data: [2, 8, 22],
            label: 'August 2023',
            fill: false,
            stack: 'a',
            borderColor:  '#B1A40F',
            pointBackgroundColor:  '#B1A40F',
            hoverBackgroundColor:  '#B1A40F'
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          }
        },
        plugins: {
          datalabels: {
            display: false,
            anchor: 'top',
            align: 'top',
            font: {
              size: 10,
            }
          }
        },

      }
    }, {
      type: 'bar',
      title: 'Monthy Trends by Amount (in Cr)',
      data: {
        legend: true,
        labels: ['Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22', 'Jan 23', 'Feb 23', 'Mar 23', 'Apr 23', 'May23', 'June 23'],
        chartData: [
          {
            data: [116.27, 123.23, 144.47, 154.98, 137.88, 215.18, 157.02, 157.56, 259.4, 140.3, 163.9, 154.59, 165.52],
            label: 'Login',
            stack: 'a',
            backgroundColor: '#3ba1d9',
            hoverBackgroundColor: '#3ba1d9'
          },
          {
            data: [52.57, 65.48, 59.02, 56.89, 62.46, 71.00, 72.72, 71.49, 76.94, 60.28, 66.71, 65.42, 66.55],
            label: 'Sanction',
            stack: 'a',
           backgroundColor: '#884EA0',
            hoverBackgroundColor: '#884EA0'
          },
          {
            data: [34.97, 43.09, 42.17, 40.29, 41.33, 48.84, 44.28, 48.94, 58.75, 39.09, 42.71, 47.68, 42.69],
            label: 'Disbursement',
            stack: 'a',
            backgroundColor: '#114EE1',
            hoverBackgroundColor: '#114EE1'
          }

        ],
        plugins: []
      },
      options: {
        responsive: true,
        scales: { xAxes: [{}], yAxes: [{}] },
        plugins: {
          datalabels: {
            display: true,
            anchor: 'middle',
            align: 'middle',
            color: '#fff',

            font: {
              size: 12,
            }
          }
        }
      }
    }, {
      type: 'dougnut',
      title: 'POS',

      data: {
        legend: true,
        labels: ['Ho Purchase', 'Zone 1', 'Zone 2'],
        chartData: [120, 80, 50],
        options: {
          // layout: { padding: { left: 20, right: 80, top: 100, bottom: 60 }},
          //showAllTooltips:true,
          legend: {
            display: true,
            labels: {
              fontColor: 'black',
              usePointStyle: true,
            },
            position: 'left'
          },
          pieceLabel: {
            render: function (args) {
              const label = args.label,
                value = args.value;
              return label + ' = ' + value;
            },
          },

        }
      }
    }, {
      type: 'doughnut',
      title: 'HFC Product (POS in Cr)',
      data: {
        legend: true,
        labels: ['Home Loan', 'Non Home Loan'],
        chartData: [1070.22, 322.41],

        options: {
          // layout: { padding: { left: 20, right: 80, top: 100, bottom: 60 }},
          legend: {
            display: true,
            labels: {
              fontColor: 'black',
              usePointStyle: true,
            },
            position: 'left'
          },
          plugins: {
            outlabels: {
              // backgroundColor: null,
              font: {
                resizable: true,
                minSize: 12,
                maxSize: 20,
              },
              // zoomOutPercentage: 100,
              textAlign: 'start',
            }
          }
        }
      },
    }, {
      type: 'doughnut',
      title: 'Zone Summary (POS in Cr)',
      data: {
        legend: true,
        labels: ['Ho Purchase', 'Zone 1', 'Zone 2'],
        color:'#fff',
        chartData: [148.09, 605.43, 638.59],
        options: {
          // layout: { padding: { left: 20, right: 80, top: 100, bottom: 60 }},
          legend: {
            display: true,
            labels: {
              datalabels: {
                display: false,
              },
              fontColor: 'black',
              usePointStyle: true,
            },
            position: 'left'
          },
          plugins: {
            outlabels: {
              // backgroundColor: null,
              font: {
                resizable: true,
                minSize: 12,
                maxSize: 12,
              },
              // zoomOutPercentage: 100,
              textAlign: 'start',
            }
          }
        }
      }
    }, {
      type: 'doughnut',
      title: 'HFC Loan Purpose (POS in Cr)',
      data: {
        legend: true,
        labels: ['Flat Purchase', 'Plot Loan', 'Constuction/Extension/Improvement/Renovation', 'HL-BT', 'Top Up', 'LAP BT + Top Up', 'LAP', 'Plot Purchase + Construction', 'House Purchase', 'LAP BT', 'Seller BT - House Purchase', 'BT + Extension', 'NRP-LAP', 'NRP', 'Pre-Approved Top Up', 'Seller BT - Flat Purchase', 'Top Up-LAP', 'P+C Builder/Developer'],
        chartData: [347.7, 41.33, 237.72, 42.47, 31.39, 14.54, 116.02, 120.63, 274.42, 2.14, 9.15, 29.82, 2.42, 114.12, 0.26, 7.67, 0.14, 0.15],
        maxLegendItems: 5,
        options: {
          // layout: { padding: { left: 20, right: 20, top: 175, bottom: 115 }},
          legend: {
            fullWidth: false,
            display: true,
            position: 'left',
            labels: {
              fontColor: 'black',
              usePointStyle: true,
              fontSize:9
            },
          },
          plugins: {
            position:'left',
          outlabels: {
            // backgroundColor: null,
            font: {
              resizable: true,
              minSize: 12,
              maxSize: 20,
            },
            // zoomOutPercentage: 100,
            textAlign: 'start',
          },

        },
        },
      }
    }, {
      type: 'doughnut',
      title: 'Loan Scheme (POS in Cr)',
      data: {
        legend: true,
        labels: ['Regular ITR', 'Flat Loan Tenure', 'Cash Salary', 'Bank Salary', 'Assessed Income', 'Liquid Income Multiplier', 'Bank Salary + Cash Salary', 'Flip Bank Salary', 'Banking Surrogate', 'GTP/GST Program'],
        chartData: [118.87, 93.23, 45.85, 901.74, 148.51, 46.56, 34.04, 2.3, 0.77, 0.24],
        options: {
          // layout: { padding: { left: 20, right: 80, top: 60, bottom: 60 }},
          legend: {
            fullWidth: false,
            display: true,
            position: 'left',
            labels: {
              fontColor: 'black',
              usePointStyle: true,
              fontSize:10
            },
          },
          plugins: {
            outlabels: {
              // backgroundColor: null,
              font: {
                resizable: true,
                minSize: 12,
                maxSize: 20,
              },
              // zoomOutPercentage: 100,
              textAlign: 'start',
            }
          }
        },

      }
    }, {
      type: 'doughnut',
      title: 'Asset Type (POS in Cr)',
      data: {
        legend: true,
        labels: ['NPA', 'Standard'],
        chartData: [10.24, 1381.86],
        options: {
          // layout: { padding: { left: 20, right: 80, top: 60, bottom: 60 }},
          legend: {
            display: true,
            position: 'left',
            labels: {
              fontColor: 'black',
              usePointStyle: true,
            },
          },
          plugins: {
            outlabels: {
              // backgroundColor: null,
              font: {
                resizable: true,
                minSize: 12,
                maxSize: 20,
              },
              // zoomOutPercentage: 100,
              textAlign: 'start',
            }
          }
        }
      }
    }, {
      type: 'doughnut',
      title: 'Asset Type(NPA) (Number of Loans)',
      data: {
        legend: true,
        labels: ['NPA', 'Standard'],
        chartData: [108, 13807],
        options: {
          layout: { padding: { left: 0, right: 0, top: 0, bottom: 20 }},
          legend: {
            display: true,
            position: 'left',
            labels: {
              fontColor: 'black',
              usePointStyle: true,
            },
          },
          plugins: {
            outlabels: {
              // backgroundColor: null,
              font: {
                resizable: true,
                minSize: 12,
                maxSize: 20,
              },
              // zoomOutPercentage: 100,
              textAlign: 'start',
            }
          }
        }
      }
    }
  ]

  public barCharts: any = this.charts.filter(chart => {
    if (chart.type == 'bar' || chart.type == 'horizontalBar') { return chart }
  })

  public pieCharts: any = this.charts.filter(chart => {
    if (chart.type == 'pie' || chart.type == 'doughnut') { return chart }
  })

  public lineCharts: any = this.charts.filter(chart => {
    if (chart.type == 'line') { return chart }
  })
  constructor() {}

  ngOnInit(): void {
  }

  public chartClicked({ event, active }): void {

    // let isx: any = active[0]._index
    // console.log(active[0]._chart.config.data.datasets[0].data);
    let dset = active[0]?._chart.config.data.datasets[0].data;
    // console.log(dset[isx]);
  }

  showcharts() {
    this.showCharts = !this.showCharts;
  }

}
