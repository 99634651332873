import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ValidationMessage } from 'src/app/constants/validationMessage';
import { BaseComponent } from 'src/app/components/basecomponent/basecomponent.component';
import { UtilityService } from 'src/app/services/utility.service';
import { APIConstant } from 'src/app/constants/apiConstants';
import { regExConstant } from 'src/app/constants/regExConstant';
import Swal from 'sweetalert2';
import { PasswordValidator } from 'src/app/shared/password.validator';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/local-storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
  // error list
  commonPageValidationMessage = ValidationMessage.common;
  validationMessage = ValidationMessage;

  // Password Form
  ResetPasswordForm: FormGroup;
  resetFormSubmit = false;
  userDetails: any = {};
  userName: string;

  @ViewChild('Ba_Otp1', { static: true }) Ba_Otp1: ElementRef;
  @ViewChild('Ba_Otp2', { static: true }) Ba_Otp2: ElementRef;
  @ViewChild('Ba_Otp3', { static: true }) Ba_Otp3: ElementRef;
  @ViewChild('Ba_Otp4', { static: true }) Ba_Otp4: ElementRef;
  @ViewChild('Ba_Otp5', { static: true }) Ba_Otp5: ElementRef;
  verify_otp: FormGroup;
  isInvalid = false;

  constructor(private router: Router, public _fb: FormBuilder, private localStorageService: LocalStorageService,
    private apiService: ApiService, private utilityService: UtilityService) {
    super(utilityService);
    this.utilityService = utilityService;
  }

  ngOnInit() {
    this.Ba_Otp1.nativeElement.focus();
    this.ResetPasswordForm = this._fb.group({
      Ba_Otp1: ['', [
      Validators.required,
      Validators.maxLength(1),
      Validators.max(9),
      Validators.min(0),
      Validators.pattern(regExConstant.onlyNumber)
      ]],
      Ba_Otp2: ['', [Validators.required,
      Validators.maxLength(1),
      Validators.max(9),
      Validators.min(0),
      Validators.pattern(regExConstant.onlyNumber)
      ]],
      Ba_Otp3: ['', [Validators.required,
      Validators.maxLength(1),
      Validators.max(9),
      Validators.min(0),
      Validators.pattern(regExConstant.onlyNumber)
      ]],
      Ba_Otp4: ['', [Validators.required,
      Validators.maxLength(1),
      Validators.max(9),
      Validators.min(0),
      Validators.pattern(regExConstant.onlyNumber)
      ]],
      new_password: ['', [
        Validators.required,
        Validators.pattern(regExConstant.password),
        Validators.minLength(8)
      ]],
      confirm_password: ['', Validators.required],
    }, { validator: PasswordValidator })
  }

  get forget_control() { return this.ResetPasswordForm.controls; }

  resetPasswordSubmit() {
    this.resetFormSubmit = true;
    let NewPassword = this.ResetPasswordForm.getRawValue().new_password;
    let ConfirmPassword = this.ResetPasswordForm.getRawValue().confirm_password;
    //  stop here if form is invalid
    if (NewPassword != ConfirmPassword) {
      Swal.fire({
        allowOutsideClick: false,
        text: 'just text',
        html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.differentPassword + '</p>',
      })
    }
    else if (this.ResetPasswordForm.invalid) {
      Swal.fire({
        allowOutsideClick: false,
        text: 'just text',
        html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.passwordFormat + '</p>',
      })
    }
    else {
      const resetPasswordData = this.ResetPasswordForm.getRawValue();
      let userNameValue = this.localStorageService.getData("forgot_user_name");
      let resetPasswordPayload = {
        userName: userNameValue,
        newPassword: resetPasswordData.new_password,
        company: {
          companyId: 1,
          companySlug: "DMI_HFC"
        }
      }
      this.apiService.post(APIConstant.RESET_PASSWORD, resetPasswordPayload, resObj => {
        Swal.fire({
          allowOutsideClick: false,
          text: 'just text',
          html: '<img src="assets/images/success.png" class="alertModalImg"><br><p class="successMsgAlert">' + resObj.responseMsg + '</p>',
        });
        this.logout();
      }, error => {
        Swal.fire({
          allowOutsideClick: false,
          text: 'just text',
          html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + error.responseMsg + '</p>',
        })
      });
    }
  }

  logout() {
    sessionStorage.clear();
    return this.router.navigate(["/login"]);
  }

  verifyOTP(formOtpVerify: FormGroupDirective) {
    this.resetFormSubmit = true;
    let NewPassword = this.ResetPasswordForm.getRawValue().new_password;
    let ConfirmPassword = this.ResetPasswordForm.getRawValue().confirm_password;

    if (NewPassword != ConfirmPassword) {
      Swal.fire({
        allowOutsideClick: false,
        text: 'just text',
        html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.differentPassword + '</p>',
      })
    }else if (this.ResetPasswordForm.invalid) {
      this.isInvalid = true;      
      Swal.fire({
        allowOutsideClick: false,
        text: 'just text',
        html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.passwordFormat + '</p>',
      })
      return;
    } else {
      const resetPasswordData = this.ResetPasswordForm.getRawValue();
      let userNameValue = this.localStorageService.getData("forgot_user_name");
      const forgotPassword_id = this.localStorageService.getData("forgetPassword_id");
      let OTPverifcationCode = this.ResetPasswordForm.controls["Ba_Otp1"].value +
        this.ResetPasswordForm.controls["Ba_Otp2"].value +
        this.ResetPasswordForm.controls["Ba_Otp3"].value +
        this.ResetPasswordForm.controls["Ba_Otp4"].value;
      let otpVerifyPayload = {
        // userName: userNameValue,
        newPassword: resetPasswordData.new_password,
        confirmPassword: resetPasswordData.confirm_password,
        otpValue: OTPverifcationCode,
        id:forgotPassword_id,
            company: {
          companyId: 1,
          companySlug: "DMI_HFC"
        }
      };
      this.utilityService.showLoader();
      this.apiService.post(APIConstant.VERIFY_OTP, otpVerifyPayload, resObj => {
        this.utilityService.hideLoader();
        this.localStorageService.deleteData('forgetPassword_id');
        this.localStorageService.deleteData('forgot_user_name');
        Swal.fire({
          allowOutsideClick: false,
          text: 'just text',
          html: '<img src="assets/images/success.png" class="alertModalImg"><br><p class="successMsgAlert">' + resObj.responseMsg + '</p>',
        });
        this.logout();
      }, error => {
        this.utilityService.hideLoader();
        this.ResetPasswordForm.controls["Ba_Otp1"].setValue('');
        this.ResetPasswordForm.controls["Ba_Otp2"].setValue('');
        this.ResetPasswordForm.controls["Ba_Otp3"].setValue('');
        this.ResetPasswordForm.controls["Ba_Otp4"].setValue('');
        this.Ba_Otp1.nativeElement.focus();
        Swal.fire({
          allowOutsideClick: false,
          text: 'just text',
          html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + error.responseMsg + '</p>',
        })
      });
    }
  }

  onKeyUp(otp, otpKey) {
    this.isInvalid = false;
    if (otp != '') {
      switch (otpKey) {
        case 'Ba_Otp1': {
          this.Ba_Otp2.nativeElement.focus();
          this.ResetPasswordForm.controls["Ba_Otp2"].setValue('');
          break;
        }
        case 'Ba_Otp2': {
          this.Ba_Otp3.nativeElement.focus();
          this.ResetPasswordForm.controls["Ba_Otp3"].setValue('');
          break;
        }
        case 'Ba_Otp3': {
          this.Ba_Otp4.nativeElement.focus();
          this.ResetPasswordForm.controls["Ba_Otp4"].setValue('');
          break;
        }
        case 'Ba_Otp4': {
          this.Ba_Otp5.nativeElement.focus();
          break;
        }
        default: {
          this.Ba_Otp1.nativeElement.focus();
          this.ResetPasswordForm.controls["Ba_Otp1"].setValue('');
          break;
        }
      }
    }

  }
}
