<div class="hold-transition bg-img login-page-bg" style="background-image: url(assets/images/banner1.jpg)"
  data-overlay="1">
  <div class="container h-p100 for_mobile_view">
    <div class="row align-items-center justify-content-md-center h-p100">
      <div class="col-12">
        <div class="row no-gutters justify-content-md-center">

          <div class="col-lg-12 col-md-12 col-12">
            <div class="content-top-agile content-top-agile-white h-p100">
              <img src="assets/images/short-logo.png">
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <h4 class="page-heada">Please enter the OTP we sent via SMS:</h4>
            <div class="p-40 bg-white content-bottom h-p100 login-bg">
              <form class="form-element" [formGroup]="ResetPasswordForm"  #formOtpVerify="ngForm" (ngSubmit)="verifyOTP(formOtpVerify)">
                <div class="row">
                  <div class="form-group col-md-3">
                    <mat-form-field>
                      <input matInput numbersOnly type="text" class="otpInput"
                        [ngClass]="{ 'is-invalid': resetFormSubmit && forget_control.Ba_Otp1.errors }" formControlName="Ba_Otp1"
                        maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onKeyUp($event.value,'Ba_Otp1')"
                        #Ba_Otp1 />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-3">
                    <mat-form-field>
                      <input matInput numbersOnly type="text" class="otpInput"
                        [ngClass]="{ 'is-invalid': resetFormSubmit && forget_control.Ba_Otp2.errors }" formControlName="Ba_Otp2"
                        maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onKeyUp($event.value,'Ba_Otp2')"
                        #Ba_Otp2 />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-3">
                    <mat-form-field>
                      <input matInput numbersOnly type="text" class="otpInput"
                        [ngClass]="{ 'is-invalid': resetFormSubmit && forget_control.Ba_Otp3.errors }" formControlName="Ba_Otp3"
                        maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onKeyUp($event.value,'Ba_Otp3')"
                        #Ba_Otp3 />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-3">
                    <mat-form-field>
                      <input matInput numbersOnly type="text" [ngClass]="{ 'is-invalid': resetFormSubmit && forget_control.Ba_Otp4.errors }"
                        formControlName="Ba_Otp4" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}"
                        class="Otp4_valsdasd otpInput" (keyup)="onKeyUp($event.value,'Ba_Otp4')" #Ba_Otp4 />
                    </mat-form-field>
                  </div>
                  <span class="error" *ngIf="isInvalid">
                    <span
                      *ngIf="forget_control.Ba_Otp1.errors.required || forget_control.Ba_Otp2.errors.required || forget_control.Ba_Otp3.errors.required || forget_control.Ba_Otp4.errors.required">{{ validationMessage.common.OTP_ERROR }}</span>
                  </span>
                  <br>
                </div>
                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-info border-info"><i class="ti-lock"></i></span>
                    </div>
                    <input type="password" class="form-control pl-15" placeholder="New Password" id="new_password"
                      [ngClass]="{ 'is-invalid': resetFormSubmit && forget_control.new_password.errors}"
                      title="Password must be at least 8 characters long with one uppercase, one lowercase & one numeric character."
                      formControlName="new_password" required>
                  </div>
                  <span *ngIf="resetFormSubmit && forget_control.new_password.errors" class="error pdl-40">
                    <span *ngIf="forget_control.new_password.errors.required">{{ validationMessage.common.requireError }}</span>
                  </span>
                </div>
                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-info border-info"><i class="ti-lock"></i></span>
                    </div>
                    <input #showPass type="password" class="form-control pl-15" placeholder="Confirm Password" id="confirm_password"
                      [ngClass]="{ 'is-invalid': resetFormSubmit && ResetPasswordForm.errors?.misMatch }"
                      title="Confirm password must be similar to new password."
                      formControlName="confirm_password" required>
                  </div>
                  <span class="error pdl-40">
                    <span *ngIf="ResetPasswordForm.errors?.misMatch">{{ validationMessage.common.mismatchpassowrd }}</span>
                  </span>
                </div>
                <mat-checkbox style="margin-left: 25px;" (click)="showPass.type=showPass.type=='password'?'text':'password'">Show Password </mat-checkbox>
                
                <div class="clear"></div>
                <div class="row">
                  <div class="col-12 text-center">
                    <button type="submit" class="btn btn-lg btn-primary btn-block margin-top-10 sign-btn" #Ba_Otp5>Reset Password</button>
                  </div>
                </div>
                  <div class="row">
                    <div class="col-md-6 login-pwd text-left">
                      <a routerLinkActive="active" routerLink="/mytask/queue/my" class="underline_txt"> Back</a>
                    </div>
                    <div class="col-md-6 login-pwd text-right">
                      <a routerLinkActive="active" (click)="logout()" class="underline_txt"> Login Here</a><br>
                    </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>