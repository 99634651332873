import { Component, OnInit } from '@angular/core';
import { Event, Router, NavigationEnd } from '@angular/router';

import { UtilityService } from "src/app/services/utility.service";
import { SessionStorageService } from "src/app/storage/session-storage.service";
import { CustomStorage } from "src/app/storage/storageKeys";
import { NavigationConstants } from "src/app/constants/navigationConstant";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  dcbRedirect:any=false;

  constructor(
    private utilityService: UtilityService,
    private sessionStorageService: SessionStorageService,
    public router: Router,
  ) {
  }
  
  ngOnInit() {
    this.router.events.subscribe((RouterEvent: Event) => {
      if (RouterEvent instanceof NavigationEnd) {
        this.dcbRedirect=this.sessionStorageService.getData(
          CustomStorage.sessionKeys.dcbRedirect
        );        
      }
    });

  }
  backToDCB(){
    this.utilityService.reDirectToUrl(NavigationConstants.DCB);
  }

}
