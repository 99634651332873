
  <div class="document-container">
  <div class="my_tab_blk all-document-container">
    <h4 class="inte-box-title"><strong>Documents</strong></h4>

    <ul class="nav nav-tabs nav-tabs-inverse-mode">
      <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#viewDocument">View All Documents</a></li>
      <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#uploadDocument">Upload Documents</a></li>
    </ul>
    <div class="tab-content doc-tab-content">
      <div id="viewDocument" class="tab-pane active">
        <ul *ngIf="documentCodeList.length" class="nav nav-tabs nav-tabs-inverse-mode">
          <li class="nav-item" *ngFor="let documents of documentCodeList; let i=index;">
            <a  class="nav-link sub-tab-color" 
            [ngClass]="(i==activeTabKey)?'active':''" data-toggle="tab" 
            href="#documentTabData" (click)="docTabClick(i)" >{{documents.docCodeName}}</a>
          </li>
        </ul>
        <div *ngIf="documentCodeList.length" class="tab-content doc-tab-content" style="box-shadow: none;">
          <div id="documentTabData" class="tab-pane active">
            <div class="table-responsive allDocList">
              <table id="invoice-list" class="table my_bank_table table-hover no-wrap scrollTable">
              <thead>
                <tr>
                  <th>Applicant/Co-Applicant Name</th>
                  <th>Document Type</th>
                  <th>Document Name</th>
                  <th>Document Image/Pdf</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="(documentTabDataList || []).length == 0">
                  <tr class="text-center">
                     <td colspan="4">No Information Available</td>
                  </tr>
                 </ng-container>
                <tr *ngFor="let document of documentTabDataList">
                  <td>
                    <span *ngIf="document.applicantName !== null">{{document.applicantName}}</span>
                    <span *ngIf="document.applicantName == null">No Applicant</span>
                  </td>
                  <td >{{document.documentSubTypeDetailDisplayText}}</td>
                  <td >{{document.documentName}}</td>
                  <td class="action">
                    <a class="view-icon" (click)="viewDocument(document,imgModal)" download><i class="fa fa-eye " aria-hidden="true"></i></a>
                    <a class="download-button" (click)="downloadDocument(document)" download><i class="fa fa-download " aria-hidden="true"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
      </div>
      </div>
      <div id="uploadDocument" class="tab-pane">
        <form [formGroup]="documentUploadForm" (ngSubmit)="uploadDocument()">
          <div class="col-md-12 upload-imput-tyle">
            <div class="row">
          <!-- <div class="col-md-4 pl">
            <div class="form-group">
              <label>Document Code</label>
              <select class="form-control" formControlName="documentCode" (change)="changeDocCode($event.target.value)">
                <option value="" disabled selected>Select Code</option>
                <option *ngFor="let docCode of documentTypeList" [value]="docCode.docValue">
                  {{docCode.docCodeName}}</option>
              </select>
              <span *ngIf="docCodeErrMsg" class="error ng-star-inserted margin-top0">{{docCodeErrMsg}}</span>
            </div>
          </div>
          <div class="col-md-4 pl">
            <div class="form-group">
              <label>Applicant/Co-applicant</label>
              <select class="form-control" formControlName="documentApplicant" (change)="changeApplicant($event.target.value)">
                <option value="" disabled selected>Select Applicant</option>
                <option *ngFor="let applicants of applicantList" [value]="applicants.leadApplicantNumber">
                  {{applicants.firstName+' '+applicants.middleName+' '+applicants.lastName}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 pl">
            <div class="form-group">
              <label>Document Type</label>
              <select class="form-control" formControlName="documentType" (change)="docTypeValue()" >
                <option value="" disabled selected>Select Document</option>
                <option *ngFor="let documents of documentDropdownList" [value]="documents.documentID">
                  {{documents.documentSubTypeDetailDisplayText}}</option>
              </select>
              <span *ngIf="docTypeRequiredMsg" class="error ng-star-inserted margin-top0">{{docTypeRequiredMsg}}</span>
            </div>
          </div>
          <div class="col-md-4 pl top15">
            <div class="form-group">
              <label>Document Name</label>
              <input type="text" class="form-control" formControlName="documentName" (keyup)="resetInputValue()">
              <span *ngIf="docNameRequiredMsg" class="error ng-star-inserted margin-top0">{{docNameRequiredMsg}}</span>
            </div>
          </div> -->
          
          <div class="col-md-4 pl">
            <div class="form-group">
              <mat-form-field>
                <mat-label>Document Code</mat-label>
                <mat-select disableOptionCentering id="documentCode" type="text"
                  (selectionChange)="changeDocCode($event.value)" 
                  formControlName="documentCode" autocomplete="off">
                  <mat-option 
                  *ngFor="let docCode of documentTypeList"
                  [value]="docCode.docValue">
                  {{docCode.docCodeName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="docCodeErrMsg" class="error ng-star-inserted margin-top0">{{docCodeErrMsg}}</span> 
            </div>
          </div>
          <div class="col-md-4 pl">
            <div class="form-group">
              <mat-form-field>
                <mat-label>Applicant/Co-applicant</mat-label>
                <mat-select disableOptionCentering id="documentApplicant" 
                  (selectionChange)="changeApplicant($event.value)"
                  formControlName="documentApplicant" autocomplete="off">
                  <mat-option 
                  *ngFor="let applicants of applicantList" 
                  [value]="applicants.leadApplicantNumber">
                  {{applicants.firstName+' '+applicants.middleName+' '+applicants.lastName}}
                  </mat-option>
                </mat-select>
              </mat-form-field> 
            </div>
          </div>
          <div class="col-md-4 pl">
            <div class="form-group">
              <mat-form-field>
                <mat-label>Document Type</mat-label>
                <mat-select disableOptionCentering id="documentType" type="text"
                  (selectionChange)="docTypeValue()" 
                  formControlName="documentType" autocomplete="off">
                  <mat-option 
                  *ngFor="let documents of documentDropdownList" [value]="documents.documentID">
                  {{documents.documentSubTypeDetailDisplayText}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span *ngIf="docCodeErrMsg" class="error ng-star-inserted margin-top0">{{docCodeErrMsg}}</span> 
            </div>
          </div>
          <div class="col-md-4 pl top15">
            <div class="form-group">
              <mat-form-field>
                <mat-label>Document Name</mat-label>
                <input matInput type="text" class="form-control" 
                  formControlName="documentName" (keyup)="resetInputValue()">
                  <span *ngIf="docNameRequiredMsg" class="error ng-star-inserted margin-top0">
                  {{docNameRequiredMsg}}</span>
              </mat-form-field>
              <span *ngIf="docCodeErrMsg" class="error ng-star-inserted margin-top0">{{docCodeErrMsg}}</span> 
            </div>
          </div>
          <div class="col-md-4 pl top15">
            <div class="form-group">
              <label>Upload Document</label>
              <div class="input-group mb-3 upload-docment-grouop">
                <input #file type="file" class="form-control" accept='image/*,application/pdf,application/x-zip-compressed'
                  (change)="preview(file.files)" aria-label="Username" aria-describedby="basic-addon1" style="margin-left: -10px;border:0px solid;">
              </div>
              <span *ngIf="docFileRequiredMsg" class="error ng-star-inserted">{{docFileRequiredMsg}}</span>
              <span *ngIf="uploadErrMsg" class="error ng-star-inserted">{{uploadErrMsg}}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="thumb-output" class="text-center" *ngIf="isFileUpload">
        <img [src]="imgURL" class="thumb" *ngIf="!docTypePdf && !docTypeZip && imgURL !==null">
        <img *ngIf="docTypePdf" class="thumb" src="assets/images/pdf.png">
        <img *ngIf="docTypeZip" class="thumb" src="assets/images/zip.png">
      </div>  
      <br>
      <div class="text-center">
        <div class="doc-progress" *ngIf="progress">
          <div class="doc-progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
        </div>
        <button class="btn btn-rounded btn-primary mb-5" [disabled]="uploadBtn" type="submit">Upload Now</button>
      </div>
        </form>
      </div>
      <div align="center" class="return-btn">
        <button class="btn btn-rounded btn-primary mb-4 marginRight25 marginRight0" (click)="callBackUrl()">Back</button>
        </div>
  </div>
  </div>

  


  <ng-template #imgModal let-modal class="first_blk_line form-element my_popup_blk">
    <div class="modal-body">
      <div class="col-sm-12" align="center">
        <img id="myimage" [src]='imageData' style="max-height:400px" *ngIf="!isPdf">
        <iframe style="width:100%; border:1px solid; height:370px" [src]="isPdfData" *ngIf="isPdf"></iframe>
      </div>
    </div>
    <div class="modal-footer" style="padding:0px 20px 4px;">
      <div class="col-12 col-md-12 col-xl-12 add_button_blk text-center">
        <button class="btn btn-rounded btn-primary marginRight25 marginRight0"
          (click)="closeModal()">Close</button>
          <!-- <a [href]="imageData" download="download" target="_blank">Download</a> -->
      </div>
      </div>
  </ng-template>