import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd, RouterEvent, NavigationCancel, NavigationError } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { UtilityService } from "./services/utility.service";
import { Observable, Subscription } from "rxjs";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  loaderSubscription: Subscription;
  routerSubscription: Subscription;
  showLaodingIndiacator = false;
  loaderText = '';
  //constructor(public router: Router){}
  constructor(public router: Router, private changeDetectorRef: ChangeDetectorRef,
    private utilityService: UtilityService) {
    this.router.events.subscribe((RouterEvent: Event) => {
      if (RouterEvent instanceof NavigationStart) {
        this.utilityService.showLoader();
      }
      if (RouterEvent instanceof NavigationEnd || RouterEvent instanceof NavigationCancel || RouterEvent instanceof NavigationError) {
        this.utilityService.hideLoader();
      }
    });
  }
  ngOnInit() {
    this.loaderSubscription = this.utilityService.onLoader().subscribe(d => {
      this.showLaodingIndiacator = (d && d.show && d.show == true) ? true : false;
      this.loaderText = (d && d.loaderMessage && d.loaderMessage != '') ? d.loaderMessage : '';
      this.changeDetectorRef.detectChanges();      
    });
  }
  ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}


