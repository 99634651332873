import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class currentSelectedModule {
  private subject = new Subject<any>();

  // Data for document upload
  setModule(currentModule?: any) {
    this.subject.next(currentModule);
  }

  getModule(): Observable<any> {
    return this.subject.asObservable();
  }

  clearModule() {
    this.subject.next(null);
  }

}


