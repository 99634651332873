
<aside class="control-sidebar control-sidebar-light   custom-upload-side-bar">
  <div class="rpanel-title"><span class="btn pull-right"><i class="ion ion-close"
        data-toggle="control-sidebar"></i></span> </div>
  <!-- Create the tabs -->
  <!-- Tab panes -->
  <div>
    <!-- Home tab content -->
    <div id="control-sidebar-home-tab ">
      <h3 class="control-sidebar-heading" *ngIf="documentHeader">{{documentHeader}} Documents Upload</h3>
      <br>
      <form [formGroup]="DocumentUpload_form" (ngSubmit)="uploadDocument()" *ngIf="documentUploadData && documentUploadData.data.uploadType=='lead' && selectedApplication.isEditable==true && documentPermissionData.form.isEdit==true && documentPermissionData.button.isEdit==true">
        <div class="col-md-12 upload-imput-tyle">
          <div class="row">
            <div class="col-md-4 pl" *ngIf="documentHeader=='KYC'">
              <div class="form-group">
                <label>Document Type</label>
                <select class="form-control" formControlName="documentType" (change)="documentNameUpdate()" >
                  <option value="" disabled selected>Select Document</option>
                  <option *ngFor="let documents of documentDropdownList" [disabled]="documents.documentSubTypeDetail !== dropdownSelectedValue" [value]="documents.documentID">
                    {{documents.documentSubTypeDetailDisplayText}}</option>
                </select>
                <span *ngIf="docTypeRequiredMsg" class="error ng-star-inserted margin-top0">{{docTypeRequiredMsg}}</span>
              </div>
            </div>
            <div class="col-md-4 pl" *ngIf="documentHeader!=='KYC'">
              <div class="form-group">
                <label>Document Type</label>
                <select class="form-control" formControlName="documentType" (change)="docTypeValue()">
                  <option value="" disabled selected>Select Document</option>
                  <option *ngFor="let documents of documentDropdownList" [value]="documents.documentID">
                    {{documents.documentSubTypeDetailDisplayText}}</option>
                </select>
                <span *ngIf="docTypeRequiredMsg" class="error ng-star-inserted margin-top0">{{docTypeRequiredMsg}}</span>
              </div>
            </div>

            <div class="col-md-4 pl">
              <div class="form-group">
                <label>Document Name</label>
                <input type="text" class="form-control" formControlName="documentName" (keyup)="resetInputValue()">
                <span *ngIf="docNameRequiredMsg" class="error ng-star-inserted margin-top0">{{docNameRequiredMsg}}</span>
              </div>
            </div>
            <div class="col-md-4 pl">
              <div class="form-group">
                <label>Upload Document</label>
                <div class="input-group mb-3 upload-docment-grouop">
                  <!-- <label for="upload-docment" class="choose-files2" style="display: flex;">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-upload"
                        aria-hidden="true"></i></span>
                  </div>
                  Choose File</label> -->
                  <input #file type="file" class="form-control" [accept]='fileTypeTxt'
                    (change)="preview(file.files)" aria-label="Username" aria-describedby="basic-addon1" style="margin-left: -10px;border:0px solid;">
                </div>
                <span *ngIf="docFileRequiredMsg" class="error ng-star-inserted">{{docFileRequiredMsg}}</span>
                <span *ngIf="uploadErrMsg" class="error ng-star-inserted">{{uploadErrMsg}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="thumb-output" class="text-center" *ngIf="isFileUpload">
          <img [src]="imgURL" class="thumb" *ngIf="!docTypePdf && !docTypeZip && !docTypeExcel && imgURL !==null">
          <img *ngIf="docTypePdf" class="thumb" src="assets/images/pdf.png">
          <img *ngIf="docTypeZip" class="thumb" src="assets/images/zip.png">
          <img *ngIf="docTypeExcel" class="thumb" src="assets/images/excel.png">
        </div>
        <br>
        <div class="text-center">
          <div class="doc-progress" *ngIf="progress">
            <div class="doc-progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
          </div>
          <button class="btn btn-rounded btn-primary mb-5" type="submit" [disabled]="uploadBtn">Upload Now</button>
        </div>
      </form>

      <form [formGroup]="DocumentUpload_form" (ngSubmit)="uploadDocument()" *ngIf="documentUploadData && documentUploadData.data.uploadType!='lead' && documentPermissionData.form.isEdit==true && documentPermissionData.button.isEdit==true">
        <div class="col-md-12 upload-imput-tyle">
          <div class="row">
            <div class="col-md-4 pl" *ngIf="documentHeader=='KYC'">
              <div class="form-group">
                <label>Document Type</label>
                <select class="form-control" formControlName="documentType" (change)="documentNameUpdate()" >
                  <option value="" disabled selected>Select Document</option>
                  <option *ngFor="let documents of documentDropdownList" [disabled]="documents.documentSubTypeDetail !== dropdownSelectedValue" [value]="documents.documentID">
                    {{documents.documentSubTypeDetailDisplayText}}</option>
                </select>
                <span *ngIf="docTypeRequiredMsg" class="error ng-star-inserted margin-top0">{{docTypeRequiredMsg}}</span>
              </div>
            </div>
            <div class="col-md-4 pl" *ngIf="documentHeader!=='KYC'">
              <div class="form-group">
                <label>Document Type</label>
                <select class="form-control" formControlName="documentType" (change)="docTypeValue()">
                  <option value="" disabled selected>Select Document</option>
                  <option *ngFor="let documents of documentDropdownList" [value]="documents.documentID">
                    {{documents.documentSubTypeDetailDisplayText}}</option>
                </select>
                <span *ngIf="docTypeRequiredMsg" class="error ng-star-inserted margin-top0">{{docTypeRequiredMsg}}</span>
              </div>
            </div>

            <div class="col-md-4 pl">
              <div class="form-group">
                <label>Document Name</label>
                <input type="text" class="form-control" formControlName="documentName" (keyup)="resetInputValue()">
                <span *ngIf="docNameRequiredMsg" class="error ng-star-inserted margin-top0">{{docNameRequiredMsg}}</span>
              </div>
            </div>
            <div class="col-md-4 pl">
              <div class="form-group">
                <label>Upload Document</label>
                <div class="input-group mb-3 upload-docment-grouop">
                  <!-- <label for="upload-docment" class="choose-files2" style="display: flex;">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-upload"
                        aria-hidden="true"></i></span>
                  </div>
                  Choose File</label> -->
                  <input #file type="file" class="form-control" [accept]='fileTypeTxt'
                    (change)="preview(file.files)" aria-label="Username" aria-describedby="basic-addon1" style="margin-left: -10px;border:0px solid;">
                </div>
                <span *ngIf="docFileRequiredMsg" class="error ng-star-inserted">{{docFileRequiredMsg}}</span>
                <span *ngIf="uploadErrMsg" class="error ng-star-inserted">{{uploadErrMsg}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="thumb-output" class="text-center" *ngIf="isFileUpload">
          <img [src]="imgURL" class="thumb" *ngIf="!docTypePdf && !docTypeZip && !docTypeExcel && imgURL !==null">
          <img *ngIf="docTypePdf" class="thumb" src="assets/images/pdf.png">
          <img *ngIf="docTypeZip" class="thumb" src="assets/images/zip.png">
          <img *ngIf="docTypeExcel" class="thumb" src="assets/images/excel.png">
        </div>
        <br>
        <div class="text-center">
          <div class="doc-progress" *ngIf="progress">
            <div class="doc-progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
          </div>
          <button class="btn btn-rounded btn-primary mb-5" type="submit" [disabled]="uploadBtn">Upload Now</button>
        </div>
      </form>

      <div class="table-responsive table-listing-new" *ngIf="documentPermissionData.form.isView==true && documentPermissionData.button.isView==true">
        <table id="invoice-list" class="table table-hover no-wrap" data-page-size="10">
          <thead>
            <tr>
              <th style="width: 20px;" *ngIf="showCheckBox"></th>
              <th width="150px">Document Type</th>
              <th> Document Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let document of DocumentByLeadAndTypeObj;let i=index;">
              <td *ngIf="showCheckBox">
                <div class="form-group">
                  <mat-checkbox (change)="checkedForSelectDocument(document,$event,i)" [checked]="checked"></mat-checkbox>
                </div>
              </td>
              <td style="text-align: center;">{{document.documentSubTypeDetailDisplayText}} </td>
              <td style="text-align: center;">{{document.documentName}}</td>
              <td style="text-align: center;">
                <a class="view-btn" (click)="downloadDocument(document)" download><i class="fa fa-download" aria-hidden="true"></i></a>
                <a class="delete-btn" (click)="deleteDocument(document)" *ngIf="selectedApplication.isEditable==true && documentUploadData.data.uploadType=='lead' && documentPermissionData.form.isDelete==true && documentPermissionData.form.isEdit==true && documentPermissionData.button.isEdit==true">
                  <i class="fa fa-trash" aria-hidden="true"></i></a>
                <a class="delete-btn" (click)="deleteDocument(document)" *ngIf="documentUploadData.data.uploadType!='lead' && documentPermissionData.form.isDelete==true && documentPermissionData.form.isEdit==true && documentPermissionData.button.isEdit==true">
                  <i class="fa fa-trash" aria-hidden="true"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="save-doucment-btn">
        <button class="btn btn-rounded btn-primary mb-5" type="button" data-toggle="control-sidebar" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</aside>
