// import section
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { APIConstant } from "src/app/constants/apiConstants";
import { ValidationMessage } from "src/app/constants/validationMessage";
import { NavigationConstants } from "src/app/constants/navigationConstant";
import { CustomStorage } from "src/app/storage/storageKeys";
import { SessionStorageService } from "src/app/storage/session-storage.service";
import { UtilityService } from "src/app/services/utility.service";
import { LocalStorageService } from "src/app/storage/local-storage.service";
import { CommonService } from "src/app/services/common.service";
import Swal from 'sweetalert2'
import { environment } from "src/environments/environment.prod";
declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  encapsulation: ViewEncapsulation.None
})
// class
export class LoginComponent implements OnInit {
  // component variables
  validationMessage = ValidationMessage;

  pageMessage: any;
  isFormSubmitted = false;
  loginForm: FormGroup;
  authorizationSequence: any = {};
  userBranches: any = [];
  firstTimeLogin: boolean;
  companyListObj: any = [];
  myRecaptcha = new FormControl(false);
  reloadedByUserFlag = false;
  autoReloadedFlag = false;

  constructor(
    public formBuilder: FormBuilder,
    private apiservice: ApiService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private utilityService: UtilityService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    const token = this.sessionStorageService.getData("token");
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
      company: ["0", Validators.required]
    });
    if (token) {
      this.utilityService.redirectToDashboard(false);
    }
    this.getCompanyList();
  }
  get loginFormControl() {
    return this.loginForm.controls;
  }

  get login_form_control() {
    return this.loginForm.controls;
  }
  getCompanyList() {
    // this.apiservice.get(APIConstant.COMPANYLIST, {}, resObj => {
    //   this.companyListObj = resObj;
    // });
    this.companyListObj = [{
      companyId: 1,
      companyName: "DMI Housing Finance",
      companySlug: "DMI_HFC"
    }];
  }

  onLoginFormSubmit() {
    this.isFormSubmitted = true;
    if (this.loginForm.invalid) {
      this.utilityService.hideLoader();
      return;
    }
    const loginData = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
      company: {
        companyId: this.companyListObj[this.loginForm.value.company].companyId,
        companySlug: this.companyListObj[this.loginForm.value.company]
          .companySlug
      }
    };
    this.utilityService.showLoader();
    this.apiservice.post(
      APIConstant.LOGIN_API,
      loginData,
      resObj => {
        if (resObj.responseObj && resObj.responseObj.userDetails && resObj.responseObj.userDetails.userBasicDetails.userType && resObj.responseObj.userDetails.userBasicDetails.userType!='customerTypeUser' && resObj.responseObj.userDetails.userBasicDetails.userType!='DsaUser') {
          let token = resObj.responseObj.token;
          let role_id = resObj.responseObj.userDetails.roleList[0].roleId;
          let authorizationSequence =
            resObj.responseObj.userDetails.userBasicDetails;
          let userBranches =  resObj.responseObj.userDetails.userBranches; 
          if (token && role_id) {
            this.pageMessage = "";
            this.sessionStorageService.setData(
              CustomStorage.localKeys.token,
              JSON.stringify(resObj.responseObj.token)
            );
            this.localStorageService.setData(
              CustomStorage.localKeys.token,
              JSON.stringify(token)
            );
            this.sessionStorageService.setData(
              CustomStorage.sessionKeys.authorizationSequence,
              JSON.stringify(authorizationSequence)
            );
            this.sessionStorageService.setData(
              CustomStorage.sessionKeys.userBranches,
              JSON.stringify(userBranches)
            );
            this.sessionStorageService.setData(
              CustomStorage.sessionKeys.roleId,
              role_id
            );
            this.sessionStorageService.setData(
              CustomStorage.sessionKeys.PriveldgeList,
              JSON.stringify(resObj.responseObj.userDetails.rolePrivilegesList)
            );
            
            this.utilityService.setLoggedIn();
            if(resObj.responseObj.userDetails.userBasicDetails.passwordChangeRequired){
              this.utilityService.hideLoader();
              this.utilityService.redirectToChangePassword();
            } else {
              if (resObj.responseObj.mfaEnable == true) {
                let userDetails = {
                  "loginPayload" : loginData, 
                  "echID" : resObj.responseObj.echId
                }                
                this.sessionStorageService.setData(
                  CustomStorage.sessionKeys.userLoginDetails,
                  JSON.stringify(userDetails)
                );
                this.router.navigate([NavigationConstants.LOGIN_OTP]);
              } else {
                this.commonService.getDocumentCode().subscribe({
                  next:(resObj1) => {
                    const masterDocumentCode = JSON.stringify(resObj1.responseObj.documentTypes);
                    this.sessionStorageService.setData(CustomStorage.sessionKeys.documentCodes,masterDocumentCode);

                    this.commonService.getMasterData().subscribe({
                      next:(resObj) => {
                        if(resObj.responseObj){
                          let masterJson = JSON.stringify(resObj.responseObj);
                          this.localStorageService.setData(CustomStorage.localKeys.masters,masterJson);
                          this.utilityService.hideLoader();
                          this.utilityService.redirectToDashboard();
                        } else {
                          this.utilityService.hideLoader();
                          this.utilityService.clearSession();
                          Swal.fire({
                            allowOutsideClick: false,
                            text: 'just text',
                            html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.applicationError + '</p>',
                          })
                        }
                      },
                      error:(err)=> {
                        this.utilityService.hideLoader();
                        this.utilityService.clearSession();
                        Swal.fire({
                          allowOutsideClick: false,
                          text: 'just text',
                          html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.applicationError + '</p>',
                        })
                      }
                    });
                  },
                  error:(err)=> {
                    this.utilityService.hideLoader();
                    this.utilityService.clearSession();
                    Swal.fire({
                      allowOutsideClick: false,
                      text: 'just text',
                      html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.common.applicationError + '</p>',
                    })
                  }
                });

                // this.utilityService.loadDocumentCode();
                
              }
            }
          } else {
            this.pageMessage = this.validationMessage.common.unexpectedError;
            this.utilityService.clearSession();
          }
        } else {
          this.utilityService.hideLoader();
          Swal.fire({
            allowOutsideClick: false,
            text: 'just text',
            html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + this.validationMessage.login.invalidLogin + '</p>',
          });
          if(resObj.responseObj && resObj.responseObj.token){
            this.sessionStorageService.setData(
              CustomStorage.localKeys.token,
              JSON.stringify(resObj.responseObj.token)
            );
            this.apiservice.post(APIConstant.LOGOUT_USER,{},resObj=>{
              this.utilityService.clearSession();
            });
          }
        }
        // this.utilityService.hideLoader();
      },
      error => {
        this.utilityService.hideLoader();
        this.pageMessage = error.responseMsg;
        this.utilityService.clearSession();
      }
    );
  }
}
