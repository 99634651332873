import { Component, OnInit, ViewChildren, ViewChild } from "@angular/core";
import { UtilityService } from "src/app/services/utility.service";
import { DocumentUploadService } from 'src/app/services/document-upload.service';
import Swal from "sweetalert2";
declare var $: any;

@Component({
  selector: "app-basecomponent",
  templateUrl: "./basecomponent.component.html",
  styleUrls: ["./basecomponent.component.css"]
})
export class BaseComponent implements OnInit {
  public uploadData;
  private _masters: any;
  private _utility: UtilityService;
  public DocumentCodes = {
    APPLICATION_FORM_UPLOAD: 'ApplicationFormUpload',
    DOB: 'DOB',
    ADDRESS_PROOF: 'AddressProof',
    BANK_PROOF: 'BankProof',
    EMPLOYMENT_PROOF: 'EmploymentProof',
    INCOME_PROOF: 'IncomeProof',
    BANK_STATEMENT: 'BankStatement',
    WAVED_PROOF: 'WavedProof',
    LOGIN_FEE_TRANSFER_PROOF: 'LoginFeeTransferProof',
    CPV_FIRING_DOC: 'CpvFiringDoc',
    CPV_REPORT: 'CpvReport',
    ROI_APPROVAL_UPLOAD: 'RoiApprovalUpload',
    INSR_UPLOAD: 'InsrUpload',
    FOIR_UPLOAD: 'FoirUpload',
    LTV_UPLOAD: 'LtvUpload',
    TENURE_UPLOAD: 'TenureUpload',
    LCR_UPLOAD: 'LcrUpload',
    LCR_UPLOAD1:'LCRDocument',
    TECHNICAL_FIRING_DOC: 'TechnicalFiringDoc',
    TECHNICAL_REPORT: 'TechnicalReport',
    KYC_DOCUMENT: 'KycDocument',
    PD_REPORT_DOCUMENT: 'PdReportDocument',
    OCR_DOCUMENT: 'OcrApprovalDocument',
    BUREAU_REPORT: 'BureauReport',
    DEVIATION_UPLOAD: 'DeviationDocument',
    TECHNICAL_DOC_UPLOAD: 'TechnicalDocument',
    LOGIN_FEE_DEPOSITE_SLIP: 'DepositeSlip',
    GEN_INS_DOC:'GenInsDoc',
    HEALTH_INS_DOC:'HealthInsDoc',
    LIFE_INS_DOC:'LifeInsDoc',
    HUNTER_DOC:'HunterDocument',
    OBLIGATION_DOC: 'ObligationDocument',
    CREDIT_CARD_DOC: 'CreditCardDocument',
    ASSET_DOC: 'AssetDocument',
    ACHSIGNED: 'AchDocument',
    LEGAL_FIRING_DOC: 'LegalFiringDoc',
    LEGAL_REPORT: 'LegalReport',
    SIGNED_LEGAL_REPORT: 'SignedLegalReport',
    Legal_Search_Receipt: 'LegalSearchReceipt',
    LEGAL_APPROVAL_DOC: 'LegalApprovalDocument',
    LOAN_CANCELLATION_DOC: 'LoanCancellationDocument',
    OCR_PROOF:'OCRDocument',
    REFUND_APPROVAL:'refundApprovalDoc',
    STAMP_UPLOAD:'StampDocument',
    APPROVAL_DOC_UPLOAD:'ApprovalDocument',
    SUBSEQUENT_DOC:'SubsequentDetailDocument',
    FORECLOSURE_DOC:'Foreclosure',
    BPF_DOC:'BalanceFeeDeductionApprovalDoc',
    // REQUEST_FORM_DOC:'TranchRequestDocument'
    PV_DOC:'PropertyValuationDocument',
    OFFICE_ADDRESS:'OfficeAddressProof',
    PROFILE_IMAGE:'ProfileImage',
    SERVICE_REQUEST_DOCUMENT: 'ServiceRequestDocument',
    FIRING_DOCUMENT: 'FiringPrerequisitesDocument',
    REPORT_DOCUMENT: 'ReportPrerequisitesDocument',
    FINANCIAL_DOCUMENT: 'ProofOfFinancialExperiance',
    BUSINESS_DOCUMENT: 'ProofOfBusiness',
    FINAL_REPORT: 'FinalReportDocument',
    DOWNSIZE_DOCUMENT: 'DownsizeDocument',
    CONSENT_UPLOAD: 'ConsentDocument',
    LEGAL_EXCEL_UPLOAD: 'LegalExcelDocument',
    CRITERIA_UPLOAD: 'CriteriaDocument',
 //   CRITERIA_UPLOAD: 'CriteriaDocument',
    APPROVAL_DOC: 'DsaApprovalDocument',
    SERVICE_REPLY_DOCUMENT: 'ServiceResponseDocument',
    NOC_UPLOAD: 'NOCLetterRequest',
    NOC_RESPONSE: 'NOCLetterResponse',
    TECHNICAL_PROPERTY_IMAGES: 'TechnicalPropertyImages',
    AGENCY_DOC : 'AgencyDocument',
    CP_AGREEMENT_SIGNED: 'ChannelPartnerAgreementManualSign',
    BUILDER_SHEET: 'BuilderSheet',
    FCU_FI_DOC: 'BuilderApfFcuReport',
    LEGAL_FI_DOC: 'BuilderApfLegalReport',
    TECHNICAL_FI_DOC: 'BuilderApfTechnicalReport',
    CHEQUE_HANDOVER_CONSENT : 'ChequeHandoverConfirmation'
  };

  public  FileUploadFormats = {
    PDF_ONLY: 'application/pdf',
    IMAGES_ONLY: 'image/*',
    PDF_AND_IMAGES: 'application/pdf,image/*',
    PDF_IMAGES_ZIP: 'application/pdf,image/*,application/x-zip-compressed'
  }
  constructor(utility: UtilityService) {
    this._utility = utility;
    this._masters = utility.getMasters();
    // if (
    //   !this._masters ||
    //   this._masters === null ||
    //   this._masters === undefined
    // ) {
    //   utility.logout();
    // }

  }

  ngOnInit() {}

  /**
   * Master List
   * Provides list of master data object of given master data set.
   * Use in places where list/options are to be shown from master data.
   * @param masterName Name of the master data set.
   */
  masterList(masterName) {
    if (this._masters) {
      return this._masters[masterName];
    }
    return [];
  }

  masterListOrder(masterName,sequenceOrder,orders) {
    let returnData=[];
    if (this._masters) {
      let ms=this._masters[masterName];
      ms.forEach((element,i) => {
        ms[i].sequence=parseInt(element.sequence);
      });
      if(ms && ms!=undefined && ms!=null){
        if(orders=='ASC'){
          returnData=ms.sort((a, b)=> {
            return a[sequenceOrder] - b[sequenceOrder];
          });
        } else {
          returnData=ms.sort((a, b)=> {
            return b[sequenceOrder] - a[sequenceOrder];
          });
        }

      } else {
        console.log('wrong master name');
        returnData=[];
      }
      return returnData;
    }
    return [];
  }


  /**
   * Master Value
   * Retrieves value of given master data type detail ID from given master data set.
   * @param masterName Name of the master data set.
   * @param typeDetailId Type Detail ID of the master Value.
   */
  masterValue(masterName, typeDetailId) {
    if (this._masters) {
      const master = this._masters[masterName];
      if (master) {
        const m = master.filter(item => {
          return item.typeDetailID == typeDetailId;
        })[0];
        if (m) {
          return m.typeDetailDisplayText;
        }
      }
    }
    return '';
  }
  masterCode(masterName, typeDetailId) {
    if (this._masters) {
      const master = this._masters[masterName];
      if (master) {
        const m = master.filter(item => {
          return item.typeDetailID === typeDetailId;
        })[0];
        if (m) {
          return m.typeDetailCode;
        }
      }
    }
    return '';
  }
  masterValueWithoutDefault(masterName, typeDetailId) {
    if (this._masters) {
      const master = this._masters[masterName];
      if (master) {
        const m = master.filter(item => {
          return item.typeDetailID === typeDetailId;
        })[0];
        if (m) {
          return m.typeDetailDisplayText;
        }
      }
    }
    return '';
  }

  masterTypeDetailId(masterName, typeDetailCode) {
    if (this._masters) {
      const master = this._masters[masterName];
      if (master) {
        const m = master.filter(item => {
          return item.typeDetailCode === typeDetailCode;
        })[0];
        if (m) {
          return m.typeDetailID;
        }
      }
    }
    return 0;
  }
  masterDisplayTextTypeDetailId(masterName, displayText) {
    if (this._masters) {
      const master = this._masters[masterName];
      if (master && displayText!=null && displayText!='' && displayText!=undefined) {
        const m = master.filter(item => {
          return item.typeDetailDisplayText.toLowerCase() == displayText.toLowerCase();
        })[0];
        if (m) {
          return m.typeDetailID;
        }
      }
    }
    return 0;
  }

  masterDisplayTextMatch(masterName, displayText) {
    if (this._masters) {
      const master = this._masters[masterName];
      if (master && displayText!=null && displayText!='' && displayText!=undefined) {
        const m = master.filter(item => {
          return item.typeDetailDisplayText.toLowerCase() == displayText.toLowerCase();
        })[0];
        if (m) {
          return m.typeDetailDisplayText;
        }
      }
    }
    return 0;
  }

  masterTypeReference(masterName, refTypeDetailID) {
    if (this._masters) {
      const master = this._masters[masterName];
      if (master) {
        const m = master.filter(item => {
          return item.refTypeDetailID === refTypeDetailID;
        });
        if (m) {
          return m;
        }
      }
    }
    return 0;
  }

  // Document Uploader
  showDocumentUploader(uploaderCode,hideCheckBox,leadID,leadApplicantNumber,rowIdentifier,documentUploadTitle,dropdownSelectedValue?,callback?:any,button?:any){
    let selectedApplicationData= {
      documentCode:this.masterTypeDetailId('DocumentCode',uploaderCode),
      hideCheckBox:hideCheckBox,
      leadID:leadID,
      leadApplicantNumber:leadApplicantNumber,
      rowIdentifier:rowIdentifier,
      documentHeading:documentUploadTitle,
      dropdownSelectedValue:dropdownSelectedValue,
      button:button,
      uploadType:'lead'
    }
    this._utility.setDocumentUploadValue(selectedApplicationData,this,callback);
    $('.control-sidebar').toggleClass('control-sidebar-open');
  }

  showDocumentUploaderOther(uploaderCode,hideCheckBox,leadID,leadApplicantNumber,rowIdentifier,documentUploadTitle,dropdownSelectedValue?,callback?:any,button?:any){
    let selectedApplicationData= {
      documentCode:this.masterTypeDetailId('DocumentCode',uploaderCode),
      hideCheckBox:hideCheckBox,
      uploadCode:leadID,
      leadApplicantNumber:leadApplicantNumber,
      rowIdentifier:rowIdentifier,
      documentHeading:documentUploadTitle,
      dropdownSelectedValue:dropdownSelectedValue,
      button:button,
      uploadType:'other'
    }
    this._utility.setDocumentUploadValue(selectedApplicationData,this,callback);
    $('.control-sidebar').toggleClass('control-sidebar-open');
  }

  //Document Uploader with DocumentIDs paramters ( ROI Waiver)
  showDocumentUploaderDocIds(uploaderCode,hideCheckBox,leadID,leadApplicantNumber,rowIdentifier,documentUploadTitle,dropdownSelectedValue?,callback?:any,button?:any,documentIds?:any){
    let selectedApplicationData= {
      documentCode:this.masterTypeDetailId('DocumentCode',uploaderCode),
      hideCheckBox:hideCheckBox,
      leadID:leadID,
      leadApplicantNumber:leadApplicantNumber,
      rowIdentifier:rowIdentifier,
      documentHeading:documentUploadTitle,
      dropdownSelectedValue:dropdownSelectedValue,
      documentIds:documentIds,
      button:button
    }
    // console.log("data1",selectedApplicationData);
    this._utility.setDocumentUploadValue(selectedApplicationData,this,callback);
    $('.control-sidebar').toggleClass('control-sidebar-open');
  }

  showDocumentUploaderForBank(uploaderCode,hideCheckBox,leadID,leadApplicantNumber,rowIdentifier,documentUploadTitle,dropdownSelectedValue?,bankNameTypeDetailID?,callback?:any,button?:any){
    let selectedApplicationData= {
      documentCode:this.masterTypeDetailId('DocumentCode',uploaderCode),
      hideCheckBox:hideCheckBox,
      leadID:leadID,
      leadApplicantNumber:leadApplicantNumber,
      rowIdentifier:rowIdentifier,
      documentHeading:documentUploadTitle,
      dropdownSelectedValue:dropdownSelectedValue,
      bankNameTypeDetailID:bankNameTypeDetailID,
      button:button,
      uploadType:'lead'
    }
    this._utility.isSidebarOpen = true;
    this._utility.setDocumentUploadValue(selectedApplicationData,this,callback);
    $('.control-sidebar').toggleClass('control-sidebar-open');
  }
  showDocumentUploaderSalarySlip(uploaderCode,hideCheckBox,leadID,leadApplicantNumber,rowIdentifier,documentUploadTitle,dropdownSelectedValue?,callback?:any,button?:any){
    let selectedApplicationData= {
      documentCode:this.masterTypeDetailId('DocumentCode',uploaderCode),
      hideCheckBox:hideCheckBox,
      leadID:leadID,
      leadApplicantNumber:leadApplicantNumber,
      rowIdentifier:rowIdentifier,
      documentHeading:documentUploadTitle,
      dropdownSelectedValue:dropdownSelectedValue,
      button:button,
      uploadType:'salarySlip'
    }
    if(this._utility.salarySlipStatus == null || this._utility.salarySlipStatus == undefined){
      this._utility.setDocumentUploadValue(selectedApplicationData,this,callback);
      $('.control-sidebar').toggleClass('control-sidebar-open');
    }
    else if(this._utility.salarySlipStatus && (this._utility.salarySlipStatus == 'COMPLETED' || this._utility.salarySlipStatus == 'REJECTED')){
      this._utility.setDocumentUploadValue(selectedApplicationData,this,callback);
      $('.control-sidebar').toggleClass('control-sidebar-open');
    }else{
      Swal.fire({
        allowOutsideClick: false,
        text:'just text',
        html:'<img src="assets/images/warning.png" class="alertModalImg"><br><p class="warningMsgAlert">'+'Uploaded document is still in review.'+'</p>',
      })
    }
  }
}
