<div class="hold-transition bg-img login-page-bg" style="background-image: url(assets/images/banner1.jpg)" data-overlay="1">
  <div class="container h-p100 for_mobile_view">
    <div class="row align-items-center justify-content-md-center h-p100">			
      <div class="col-12">
        <div class="row no-gutters justify-content-md-center">
          
          <div class="col-lg-12 col-md-12 col-12">
            <div class="content-top-agile content-top-agile-white h-p100">
              <img src="assets/images/short-logo.png"  >
            </div>
            
          </div>
          <div class="col-lg-12 col-md-12 col-12">
              <h4 class="page-heada">Forgot Password</h4>			
            <div class="p-40 bg-white content-bottom h-p100 login-bg">
              <!--<h3 class="text-center mb-4 mt-0 heading_border">Account Login</h3>-->
              <form  class="form-element" [formGroup]="forgot_password_form" (ngSubmit)="onSubmitForgotPwd()">
                 <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text bg-info border-info"><i class="ti-user"></i></span>
                    </div>
                    <input type="text" class="form-control pl-15" placeholder="Username"  id="forgot_user" [ngClass]="{ 'is-invalid': submittedForgotPassword && forgotPassword_control.forgot_user.errors }" formControlName="forgot_user" required>
                    
                  </div>
                  <span *ngIf="submittedForgotPassword && forgotPassword_control.forgot_user.errors" class="error pdl-40">
                    <span *ngIf="forgotPassword_control.forgot_user.errors.required">{{ requiredErrorMessage.requireError }}</span>
                  </span>
                </div>
                 
                <div class="form-group">
									<div class="input-group mb-3">
										<div class="input-group-prepend">
											<span class="input-group-text bg-info border-info"><i class="fa fa-building-o"></i></span>
										</div>
										<div class="form-control pl-15"  style="top: 1px;" 
											*ngFor="let company of companyList" >
											<div class="mt-2">
												{{company.companyName}}
											</div>
										</div>
									</div>
								</div>
                <div class="clear"></div>
                  <div class="row">
                  <div class="col-12 text-center">
                      <button class="btn btn-lg btn-primary btn-block margin-top-10 sign-btn">Get OTP</button>
                  </div>
                  <div class="col-12">
                      <div class="login-pwd text-center">
                       <a routerLinkActive="active" routerLink="/login" class="underline_txt"> Login Here</a><br>
                       </div>
                     </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  
  