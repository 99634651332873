// core imports
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { APIConstant } from "src/app/constants/apiConstants";
import { UtilityService } from 'src/app/services/utility.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationMessage } from "src/app/constants/validationMessage";
import { HttpClient, HttpEventType,HttpErrorResponse } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { map, catchError } from "rxjs/operators";
import Swal from 'sweetalert2';
import { SessionStorageService } from 'src/app/storage/session-storage.service';
import { CustomStorage } from "src/app/storage/storageKeys";
declare var $: any;

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {
  formPermissionData:any;
  documentList: any = [];
  leadID: any;
  imageData:SafeResourceUrl;
  documentResObj: any;
  docUploadedPath: any;
  documentUploadForm: FormGroup
  isPdf:any;
  isPdfData:SafeResourceUrl
  validationMessage = ValidationMessage;
  file: any;
  applicantID: any;
  leadApplicantNumber: any;
  DocumentByLeadAndTypeObj: any = [];
  selectedApplication: any;
  documentDropdownList: any;
  applicantDisabled:boolean = true;
  documentUploadData: any;
  applicationLeadId: any;
  applicationTypeDetailId: any;
  docUploadResUrl: any = {};
  finalDocResUrl: any = [];
  docResId: any;
  docTypePdf: boolean = false;
  docTypeZip: boolean = false;
  docNameRequiredMsg: any;
  docTypeRequiredMsg: any;
  docFileRequiredMsg: any;
  documentHeader: any;
  dropdownSelectedValue: any;
  uploadErrMsg: string;
  checked = false;
  imagePath:any;
  imgURL:any;
  _masters:any;
  selectedDocCode:any;
  selectedDoctypeDetailcode:any;
  progress: number;
  uploadBtn:boolean = false;
  progressStart:boolean = false;
  isFileUpload:boolean = false;
  private url = environment.apiUrl;
  applicantList:any = [];
  docCodeErrMsg:any;
  activeTabKey=0;
  documentTabDataList:any=[];
  documentTypeList:any = [];
  documentCodeList:any = [];
  maxUploadResponse:any = [];
  maxFileUpLoadSize:any;

  @ViewChild('file', { static: true }) fileInput:ElementRef;
  constructor(
    private apiservice: ApiService, private router: Router,
    private utilityService: UtilityService, private sanitizer: DomSanitizer,
    private modalService: NgbModal,public _fb: FormBuilder, private http: HttpClient,private sessionStorageService:SessionStorageService
  ) {
    this.utilityService = utilityService;
    let currentUrl = window.location.pathname;
    this.leadID = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
    this._masters = utilityService.getMasters();
  }
  ngOnInit() {
    this.documentUploadForm = this._fb.group({
      documentCode: [''],
      documentApplicant: [''],
      documentType: [''],
      documentName: [''],
      document: ['']
    })
    this.documentUploadForm.controls['documentApplicant'].disable();
    this.documentUploadForm.controls['documentType'].disable();
    this.formPermissionData=this.utilityService.getPermission('Login','AllDocuments');
    this.getDocumentType();
    this.getApplicant();
    this.getDocumentList();
  }
  preview(fileInputEvent) {
    this.isFileUpload = true;
    this.file = fileInputEvent[0];
    if (fileInputEvent.length === 0) return;
    let mimeType = fileInputEvent[0].type;
    if (mimeType == 'application/pdf') {
      this.docTypePdf = true;
      this.docTypeZip = false
    } else if(mimeType == 'application/x-zip-compressed'){
      this.docTypeZip = true
      this.docTypePdf = false;
    }else {
      this.docTypeZip = false
      this.docTypePdf = false;
    }
    if (mimeType == 'application/pdf' || mimeType == 'image/jpeg' || mimeType == 'image/png' || mimeType == 'image/jpg' || mimeType == 'application/x-zip-compressed') {
      this.uploadErrMsg = '';
    } else {
      this.uploadErrMsg = this.validationMessage.documentUpload.supportedDocumentErrMsg;
    }
    var reader = new FileReader();
    this.imagePath = fileInputEvent;
    reader.readAsDataURL(fileInputEvent[0]);
    reader.onload = _event => {
      this.imgURL = reader.result;
      if (this.imgURL !== '') {
        this.docFileRequiredMsg = '';
      }
    };
    this.getMaxUpLoadSize();
  }
  masterTypeDetailId(masterName, typeDetailCode) {
    if (this._masters) {
      const master = this._masters[masterName];
      if (master) {
        const m = master.filter(item => {
          return item.typeDetailCode === typeDetailCode;
        })[0];
        if (m) {
          return m.typeDetailID;
        }
      }
    }
    return 0;
  }
  changeDocCode(docCode){
    this.selectedDocCode = this.masterTypeDetailId('DocumentCode',docCode),
    this.selectedDoctypeDetailcode=docCode;
    this.documentUploadForm.controls['documentApplicant'].enable();
    this.documentUploadForm.controls['documentType'].enable();
    this.docCodeErrMsg = ''
    this.docCodeErrMsg = ''
    this.getDocumentByLeadAndType();
  }
  changeApplicant(leadApplicantNumber){
    this.leadApplicantNumber = leadApplicantNumber;
    this.getDocumentByLeadAndType();
  }
  documentNameUpdate(){
    let docCount=0;
    let docName='';
    let docID=this.documentUploadForm.get('documentType').value;
    if(docID!='' && docID!=null && docID!=undefined && docID>0){
      let docTypes=this.documentDropdownList.filter(item=>{
        return docID==item.documentID;
      });
      if(docTypes.length){
        // docName+=' '+docTypes[0].documentCodeTypeDetailDisplayText;
        docName+=' '+docTypes[0].documentSubTypeDetailDisplayText;
        if(this.leadID!=null && this.leadID!='' && this.leadID!=undefined){
          docName+=' '+this.leadID;
        } else {
          docName+=' '+this.leadID;
        }
        if(this.documentList.hasOwnProperty(docTypes[0].documentCodeTypeDetail)){
          let savedDocCount=this.documentList[docTypes[0].documentCodeTypeDetail].filter(item=>{
            return item.documentSubTypeDetail.toUpperCase()==docTypes[0].documentSubTypeDetail.toUpperCase();
          });
          if(savedDocCount.length){
            docCount=savedDocCount.length;
          }
        }
        if(docCount>0){
          docName+=' '+docCount;
        }
      }
    }
    this.documentUploadForm.get('documentName').setValue(docName);
    this.resetInputValue();
  }
  docTypeValue(){
    this.docTypeRequiredMsg = '';
    this.documentNameUpdate();
  }
  resetInputValue(){
    this.docNameRequiredMsg = ''
  }
  uploadDocument() {
    if (this.selectedDocCode == '' || this.selectedDocCode === undefined || this.selectedDocCode === null) {
      this.docCodeErrMsg = this.validationMessage.documentUpload.DOC_CODE_ERR;
    }
    let documentName = this.documentUploadForm.controls.documentName.value;
    let documentID = this.documentUploadForm.controls.documentType.value;
    if (documentName == '' || documentName === undefined) {
      this.docNameRequiredMsg = this.validationMessage.documentUpload.documentNameErr;
    }else{
      this.docNameRequiredMsg = ''
    }
    if (documentID == '' || documentID === undefined) {
      this.docTypeRequiredMsg = this.validationMessage.documentUpload.documentTypeErr;
    }else{
      this.docTypeRequiredMsg = ''
    }
    let rowIdentifier = '';
    if (this.file) {
      this.documentUploadForm.get('document').setValue(this.file);
    }
    const formData = new FormData();
    let documentFile = this.documentUploadForm.get('document').value;
    if (documentFile == '' || this.imgURL == '') {
      this.docFileRequiredMsg = this.validationMessage.documentUpload.documentUploadErr;
      return;
    }
    this.progressStart = true;
    this.uploadBtn = true;
    this.progress = 1;
    formData.append('leadID', this.leadID);
    formData.append('documentName', documentName);
    formData.append('documentID', documentID);
    formData.append('leadApplicantNumber', this.leadApplicantNumber);
    formData.append('document', documentFile);
    formData.append('rowIdentifier', rowIdentifier);

    if( this.selectedDoctypeDetailcode=='CpvFiringDoc' && this.file.size/(1024*1024) > this.maxFileUpLoadSize){
      Swal.fire({
        allowOutsideClick: false,
        text:'just text',
        html:'<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">File size is not allowed more than '+this.maxFileUpLoadSize+'MB</p>',
      })
      this.fileInput.nativeElement.value='';
      this.resetDocForm();
      this.isFileUpload=false;
    return;
  }
    this.http.post(`${this.url}`+'v1/document/upload/', formData,{
      reportProgress: true,
      observe: "events"
    })
    .pipe(
      map((event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.progress = Math.round((100 / event.total) * event.loaded);
        } else if (event.type == HttpEventType.Response) {
          Swal.fire({
            allowOutsideClick: false,
            text:'just text',
            html:'<img src="assets/images/success.png" class="alertModalImg"><br><p class="successMsgAlert">'+ValidationMessage.documentUpload.DOC_UPLOADED+'</p>',
          });
          this.refreshDocumentList();
          this.resetDocForm();
        }if(event.status == 400){
          Swal.fire({
            allowOutsideClick: false,
            text:'just text',
            html:'<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">'+'Invalid File'+'</p>',
          });
          this.resetDocForm();
        }
      })
    ).toPromise();
  }
  getApplicant(){
    this.apiservice.get(APIConstant.APPLICANTCOUNT + this.leadID, {}, resObj => {
      this.applicantList = resObj;
    });
  }
  getMaxUpLoadSize(){
    this.apiservice.get(APIConstant.MAXUPLOADSIZE, {}, resObj=>{
      this.maxUploadResponse = resObj;
      this.maxFileUpLoadSize = this.maxUploadResponse[0].configValue
    })
  }

  docTabClick(index){
    this.activeTabKey=index;
    this.documentTabDataList=this.documentList[this.documentCodeList[index].docValue];
  }
  getDocumentByLeadAndType() {
    const paramsData = {
      codeID: this.selectedDocCode,
      lead: this.leadID,
      applicantNumber: '',
      rowIdentifier: ''
    }
    this.apiservice.get(APIConstant.DOCUMENT_AND_TYPE_LIST, paramsData, resObj => {
      this.documentDropdownList = resObj.documentTypes;
    });
  }
  getDocumentList() {
    this.utilityService.showLoader();
    let leadValue = {
      "lead": this.leadID
    }
    this.apiservice.get(APIConstant.DOCUMENT_AND_TYPE_LIST, leadValue, resObj => {
      if(resObj.documents){
        this.documentList = resObj.documents;
        for (let key in this.documentList) {
          let docCodeName=key;
          if(this.documentList[key].length){
            docCodeName=this.documentList[key][0].documentCodeTypeDetail;
          }
          if(this.formPermissionData.DocumentType.componentPrivileges[key] && this.formPermissionData.DocumentType.componentPrivileges[key].isView){
            this.documentCodeList.push({docValue:key, docCodeName:docCodeName});
          }
        }
        if(this.documentCodeList.length){
          this.documentTabDataList=this.documentList[this.documentCodeList[0].docValue];
        }
      }
      this.utilityService.hideLoader();
    },err=>{
      this.utilityService.hideLoader();
    });
  }
  refreshDocumentList() {
    let leadValue = {
      "lead": this.leadID
    }
    this.apiservice.get(APIConstant.DOCUMENT_AND_TYPE_LIST, leadValue, resObj => {
      if(resObj.documents){
        this.documentList = resObj.documents;
        for (let key in this.documentList) {
          let docCodeName=key;
          if(this.documentList[key].length){
            docCodeName=this.documentList[key][0].documentCodeTypeDetail;
          }
        }
        if(this.documentCodeList.length){
          this.documentTabDataList=this.documentList[this.documentCodeList[0].docValue];
        }
      }
    },err=>{
    });
  }
  reloadData(){
    this.refreshDocumentList();
  }
  viewDocument(document,imgModal) {
    this.utilityService.showLoader();
    this.apiservice.get(APIConstant.DELDOCUMENT + document.applicationDocumentID, '', resObj => {
      this.documentResObj = resObj;
      this.docUploadedPath = this.documentResObj.documentPath;
      this.isPdf = this.docUploadedPath.includes(".pdf") || this.docUploadedPath.includes(".PDF");
      this.imageData = this.sanitizer.bypassSecurityTrustUrl(this.docUploadedPath);
      this.isPdfData = this.sanitizer.bypassSecurityTrustResourceUrl(this.docUploadedPath);
      this.modalService.open(imgModal, {
        // size: 'sm',
        backdrop: 'static',
        keyboard: false,
      });
      this.utilityService.hideLoader();
    })
  }
  downloadDocument(document) {
    this.apiservice.downloadFile(document.applicationDocumentID);
  }
  callBackUrl() {
    history.back();
  }
  closeModal(){
    this.modalService.dismissAll();
  }
  getDocumentType(){
    let localDocCodes=JSON.parse(this.sessionStorageService.getData(CustomStorage.sessionKeys.documentCodes));
    for (let key in localDocCodes) {
      let docCodeName=key;
      if(localDocCodes[key].length){
        docCodeName=localDocCodes[key][0].documentCodeTypeDetail;
      }
      if(this.formPermissionData.DocumentType.componentPrivileges[key] && this.formPermissionData.DocumentType.componentPrivileges[key].isView){
        this.documentTypeList.push({docValue:key, docCodeName:docCodeName});
      }
    }
  }
  resetDocForm(){
    this.progress = null;
    this.uploadBtn = false;
    this.docCodeErrMsg = ''
    this.documentUploadForm.controls['documentType'].setValue('');
    this.documentUploadForm.controls['documentName'].setValue('');
    this.documentUploadForm.controls['documentCode'].setValue('');
    this.documentUploadForm.controls['documentApplicant'].setValue('');
    this.documentUploadForm.controls['documentApplicant'].disable();
      this.documentUploadForm.controls['documentType'].disable();
    this.docNameRequiredMsg = '';
    this.docTypeRequiredMsg = '';
    this.docFileRequiredMsg = '';
    this.isFileUpload = true;
    this.imgURL = '';
  }
}
// documentCodeList = [
//   {docValue:'ApplicationFormUpload', docCodeName:'Application Form Upload'},
//   {docValue: 'DOB', docCodeName:'DOB'},
//   {docValue: 'AddressProof', docCodeName:'Address Proof'},
//   {docValue: 'EmploymentProof', docCodeName:'Employment Proof'},
//   {docValue: 'IncomeProof', docCodeName:'Income Proof'},
//   {docValue: 'BankStatement', docCodeName:'Bank Statement'},
//   {docValue: 'WavedProof', docCodeName:'Waved Proof'},
//   {docValue: 'LoginFeeTransferProof', docCodeName:'Login Fee Transfer Proof'},
//   {docValue: 'CpvFiringDoc', docCodeName:'CPV Firing'},
//   {docValue: 'CpvReport', docCodeName:'CPV Report'},
//   {docValue: 'RoiApprovalUpload', docCodeName:'ROI Approval Upload'},
//   {docValue: 'InsrUpload', docCodeName:'INSR Upload'},
//   {docValue: 'FoirUpload', docCodeName:'FOIR Upload'},
//   {docValue: 'LtvUpload', docCodeName:'LTV Upload'},
//   {docValue: 'LcrUpload', docCodeName:'LCR Upload'},
//   {docValue: 'TechnicalFiringDoc', docCodeName:'Technical Firing'},
//   {docValue: 'KycDocument', docCodeName:'KYC Document'},
//   {docValue: 'PdReportDocument', docCodeName:'PD Report Document'},
//   {docValue: 'BureauReport', docCodeName:'Bureau Report'},
//   {docValue: 'HunterDocument', docCodeName:'Hunter Document'},
//   {docValue: 'LifeInsDoc', docCodeName:'Life Insurance Document'},
//   {docValue: 'HealthInsDoc', docCodeName:'Health Insurance Document'},
//   {docValue: 'GenInsDoc', docCodeName:'General Insurance Document'},
//   {docValue: 'DepositeSlip', docCodeName:'Login Fee Deposite Slip'},
//   {docValue: 'TechnicalDocument', docCodeName:'Technical Document'},
//   {docValue: 'DeviationDocument', docCodeName:'Deviation Document'}
// ]



