import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators,FormGroupDirective } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';
import { ApiService } from 'src/app/services/api.service';
import { APIConstant } from "src/app/constants/apiConstants";
import { BaseComponent } from 'src/app/components/basecomponent/basecomponent.component';
import { ValidationMessage } from "src/app/constants/validationMessage";
import { NavigationConstants } from "src/app/constants/navigationConstant";
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css']
})
export class NewDashboardComponent extends BaseComponent implements OnInit {
  zoneDashboardData: any = [];
  regionDashboardData:any=[];
  branchDashboardData:any=[];
  showZoneData:boolean=false;
  showRegionData:boolean=false;
  showBranchData:boolean=false;
  showRegionName='';
  searchText:string='';
  showBranchName='';
  currentZoneID:any;
  currentType:any;
  formPermissionData:any;
  formPermissionDatainorganicDashboard:any;
  showFOSData : boolean = false;
  showFOSName = '';
  FOSDashboardData : any =[];
  currentRegionID: any;
  currentFOSZoneID: any;
  currentRegionType: any;
  isShowDsa:boolean = false;
  isShowChartDashboard:boolean = false;

  constructor(public _fb: FormBuilder, private modalService: NgbModal,private router: Router,
    private utilityService: UtilityService,private apiService: ApiService) {
    super(utilityService);
    this.formPermissionData = this.utilityService.getPermission('Dashboard', 'Dashboard');
    if(this.formPermissionData && this.formPermissionData.Dashboard && this.formPermissionData.Dashboard.isView==true){

    } else {
      this.utilityService.reDirectToUrl(NavigationConstants.MYTASK);
    }
   }

  ngOnInit() {
    this.zoneDashboard();
  }
  showDsaDashboard(){
    this.isShowDsa = !this.isShowDsa
  }
  showInorganicDashboard(){
    this.router.navigate(['/all-dashboard/inorganic-dashboard']);
  }
  showChartDashboard(){
    this.isShowChartDashboard = !this.isShowChartDashboard;
  }
  zoneDashboard(){
    this.showZoneData=true;
    this.showRegionData=false;
    this.showBranchData=false;
    this.showFOSData = false;
      this.utilityService.showLoader();
      this.apiService.get(APIConstant.GETZONEDASHBOARD,{}, resObj => {
        this.zoneDashboardData = resObj;
        this.utilityService.hideLoader();
        this.searchText='';
      },err=>{
        this.utilityService.hideLoader();
      });
  }
  getTotalByType(field: string, type: string): number {
    return this.zoneDashboardData
      .filter(zone => zone.chequeHandoverCount === type)
      .reduce((sum, zone) => parseInt(sum) + (parseInt(zone[field]) || 0), 0);
  }
    regionDashboard(type,zoneID){
      this.showZoneData=false;
      this.showRegionData=true;
      this.showBranchData=false;
      this.showFOSData = false;
      this.currentType=type;
      this.utilityService.showLoader();
      this.apiService.get(APIConstant.GETREGIONDASHBOARD,{type:type,zoneID:zoneID }, resObj => {
        this.regionDashboardData = resObj;
        if(type=='ALL'){
          this.showRegionName='All Region';
        }
       else{
         this.showRegionName=this.regionDashboardData[0].zoneName+'>>Region';}

       this.utilityService.hideLoader();
       this.searchText='';
      },err=>{
        this.utilityService.hideLoader();
      });
    }

    branchDashboard(type,regionID,zoneID){
      console.warn(zoneID);
      this.showZoneData=false;
      this.showRegionData=false;
      this.showBranchData=true;
      this.showFOSData = false;
      this.currentRegionType= type;
      this.currentZoneID=zoneID;
      this.currentRegionID = regionID;
      this.utilityService.showLoader();
      this.apiService.get(APIConstant.GETBRANCHDASHBOARD,{type:type,regionID:regionID,zoneID:zoneID}, resObj => {
        this.branchDashboardData = resObj;
        if(type=='ALL'){
          this.showBranchName='All Branch';
        }
       else{
        this.showBranchName=this.branchDashboardData[0].zoneName+'>>'+this.branchDashboardData[0].regionName+'>>Branch';
      }
        this.utilityService.hideLoader();
        this.searchText='';
      },err=>{
        this.utilityService.hideLoader();
      });
    }
    getFOSData(branchID){
      this.showZoneData = false;
      this.showRegionData = false;
      this.utilityService.showLoader();
      let apiURL=APIConstant.GETFOSDASHBOARD.replace('{branchID}',branchID);
      this.apiService.get(apiURL,{}, resObj => {
        if(resObj == null || resObj.length == 0){
          this.showBranchData = true;
          this.utilityService.hideLoader();
        }
        else{
        this.showFOSData = true;
        this.showBranchData = false;
       this.FOSDashboardData = resObj;
       this.currentFOSZoneID = this.FOSDashboardData[0].zoneID;
       this.currentRegionID = this.FOSDashboardData[0].regionID;
      this.showFOSName = this.FOSDashboardData[0].zoneName + '>>'+this.FOSDashboardData[0].regionName + '>>' + this.FOSDashboardData[0].branchName;
      this.utilityService.hideLoader();
      this.searchText='';
    }},err=>{
      this.utilityService.hideLoader();
      Swal.fire({
        allowOutsideClick: false,
        text: 'just text',
        html: '<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">' + err.responseMsg + '</p>',
        })
    });
    }
  }




