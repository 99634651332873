<div class="content-wrapper finalprevi">
  <!--- middle content ------>
  <!-- Main content -->
  <section class="content based-form" style="background-color: white;">
    <div id="myWizard" class="mybasicform_blk">
      <div class=" tabl-form-controll">

        <!--Login Fee Start-->
        <!-- <div class="tab-pane first-tab active">
            <div class="formtitle">
                <h5> DASHBOARD</h5>
            </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-md-6 col-xl-3">
            <div class="card bg-c-blue order-card">
              <div class="card-block">
                <h4 class="m-b-20">Login</h4>
                <div class="more-info">
                  <div class="stats-score">
                    <div class="authscore">
                      <div class="title m-b-20">Today's Count: 57</div>
                      <div class="title m-b-20">Today's Value: 1,00,07,878</div>
                    </div>
                    <div class="authscore">
                      <div class="title m-b-20">Monthly Count: 237</div>
                      <div class="title m-b-20">Monthly Value: 5,12,67,349</div> 
                    </div>
                  </div>
              </div>
                <h2 class="text-right"><i class="ti-shopping-cart f-left"></i><span>486</span></h2>
                <p class="m-b-0">Completed Orders<span class="f-right">351</span></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="card bg-c-pink order-card">
              <div class="card-block">
                <h4 class="m-b-20">Sanction</h4>
                <div class="more-info">
                  <div class="stats-score">
                    <div class="authscore">
                      <div class="title m-b-20">Today's Count: 57</div>
                      <div class="title m-b-20">Today's Value: 1,00,07,878</div>
                    </div>
                    <div class="authscore">
                      <div class="title m-b-20">Monthly Count: 237</div>
                      <div class="title m-b-20">Monthly Value: 5,12,67,349</div> 
                    </div>
                  </div>
                </div>
                <h2 class="text-right"><i class="ti-tag f-left"></i><span>1641</span></h2>
                <p class="m-b-0">This Month<span class="f-right">213</span></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="card bg-c-yellow order-card">
              <div class="card-block">
                <h4 class="m-b-20">Charts</h4>
                <div class="more-info">
                  <div class="stats-score">
                    <div class="authscore">
                      <div class="title m-b-20">Today's Count: 57</div>
                      <div class="title m-b-20">Today's Value: 1,00,07,878</div>
                    </div>
                    <div class="authscore">
                      <div class="title m-b-20">Monthly Count: 237</div>
                      <div class="title m-b-20">Monthly Value: 5,12,67,349</div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="card bg-c-green order-card">
              <div class="card-block">
                <h4 class="m-b-20">Reports</h4>
                <h2 class="text-right"><i class="ti-wallet f-left"></i><span>$9,562</span></h2>
                <p class="m-b-0">This Month<span class="f-right">$542</span></p>
              </div>
            </div>
          </div>
        </div> -->

        <div class="row mb-20 pl-10">

          <div class="col-auto mt-0">
            <button (click)="loginDashboard()" mat-raised-button class="login-dashboard-color btn-click-effect"
              id="loginDashboard"><span>Login</span></button>
          </div>

          <div class="col-auto mt-0">
            <button (click)="sanctionDashboard()" mat-raised-button class="sanction-dashboard-color btn-click-effect"
              id="sanctionDashboard"><span>Sanction</span></button>
          </div>

          <div class="col-auto mt-0">
            <button (click)="disbursalDashboard()" mat-raised-button class="disbursal-dashboard-color btn-click-effect"
              id="disburseDashboard"><span>Disbursement</span></button>
          </div>

          <div class=" col-sm-4 col-auto mt-0" align="right" style="left: 700px;">
            <input type="text" [(ngModel)]="searchText" placeholder='Type Region or Branch to search'
              class="dashboard-searchbar">
          </div>

          <!-- <div class="col-auto mt-0"  align="right" style="left: 644px;">
            <button class="btn btn-rounded btn-success"
              (click)="downloadExlFile(this.LeadAssignmentData)"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
          </div> -->


        </div>

        <!-- <div class="form-row">
          <div class="col-md-12 actico-tbl-sec">
            <h4>Income Eligibility Parameter </h4>
            <div class="table-responsive tableforltv">
              <table id="example" class="table table-bordered text-left tableview scrollTable eli-rejection-table" >
                <thead>
                  <tr>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">KPI</th>
                    <th style=" color: white; background-color: #59e0c5 !important;"  class="text-center">As Per Norm% </th>
                    <th style=" color: white; background-color: #59e0c5 !important;"  class="text-center">Final % (with Ins.)
                    </th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">Eligible Amount</th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">KPI</th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">As Per Norm% </th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">KPI</th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">As Per Norm% </th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">Eligible Amount</th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">KPI</th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">As Per Norm% </th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">KPI</th>
                    <th style=" color: white; background-color: #59e0c5 !important;" class="text-center">As Per Norm% </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">FOIR</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00

                    </td>
                    <td class="text-center">₹7,821,363.00</td>
                  </tr>
                  <tr>
                    <td class="text-center">LTV</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00</td>
                    <td class="text-center">₹7,821,363.00

                    </td>
                    <td class="text-center">₹7,821,363.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> -->
        <div class="tab-content doc-tab-content dashboard-table-card">
          <div *ngIf="showLogin" class="table-responsive dashboard-table-div">
            <table class="table my_bank_table table-bordered dashboard-login-table mb-35 scrollTable" id="example">
              <thead>
                <tr>
                  <th width="160">Region </th>
                  <th width="160">Branch</th>
                  <th width="220" colspan="2">Today</th>
                  <th width="220" colspan="2">Monthly</th>
                  <th width="220" colspan="2">Target</th>
                  <th width="334" colspan="2">Achieved (%)</th>
                </tr>
                <tr>
                  <th width="160"> </th>
                  <th width="160"> </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="160">File Count </th>
                  <th width="174">Amount </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let login of LoginDashboardData| SearchPipe: searchText ; let i = index">
                  <td width="160">{{login.regionName}}</td>
                  <td width="160">{{login.branchName}}</td>
                  <td width="110">{{login.todayNumber}}</td>
                  <td width="110">{{login.todayAmount | number : '1.0-0'}}</td>
                  <td width="110">{{login.mtdNumber}}</td>
                  <td width="110">{{login.mtdAmount | number : '1.0-0'}}</td>
                  <td width="110">{{login.targetFile}}</td>
                  <td width="110">{{login.targetAmount | number : '1.0-0'}}</td>
                  <td width="160">{{login.targetFileAchieved}}</td>
                  <td width="157">{{login.targetAmountAchieved | number : '1.2-2'}}</td>
                </tr>
                <tr style="background: #8bf1ae; font-weight: bold;">
                  <td width="160">TOTAL</td>
                  <td width="160"></td>
                  <td width="110">{{LoginDashboardData | SearchPipe: searchText | total:'todayNumber' | number}}</td>
                  <td width="110">{{LoginDashboardData | SearchPipe: searchText | total:'todayAmount' | number}}</td>
                  <td width="110">{{LoginDashboardData | SearchPipe: searchText | total:'mtdNumber' | number}}</td>
                  <td width="110">{{LoginDashboardData | SearchPipe: searchText | total:'mtdAmount' | number}}</td>
                  <td width="110">{{LoginDashboardData | SearchPipe: searchText | total:'targetFile' | number}}</td>
                  <td width="110">{{LoginDashboardData | SearchPipe: searchText | total:'targetAmount' | number}}</td>
                  <td width="160">{{ ((LoginDashboardData | SearchPipe: searchText | total:'mtdNumber')/(LoginDashboardData | SearchPipe: searchText | total:'targetFile')) * 100 | number : '1.2-2'}}</td>
                  <td width="157">{{ ((LoginDashboardData | SearchPipe: searchText | total:'mtdAmount')/(LoginDashboardData | SearchPipe: searchText | total:'targetAmount')) * 100 | number : '1.2-2'}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="showSanction" class="table-responsive dashboard-table-div">
            <table class="table my_bank_table table-bordered dashboard-santion-table mb-35 " id="example">
              <thead>
                <tr>
                  <th width="160">Region </th>
                  <th width="160">Branch</th>
                  <th width="220" colspan="2">Today</th>
                  <th width="220" colspan="2">Monthly</th>
                  <th width="220" colspan="2">Target</th>
                  <th width="334" colspan="2">Achieved (%)</th>
                </tr>
                <tr>
                  <th width="160"> </th>
                  <th width="160"> </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="160">File Count </th>
                  <th width="174">Amount </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sanction of SanctionDashboardData| SearchPipe: searchText ; let i = index">
                  <td width="160">{{sanction.regionName}}</td>
                  <td width="160">{{sanction.branchName}}</td>
                  <td width="110">{{sanction.todayNumber}}</td>
                  <td width="110">{{sanction.todayAmount | number : '1.0-0'}}</td>
                  <td width="110">{{sanction.mtdNumber}}</td>
                  <td width="110">{{sanction.mtdAmount | number : '1.0-0'}}</td>
                  <td width="110">{{sanction.targetFile}}</td>
                  <td width="110">{{sanction.targetAmount | number : '1.0-0'}}</td>
                  <td width="160">{{sanction.targetFileAchieved}}</td>
                  <td width="157">{{sanction.targetAmountAchieved | number : '1.2-2'}}</td>
                </tr>
                <tr style="background: #8bf1ae; font-weight: bold;" >
                  <td width="160">TOTAL</td>
                  <td width="160"></td>
                  <td width="110">{{SanctionDashboardData | SearchPipe: searchText | total:'todayNumber' | number}}</td>
                  <td width="110">{{SanctionDashboardData | SearchPipe: searchText | total:'todayAmount' | number}}</td>
                  <td width="110">{{SanctionDashboardData | SearchPipe: searchText | total:'mtdNumber' | number}}</td>
                  <td width="110">{{SanctionDashboardData | SearchPipe: searchText | total:'mtdAmount' | number}}</td>
                  <td width="110">{{SanctionDashboardData | SearchPipe: searchText | total:'targetFile' | number}}</td>
                  <td width="110">{{SanctionDashboardData | SearchPipe: searchText | total:'targetAmount' | number}}</td>
                  <td width="160">{{ ((SanctionDashboardData | SearchPipe: searchText | total:'mtdNumber')/(SanctionDashboardData | SearchPipe: searchText | total:'targetFile')) * 100 | number : '1.2-2'}}</td>
                  <td width="157">{{ ((SanctionDashboardData | SearchPipe: searchText | total:'mtdAmount')/(SanctionDashboardData | SearchPipe: searchText | total:'targetAmount')) * 100 | number : '1.2-2'}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="showDisbursal" class="table-responsive dashboard-table-div">
            <table class="table my_bank_table table-bordered dashboard-disbursal-table mb-35 " id="example">
              <thead>
                <tr>
                  <th width="160">Region </th>
                  <th width="160">Branch</th>
                  <th width="220" colspan="2">Today</th>
                  <th width="220" colspan="2">Monthly</th>
                  <th width="220" colspan="2">Target</th>
                  <th width="334" colspan="2">Achieved (%)</th>
                </tr>
                <tr>
                  <th width="160"> </th>
                  <th width="160"> </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="110">File Count </th>
                  <th width="110">Amount </th>
                  <th width="160">File Count </th>
                  <th width="174">Amount </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let disbursal of DisbursalDashboardData| SearchPipe: searchText ; let i = index">
                  <td width="160">{{disbursal.regionName}}</td>
                  <td width="160">{{disbursal.branchName}}</td>
                  <td width="110">{{disbursal.todayNumber}}</td>
                  <td width="110">{{disbursal.todayAmount | number : '1.0-0'}}</td>
                  <td width="110">{{disbursal.mtdNumber}}</td>
                  <td width="110">{{disbursal.mtdAmount | number : '1.0-0'}}</td>
                  <td width="110">{{disbursal.targetFile}}</td>
                  <td width="110">{{disbursal.targetAmount | number : '1.0-0'}}</td>
                  <td width="160">{{disbursal.targetFileAchieved}}</td>
                  <td width="157">{{disbursal.targetAmountAchieved | number : '1.2-2'}}</td>
                </tr>
                <tr style="background: #8bf1ae; font-weight: bold;" >
                  <td width="160">TOTAL</td>
                  <td width="160"></td>
                  <td width="110">{{DisbursalDashboardData | SearchPipe: searchText | total:'todayNumber' | number}}</td>
                  <td width="110">{{DisbursalDashboardData | SearchPipe: searchText | total:'todayAmount' | number}}</td>
                  <td width="110">{{DisbursalDashboardData | SearchPipe: searchText | total:'mtdNumber' | number}}</td>
                  <td width="110">{{DisbursalDashboardData | SearchPipe: searchText | total:'mtdAmount' | number}}</td>
                  <td width="110">{{DisbursalDashboardData | SearchPipe: searchText | total:'targetFile' | number}}</td>
                  <td width="110">{{DisbursalDashboardData | SearchPipe: searchText | total:'targetAmount' | number}}</td>
                  <td width="160">{{ ((DisbursalDashboardData | SearchPipe: searchText | total:'mtdNumber')/(DisbursalDashboardData | SearchPipe: searchText | total:'targetFile')) * 100 | number : '1.2-2'}}</td>
                  <td width="157">{{ ((DisbursalDashboardData | SearchPipe: searchText | total:'mtdAmount')/(DisbursalDashboardData | SearchPipe: searchText | total:'targetAmount')) * 100 | number : '1.2-2'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </section>
</div>