import { CommonModule,DatePipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { LoginComponent } from "./components/login/login.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxPaginationModule } from 'ngx-pagination';
// import this new file
import { MaterialModule } from "./shared/material";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApiService } from "./services/api.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ForgetPasswordComponent } from "./components/forget-password/forget-password.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { VerifyOtpComponent } from "./components/verify-otp/verify-otp.component";
import { ToastrModule } from "ngx-toastr";
import { BaseComponent } from "./components/basecomponent/basecomponent.component";
import { MatDialogModule } from "@angular/material/dialog";
import { FinalpreviewComponent } from "./pages/finalpreview/finalpreview.component";
import { DocumentUploadComponent } from "./components/document-upload/document-upload.component";
import { TokenInterceptorService } from "./services/token-interceptor.service";
import { KycComponent } from './kyc/kyc.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { DocumentListComponent } from './components/document-upload/document-list/document-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SearchPipe } from './pages/dashboard/search.pipe';
import { TotalPipe } from './pipes/total.pipe';
import { NavService } from 'src/app/services/nav.service';
import { NewDashboardComponent } from './pages/new-dashboard/new-dashboard.component';
import { CustomPipeModule } from 'src/app/pipes/custom-pipe.module';
import { DsaDashboardComponent } from './dsa-dashboard/dsa-dashboard.component';
import { ChartsModule } from "ng2-charts";
import { CustomUrlSerializer } from "./Interface/CustomUrlSerializer";
import { LoginOtpComponent } from './components/login-otp/login-otp.component';
import { NgOtpInputModule } from "ng-otp-input";
import { ShareableModule } from "./shared/shareable.module";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        ForgetPasswordComponent,
        ChangePasswordComponent,
        VerifyOtpComponent,
        BaseComponent,
        FinalpreviewComponent,
        DocumentUploadComponent,
        KycComponent,
        AlertDialogComponent,
        DocumentListComponent,
        DashboardComponent,
        ResetPasswordComponent,
        SearchPipe,
        TotalPipe,
        NewDashboardComponent,
        DsaDashboardComponent,
        LoginOtpComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        HttpClientModule,
        NgbModule,
        CommonModule,
        ToastrModule.forRoot(),
        MatDialogModule,
        NgxPaginationModule,
        CustomPipeModule,
        ChartsModule,
        NgOtpInputModule,
        ShareableModule
    ],
    providers: [
        DatePipe,
        ApiService,
        NavService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },
        // { provide: UrlSerializer, useClass: CustomUrlSerializer },
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule { }

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    // Optional Step: Do some stuff with the url if needed.

    // If you lower it in the optional step
    // you don't need to use "toLowerCase"
    // when you pass it down to the next function
    return super.parse(url.toLowerCase());
  }
}
