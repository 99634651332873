import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { ApiService } from 'src/app/services/api.service';
import { APIConstant } from "src/app/constants/apiConstants";




declare var $: any;

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})
export class KycComponent implements OnInit {
  
  
  userDetails: any;
  kycTypes: any;
  // kycTypes = [
  //           {
  //               "name": "AADHAAR_ZIP_INLINE",
  //               "value": "Aadhaar OTP"
  //           },
  //           {
  //               "name": "QRCODE_PAN_REQUEST",
  //               "value": "QR code and PAN "
  //           },
  //           {
  //               "name": "QRCODE_DL_REQUEST",
  //               "value": "QR code and DL"
  //           },
  //           {
  //               "name": "PAN_DL_REQUEST",
  //               "value": "PAN and DL "
  //           }
  //       ]
  selectedKycType: any;
  kycTypeSelectedOption: any;
  kycID: string;
  kycRequestData: any;

  

  constructor(private apiService: ApiService, private activatedRoute: ActivatedRoute, private changeDetector: ChangeDetectorRef) { }


  ngOnInit() {
    let currentObject = window.location.href;
    let finalData = currentObject.replace (/%/g, "");
    debugger;
    $.getJSON('http://localhost:4200/kyc/start/%5B%7B%22name%22:%22Ravi%20Kumar%22,%22age%22:%2230%22,%22height%22:%221.9%22%7D%5D', function(data) {
    //data is the JSON string
});
  }

  kycTypeSelection(event){
    this.kycTypeSelectedOption = event;      
  }

  submitMyForm(event){
  }

}



