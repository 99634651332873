import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ValidationMessage } from "src/app/constants/validationMessage";
import { APIConstant } from "src/app/constants/apiConstants";
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/storage/local-storage.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ForgetPasswordComponent implements OnInit {
  forgot_password_form: FormGroup;
  submittedForgotPassword = false;
  validationMessage = ValidationMessage;
  requiredErrorMessage: any;
  companyList: any = [];
  forgotUserName = "forgot_user_name";
  forgotPassword_id = "forgetPassword_id";

  constructor(public _fb: FormBuilder, private apiservice: ApiService, private router:Router, private localStorageService: LocalStorageService,) {
    this.requiredErrorMessage = this.validationMessage.common;
  }

  ngOnInit() {
    this.forgot_password_form = this._fb.group({
      forgot_user: ['', Validators.required],
      company: ["0", Validators.required]
    });
    this.getCompanyList();
  }

  get forgotPassword_control() { return this.forgot_password_form.controls; }
  getCompanyList() {
    // this.apiservice.get(APIConstant.COMPANYLIST, {}, resObj => {
    //   this.companyList = resObj;
    // });
    this.companyList = [{
      companyId: 1,
      companyName: "DMI Housing Finance",
      companySlug: "DMI_HFC"
    }];
  }
  onSubmitForgotPwd() {
    this.submittedForgotPassword = true;
    if (this.forgot_password_form.invalid) {
      return;
    } else {
      this.localStorageService.setData(this.forgotUserName,this.forgot_password_form.value.forgot_user);
      let payload = {
        userName: this.forgot_password_form.value.forgot_user,
        company: {
          companyId: this.companyList[this.forgot_password_form.value.company].companyId,
          companySlug: "DMI_HFC"
        }
      }
      
      this.apiservice.post(APIConstant.GET_OTP, payload, resObj => {
        if(resObj){
          console.log(resObj.responseObj.id)
          this.localStorageService.setData(this.forgotPassword_id,resObj.responseObj.id);
          this.router.navigate(["/reset_password"]);
        }
      }
      , error => {
        Swal.fire({
          allowOutsideClick: false,
          text:'just text',
          html:'<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">'+error.responseMsg+'</p>',
        })
      }
      );
    }
  }

}
