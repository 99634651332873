<div class="content-wrapper finalprevi">
  <!--- middle content ------>
  <!-- Main content -->
  <section class="content based-form">
    <div id="myWizard" class="mybasicform_blk">
      <div class=" tabl-form-controll">
 
        <!--Login Fee Start-->
        <div class="tab-pane first-tab active">
            <div class="formtitle">
                <h5> Final Preview</h5>
              </div>

          <div class="box box-default my_inner_box">
            <div class="box-body wizard-content">
              <div class="tab-content">
                <div class="first_blk_line form-element">
                  
                  <h4 class="inte-box-title"><strong>Final Preview</strong></h4>
                  
                  <div class="finalpreview-box">
                    <table>
                      <tr>
                        <td>
                            <div class="basicapplicatn-detil">
                                <p> <b>Applicant Full Name: </b> Rajesh Maurya </p>
                                <p> <b>Application Number: </b> 0000000000001 </p>
                                <p> <b>Lead Number: </b> LED0000000000001 </p>
                                <p> <b>Branch: </b> New Delhi </p>
                                <p> <b>Product: </b> Home Loan </p>
                            </div>
                        </td>
                        <td>

                            <div class="basicapplicatn-detil1">
                                <div class="final-userimg">
                                  <img src="assets/images/avatar/7.jpg" alt="applicant image">
        
                                </div>
                            </div>


                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                            <h5 class="fiantotle"><span class="titlesept"> Step 1</span> <span class="titlebar"> Loan Information  <button class="editfinalbutn pull-right" title="Edit"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> </button></span> </h5>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2">
                          <table>
                            <tr>
                                <td width="154"> 
                                  </td>
                                  <td>
                                     
                                      
                                      <div class="fist-tabobox">
                                          <h5>Lead Information</h5>
                                          <div class="detailsbox">
                                              <div class="row">
                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Branch </label>
                                                      <p class="finalfields"> DMI South Delhi</p>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Applicant First Name </label>
                                                      <p class="finalfields"> Hitesh</p>
                                                    </div>
                                                </div>



                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Applicant Middle Name </label>
                                                      <p class="finalfields">Kumar </p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Applicant Last Name </label>
                                                      <p class="finalfields">Rawat</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Contact No. </label>
                                                      <p class="finalfields"> +091 999999999</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Type of Loan </label>
                                                      <p class="finalfields"> Home Loan</p>
                                                    </div>
                                                </div>



                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Location </label>
                                                      <p class="finalfields">New Delhi</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Email </label>
                                                      <p class="finalfields"> hiteshhello@gmail.com</p>
                                                    </div>
                                                </div>



          
          
                                              </div>
          
          
                                          </div>
          
                                      </div>

                                      <div class="fist-tabobox">
                                          <h5>Loan Information</h5>
                                          <div class="detailsbox">
                                              <div class="row">
                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Loan Product </label>
                                                      <p class="finalfields"> House Loan</p>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Loan Purpose</label>
                                                      <p class="finalfields"> Construction</p>
                                                    </div>
                                                </div>



                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Loan Scheme</label>
                                                      <p class="finalfields">Scheme1 </p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Processing Fee %</label>
                                                      <p class="finalfields">8.2%</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Property Selected </label>
                                                      <p class="finalfields"> Yes</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Loan Amount Request </label>
                                                      <p class="finalfields">  10,100,000.00</p>
                                                    </div>
                                                </div>



                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Tenor (In Month) </label>
                                                      <p class="finalfields">120</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Interest Type </label>
                                                      <p class="finalfields"> Fixed</p>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Affordable EMI </label>
                                                      <p class="finalfields"> Rs. 1000.00</p>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Sales Officer </label>
                                                      <p class="finalfields"> EMP03_g.ravinder@rvsolutions.in</p>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Credit Officer </label>
                                                      <p class="finalfields"> EMP03_g.ravinder@rvsolutions.in</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Application Number </label>
                                                      <p class="finalfields"> GG0000001</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Sourcing Channel Partner</label>
                                                      <p class="finalfields"> DSA</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Channel Partner Name</label>
                                                      <p class="finalfields"> RV Solutions Pvt. Ltd.</p>
                                                    </div>
                                                </div>
 
                                              </div>
          
          
                                          </div>
          
                                      </div>

                                      <div class="fist-tabobox">
                                          <h5>Rules Based Preliminary Output</h5>
                                          <div class="detailsbox">
                                              <div class="row">
                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Status </label>
                                                      <p class="finalfields"> Pre-Approved</p>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Requested Amount</label>
                                                      <p class="finalfields"> Rs. 10000</p>
                                                    </div>
                                                </div>



                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Eligible Loan Amount</label>
                                                      <p class="finalfields">Rs. 10000 </p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Requested Tenure</label>
                                                      <p class="finalfields">25 Month</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Proposed Tenure </label>
                                                      <p class="finalfields"> 25 Month</p>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="fieldslisting">
                                                      <label class="finallabel" for="">Proposed EMI</label>
                                                      <p class="finalfields">  10,100,000.00</p>
                                                    </div>
                                                </div>

  
                                              </div>
          
          
                                          </div>
          
                                      </div>


                                    </td>
                            </tr>
                          </table>
                        </td>

                      </tr>

                      <tr>
                          <td colspan="2">
                              <h5 class="fiantotle"><span class="titlesept"> Step 2.1</span> <span class="titlebar"> Personal  (Applicant: Rakesh Roshan)<button class="editfinalbutn pull-right" title="Edit"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> </button></span> </h5>
                          </td>
                        </tr>
  
                        <tr>
                          <td colspan="2">
                            <table>
                              <tr>
                                  <td width="154"> 
                                    </td>
                                    <td>
                                          
                                        <div class="fist-tabobox">
                                            <h5>KYC</h5>
                                            <div class="detailsbox">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Identification Type</label>
                                                        <p class="finalfields"> UID</p>
                                                      </div>
                                                  </div>
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for=""> Number </label>
                                                        <p class="finalfields"> 00001111100</p>
                                                      </div>
                                                  </div>
  
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Issue Date </label>
                                                        <p class="finalfields">01/Mar/2020 </p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Expiry Date </label>
                                                        <p class="finalfields">01/Mar/2020</p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for=""> Verified Status</label>
                                                        <p class="finalfields"> Positive </p>
                                                      </div>
                                                  </div>
  
                                                </div>
                                                
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Mobile No.</label>
                                                          <p class="finalfields"> +91 000 000 0000 <img src="assets/images/verified-icon.png" class="img-verified"></p>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for=""> Email Id </label>
                                                          <p class="finalfields"> dmihousing@gmail.com</p>
                                                        </div>
                                                    </div>
    
    
     
    
                                                  </div>


            
                                            </div>
            
                                        </div>
  
                                        <div class="fist-tabobox">
                                            <h5>Basic Details</h5>
                                            <div class="detailsbox">
                                                <div class="row">
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">First Name  </label>
                                                        <p class="finalfields"> Kumar 01</p>
                                                      </div>
                                                  </div>
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Middle Name</label>
                                                        <p class="finalfields"> Kumar</p>
                                                      </div>
                                                  </div>
  
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Last Name</label>
                                                        <p class="finalfields">Roshan </p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">DOB</label>
                                                        <p class="finalfields">2/3/1997</p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">DOB </label>
                                                        <p class="finalfields"> 22</p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">DOB Proof</label>
                                                        <p class="finalfields">  PAN</p>
                                                      </div>
                                                  </div>
  
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Gender</label>
                                                        <p class="finalfields">Indian</p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Nationality </label>
                                                        <p class="finalfields"> Hindu</p>
                                                      </div>
                                                  </div>
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Religion </label>
                                                        <p class="finalfields"> SC</p>
                                                      </div>
                                                  </div>
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">No. of Earning Members </label>
                                                        <p class="finalfields">01</p>
                                                      </div>
                                                  </div>
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Qualification </label>
                                                        <p class="finalfields"> Commerce</p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Detail Qualification </label>
                                                        <p class="finalfields"> Single</p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Marital Status </label>
                                                        <p class="finalfields"> Single</p>
                                                      </div>
                                                  </div>
  
  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Father First Name </label>
                                                        <p class="finalfields"> Ram f </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Father Middle Name</label>
                                                        <p class="finalfields"> Ram f </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Father Last Name</label>
                                                        <p class="finalfields"> Ram f </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Alternate Contact</label>
                                                        <p class="finalfields"> 9811452 </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">No. of Dependents</label>
                                                        <p class="finalfields"> 3 </p>
                                                      </div>
                                                  </div>


                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Living standard Indicators (LSI)</label>
                                                        <p class="finalfields"> Yes </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Relation with Applicant</label>
                                                        <p class="finalfields"> Self </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Income Considerd?</label>
                                                        <p class="finalfields"> Yes </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-12">
                                                      <h4 class="subtitle1">Current Address </h4>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Residence Type </label>
                                                        <p class="finalfields"> Owned</p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Address Proof</label>
                                                        <p class="finalfields"> VoterID </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Rent Amount</label>
                                                        <p class="finalfields"> 122222 </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Address</label>
                                                        <p class="finalfields"> Nirman Vihar </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Landmark</label>
                                                        <p class="finalfields"> 834004 </p>
                                                      </div>
                                                  </div>
                                                  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">State</label>
                                                        <p class="finalfields"> Delhi </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">District</label>
                                                        <p class="finalfields"> New Delhi </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">City</label>
                                                        <p class="finalfields"> New Delhi </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Staying (In Yrs)</label>
                                                        <p class="finalfields"> 20 </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Distance From Branch (KM)</label>
                                                        <p class="finalfields"> 20 </p>
                                                      </div>
                                                  </div>
                                                    

                                                  <div class="col-md-12">
                                                      <h4 class="subtitle1">Permanent Address </h4>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Residence Type </label>
                                                        <p class="finalfields"> Owned</p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Address Proof</label>
                                                        <p class="finalfields"> VoterID </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Rent Amount</label>
                                                        <p class="finalfields"> 122222 </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Address</label>
                                                        <p class="finalfields"> Nirman Vihar </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Landmark</label>
                                                        <p class="finalfields"> 834004 </p>
                                                      </div>
                                                  </div>
                                                  
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">State</label>
                                                        <p class="finalfields"> Delhi </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">District</label>
                                                        <p class="finalfields"> New Delhi </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">City</label>
                                                        <p class="finalfields"> New Delhi </p>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Staying (In Yrs)</label>
                                                        <p class="finalfields"> 20 </p>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-3">
                                                      <div class="fieldslisting">
                                                        <label class="finallabel" for="">Distance From Branch (KM)</label>
                                                        <p class="finalfields"> 20 </p>
                                                      </div>
                                                  </div>
                                                    


   
                                                </div>
            
            
                                            </div>
            
                                        </div>
   
  
                                      </td>
                              </tr>
                            </table>
                          </td>
  
                        </tr>
                        

                        <tr>
                            <td colspan="2">
                                <h5 class="fiantotle"><span class="titlesept"> Step 2.2</span> <span class="titlebar"> Personal  (Co-Applicant: Sachin Tichkule)<button class="editfinalbutn pull-right" title="Edit"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> </button></span> </h5>
                            </td>
                          </tr>
    
                          <tr>
                            <td colspan="2">
                              <table>
                                <tr>
                                    <td width="154"> 
                                      </td>
                                      <td>
                                            
                                          <div class="fist-tabobox">
                                              <h5>KYC</h5>
                                              <div class="detailsbox">
                                                  <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Identification Type</label>
                                                          <p class="finalfields"> UID</p>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for=""> Number </label>
                                                          <p class="finalfields"> 00001111100</p>
                                                        </div>
                                                    </div>
    
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Issue Date </label>
                                                          <p class="finalfields">01/Mar/2020 </p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Expiry Date </label>
                                                          <p class="finalfields">01/Mar/2020</p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for=""> Verified Status</label>
                                                          <p class="finalfields"> Positive </p>
                                                        </div>
                                                    </div>
    
                                                  </div>
                                                  
                                                  <div class="row">
                                                      <div class="col-md-3">
                                                          <div class="fieldslisting">
                                                            <label class="finallabel" for="">Mobile No.</label>
                                                            <p class="finalfields"> +91 000 000 0000 <img src="assets/images/verified-icon.png" class="img-verified"></p>
                                                          </div>
                                                      </div>
      
                                                      <div class="col-md-3">
                                                          <div class="fieldslisting">
                                                            <label class="finallabel" for=""> Email Id </label>
                                                            <p class="finalfields"> dmihousing@gmail.com</p>
                                                          </div>
                                                      </div>
      
      
       
      
                                                    </div>
  
  
              
                                              </div>
              
                                          </div>
    
                                          <div class="fist-tabobox">
                                              <h5>Basic Details</h5>
                                              <div class="detailsbox">
                                                  <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">First Name  </label>
                                                          <p class="finalfields"> Kumar 01</p>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Middle Name</label>
                                                          <p class="finalfields"> Kumar</p>
                                                        </div>
                                                    </div>
    
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Last Name</label>
                                                          <p class="finalfields">Roshan </p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">DOB</label>
                                                          <p class="finalfields">2/3/1997</p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">DOB </label>
                                                          <p class="finalfields"> 22</p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">DOB Proof</label>
                                                          <p class="finalfields">  PAN</p>
                                                        </div>
                                                    </div>
    
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Gender</label>
                                                          <p class="finalfields">Indian</p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Nationality </label>
                                                          <p class="finalfields"> Hindu</p>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Religion </label>
                                                          <p class="finalfields"> SC</p>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">No. of Earning Members </label>
                                                          <p class="finalfields">01</p>
                                                        </div>
                                                    </div>
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Qualification </label>
                                                          <p class="finalfields"> Commerce</p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Detail Qualification </label>
                                                          <p class="finalfields"> Single</p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Marital Status </label>
                                                          <p class="finalfields"> Single</p>
                                                        </div>
                                                    </div>
    
    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Father First Name </label>
                                                          <p class="finalfields"> Ram f </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Father Middle Name</label>
                                                          <p class="finalfields"> Ram f </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Father Last Name</label>
                                                          <p class="finalfields"> Ram f </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Alternate Contact</label>
                                                          <p class="finalfields"> 9811452 </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">No. of Dependents</label>
                                                          <p class="finalfields"> 3 </p>
                                                        </div>
                                                    </div>
  
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Living standard Indicators (LSI)</label>
                                                          <p class="finalfields"> Yes </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Relation with Applicant</label>
                                                          <p class="finalfields"> Self </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Income Considerd?</label>
                                                          <p class="finalfields"> Yes </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-12">
                                                        <h4 class="subtitle1">Current Address </h4>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Residence Type </label>
                                                          <p class="finalfields"> Owned</p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Address Proof</label>
                                                          <p class="finalfields"> VoterID </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Rent Amount</label>
                                                          <p class="finalfields"> 122222 </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Address</label>
                                                          <p class="finalfields"> Nirman Vihar </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Landmark</label>
                                                          <p class="finalfields"> 834004 </p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">State</label>
                                                          <p class="finalfields"> Delhi </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">District</label>
                                                          <p class="finalfields"> New Delhi </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">City</label>
                                                          <p class="finalfields"> New Delhi </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Staying (In Yrs)</label>
                                                          <p class="finalfields"> 20 </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Distance From Branch (KM)</label>
                                                          <p class="finalfields"> 20 </p>
                                                        </div>
                                                    </div>
                                                      
  
                                                    <div class="col-md-12">
                                                        <h4 class="subtitle1">Permanent Address </h4>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Residence Type </label>
                                                          <p class="finalfields"> Owned</p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Address Proof</label>
                                                          <p class="finalfields"> VoterID </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Rent Amount</label>
                                                          <p class="finalfields"> 122222 </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Address</label>
                                                          <p class="finalfields"> Nirman Vihar </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Landmark</label>
                                                          <p class="finalfields"> 834004 </p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">State</label>
                                                          <p class="finalfields"> Delhi </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">District</label>
                                                          <p class="finalfields"> New Delhi </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">City</label>
                                                          <p class="finalfields"> New Delhi </p>
                                                        </div>
                                                    </div>
  
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Staying (In Yrs)</label>
                                                          <p class="finalfields"> 20 </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="fieldslisting">
                                                          <label class="finallabel" for="">Distance From Branch (KM)</label>
                                                          <p class="finalfields"> 20 </p>
                                                        </div>
                                                    </div>
                                                      
  
  
     
                                                  </div>
              
              
                                              </div>
              
                                          </div>
     
    
                                        </td>
                                </tr>
                              </table>
                            </td>
    
                          </tr>

                        
                        <tr> 
                          <td colspan="2">
                              <div class="text-center finalsub-buttons">
                                    <button class="btn btn-primary"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Application</button>
                                    <button  class="btn btn-success finalsubmt1"><i class="fa fa-floppy-o" aria-hidden="true"></i> Final Submit</button>
                              </div>
                          </td>
                        </tr>


                    </table>
                  </div>




                   
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Login Fee End-->

      </div>
    </div>
 
  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->
