import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { regExConstant } from 'src/app/constants/regExConstant';
import { ApiService } from 'src/app/services/api.service';
import { APIConstant } from "src/app/constants/apiConstants";
import { ValidationMessage } from "src/app/constants/validationMessage";
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/basecomponent/basecomponent.component';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VerifyOtpComponent extends BaseComponent implements OnInit {
  @ViewChild('Ba_Otp1', { static: true }) Ba_Otp1: ElementRef; 
  @ViewChild('Ba_Otp2', { static: true }) Ba_Otp2: ElementRef;
  @ViewChild('Ba_Otp3', { static: true }) Ba_Otp3: ElementRef;
  @ViewChild('Ba_Otp4', { static: true }) Ba_Otp4: ElementRef;
  @ViewChild('Ba_Otp5', { static: true }) Ba_Otp5: ElementRef;
  verify_otp: FormGroup;
  otpVerify = false;
  validationMessage = ValidationMessage;
  isInvalid = false;

  constructor(public _fb: FormBuilder, private apiservice:ApiService,
     private router:Router,private utilityService: UtilityService,) { super(utilityService);}

  ngOnInit() {
    this.Ba_Otp1.nativeElement.focus();
    this.verify_otp = this._fb.group({
      Ba_Otp1: ['', [
        Validators.required,
        Validators.maxLength(1),
        Validators.max(9),
        Validators.min(0),
        Validators.pattern(regExConstant.onlyNumber)
      ]],
      Ba_Otp2: ['', [Validators.required,
      Validators.maxLength(1),
      Validators.max(9),
      Validators.min(0),
      Validators.pattern(regExConstant.onlyNumber)
      ]],
      Ba_Otp3: ['', [Validators.required,
      Validators.maxLength(1),
      Validators.max(9),
      Validators.min(0),
      Validators.pattern(regExConstant.onlyNumber)
      ]],
      Ba_Otp4: ['', [Validators.required,
      Validators.maxLength(1),
      Validators.max(9),
      Validators.min(0),
      Validators.pattern(regExConstant.onlyNumber)
      ]],
    });
  }
  get otpForm() {
    return this.verify_otp.controls;
  }

  verifyOTP(formOtpVerify: FormGroupDirective){
    this.otpVerify = true;
    if (this.verify_otp.invalid) {
      this.isInvalid = true;
      return;
    } else {
      let OTPverifcationCode = this.verify_otp.controls["Ba_Otp1"].value +
        this.verify_otp.controls["Ba_Otp2"].value +
        this.verify_otp.controls["Ba_Otp3"].value +
        this.verify_otp.controls["Ba_Otp4"].value;
      let otpVerifyPayload = {
          otpValue: OTPverifcationCode,
            company: {
              companyId: 1,
              companySlug: "DMI_HFC"
            }
      };
      this.apiservice.post(APIConstant.VERIFY_OTP, otpVerifyPayload, resObj => {
        this.router.navigate(['/reset_password'])
      }, error => {
        this.verify_otp.controls["Ba_Otp1"].setValue('');
        this.verify_otp.controls["Ba_Otp2"].setValue('');
        this.verify_otp.controls["Ba_Otp3"].setValue('');
        this.verify_otp.controls["Ba_Otp4"].setValue('');
        this.Ba_Otp1.nativeElement.focus();
        Swal.fire({
          allowOutsideClick: false,
          text:'just text',
          html:'<img src="assets/images/error.png" class="alertModalImg"><br><p class="errorMsgAlert">'+error.responseMsg+'</p>',
        })
      });
    }
  }
  onKeyUp(otp, otpKey) {
    this.isInvalid = false;
    if (otp != '') {
      switch (otpKey) {
        case 'Ba_Otp1': {
          this.Ba_Otp2.nativeElement.focus();
          this.verify_otp.controls["Ba_Otp2"].setValue('');
          break;
        }
        case 'Ba_Otp2': {
          this.Ba_Otp3.nativeElement.focus();
          this.verify_otp.controls["Ba_Otp3"].setValue('');
          break;
        }
        case 'Ba_Otp3': {
          this.Ba_Otp4.nativeElement.focus();
          this.verify_otp.controls["Ba_Otp4"].setValue('');
          break;
        }
        case 'Ba_Otp4': {
          this.Ba_Otp5.nativeElement.focus();
          break;
        }
        default: {
          this.Ba_Otp1.nativeElement.focus();
          this.verify_otp.controls["Ba_Otp1"].setValue('');
          break;
        }
      }
    }

  }

}
