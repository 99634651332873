<app-dsa-dashboard *ngIf = "isShowDsa"></app-dsa-dashboard>
<ng-container *ngIf="isShowChartDashboard; else sales"><app-chart-dashboard></app-chart-dashboard></ng-container>

<ng-template #sales>

<div class="content-wrapper finalprevi" style="margin: 20px 20px; font-size: 11.8px !important;" *ngIf="!isShowDsa">
  <section class="content based-form" style="background-color: white;"
  *ngIf="formPermissionData.dsasales.isView">
    <div id="myWizard" class="mybasicform_blk">

      <div class="dashboard-nav" >
        <div class="col-auto mt-0">
          <button (click)="zoneDashboard()" mat-raised-button class="login-dashboard-color btn-click-effect">
            <span>Zone</span></button>
        </div>
        <div class="col-auto mt-0" >
          <button (click)="regionDashboard('ALL','')" mat-raised-button
            class="sanction-dashboard-color btn-click-effect">
            <span>Region</span></button>
        </div>

        <div class="col-auto mt-0" >
          <button (click)="branchDashboard('ALL','','')" mat-raised-button
            class="disbursal-dashboard-color btn-click-effect">
            <span>Branch</span></button>
        </div>
        <div class="col-auto mt-0" >
          <button (click)="showDsaDashboard()" mat-raised-button
          *ngIf="formPermissionData.dsasales.componentPrivileges.dsadashboard.isView"
            class="button-1">
            <span>DSA <i class="mdi mdi-view-dashboard passwordIcon"></i></span></button>
        </div>
        <div class="col-auto mt-0" >
          <button (click)="showInorganicDashboard()" mat-raised-button
          *ngIf="formPermissionData.InorganicDashboard.isView"

            class="button-1">
            <span>Inorganic <i class="mdi mdi-view-dashboard passwordIcon"></i></span></button>
        </div>
        <div class="col-auto mt-0" >
          <button (click)="showChartDashboard()" mat-raised-button
            class="button-1">
            <span>Chart Dashboard <i class="mdi mdi-view-dashboard passwordIcon"></i></span></button>
        </div>
        <div class=" col-sm-4 col-auto mt-0" align="right" style="left: 600px;">
          <input type="text" [(ngModel)]="searchText" placeholder='Search' class="dashboard-searchbar">
        </div>
      </div>


      <div class="accordion" id="dashboardAccordion" >
        <div class="card" *ngIf="showZoneData">
          <div class="card-header">
            <h4 class="inte-box-title display_flex col-12 login-dashboard-color" data-toggle="collapse"
              data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Zone
            </h4>

          </div>

          <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#dashboardAccordion">
            <div class="card-body">
              <div class="table-responsive lead-fixed-table-long">
                <table style="font-size: 10px;" id="example" class="table my_bank_table table-bordered mb-4 leadTableScrollable zone-tbl-bg">
                  <thead>
                    <tr>
                      <th rowspan="2">Zone</th>
                      <th colspan="4">FOS</th>
                      <th colspan="6">Login</th>
                      <th colspan="5">Sanction</th>
                      <th colspan="4">Cheque Cut</th>
                      <th colspan="8">Disbursement</th>
                      <th colspan="3">Cheque Handover Pending</th>
                    </tr>
                    <tr>
                      <th>FOS#</th>
                      <th>Zero Login</th>
                      <th>Zero FD</th>
                      <th>Zero CC</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>MTD#</th>
                      <th>MTD Amt</th>
                      <th>WIP#</th>
                      <th>Reject#</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>MTD#</th>
                      <th>MTD Amt</th>
                      <th>SUD</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>FD#</th>
                      <th>MTD Amt</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>MTD#</th>
                      <th>MTD Amt</th>
                      <th>FD#</th>
                      <th>FD Vol</th>
                      <th>HomeLoan</th>
                      <th>Non-HomeLoan</th>
                      <th>Loan#</th>
                      <!-- <th>no of cheque</th> -->
                      <th>Amt</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr style="background: #B0C4DE; font-size: 14px; color: #020202;">
                      <th colspan="30">Organic</th>
                    </tr>
                    <ng-container *ngFor="let zone of zoneDashboardData| grdFilter: {zoneName: searchText} ;let i=index">

                    <tr *ngIf="zone.chequeHandoverCount == 0">
                      <td><a href="#" (click)="regionDashboard('zone',zone.zoneID)">{{zone.zoneName}}</a></td>
                      <td>{{zone.fosnumber | number}}</td>
                      <td>{{zone.zeroLogin | number}}</td>
                      <td>{{zone.zeroFd | number}}</td>
                      <td>{{zone.chequeHandoverOthers | number}}</td>
                      <td>{{zone.loginTodayNumber | number}}</td>
                      <td>{{zone.loginTodayAmount | number}}</td>
                      <td>{{zone.loginMtdNumber | number}}</td>
                      <td>{{zone.loginMtdAmount | number}}</td>
                      <td>{{zone.wipFileNo | number}}</td>
                      <td>{{zone.rejectFileno | number}}</td>
                      <td>{{zone.sanTodayNumber | number}}</td>
                      <td>{{zone.sanTodayAmount | number}}</td>
                      <td>{{zone.sanMtdNumber | number}}</td>
                      <td>{{zone.sanMtdAmount | number}}</td>
                      <td>{{zone.sud | number}}</td>
                      <td>{{zone.chequeCutTdy | number}}</td>
                      <td>{{zone.chequeCutTdyAmt | number}}</td>
                      <td>{{zone.chequeCutMtd | number}}</td>
                      <td>{{zone.chequeCutMtdAmount | number}}</td>
                      <td>{{zone.disTodayNumber | number}}</td>
                      <td>{{zone.disTodayAmount | number}}</td>
                      <td>{{zone.disMtdNumber | number}}</td> 
                      <td>{{zone.disMtdAmount | number}}</td>
                      <td>{{zone.fdnumber | number}}</td>
                      <td>{{zone.fdvolume | number}}</td>
                      <td>{{zone.homeloan | number}}</td>
                      <td>{{zone.nonHomeloan | number}}</td>
                      <td>{{zone.chequeHandoverLoanCount | number}}</td>
                      <!-- <td>{{zone.chequeHandoverOthers | number}}</td> -->
                      <td>{{zone.chequeHandoverPendingAmount | number}}</td>
                    </tr>
                  </ng-container>
                  <tr style="background: #F2F3F4;color: #020202;font-weight: bold;">
                    <td>ORGANIC TOTAL</td>
                    <td>{{ getTotalByType('fosnumber', 0) | number }}</td>
                    <td>{{ getTotalByType('zeroLogin', 0) | number }}</td>
                    <td>{{ getTotalByType('zeroFd', 0) | number }}</td>
                    <td>{{ getTotalByType('chequeHandoverOthers', 0) | number }}</td>
                    <td>{{ getTotalByType('loginTodayNumber', 0) | number }}</td>
                    <td>{{ getTotalByType('loginTodayAmount', 0) | number }}</td>
                    <td>{{ getTotalByType('loginMtdNumber', 0) | number }}</td>
                    <td>{{ getTotalByType('loginMtdAmount', 0) | number }}</td>
                    <td>{{ getTotalByType('wipFileNo', 0) | number }}</td>
                    <td>{{ getTotalByType('rejectFileno', 0) | number }}</td>
                    <td>{{ getTotalByType('sanTodayNumber', 0) | number }}</td>
                    <td>{{ getTotalByType('sanTodayAmount', 0) | number }}</td>
                    <td>{{ getTotalByType('sanMtdNumber', 0) | number }}</td>
                    <td>{{ getTotalByType('sanMtdAmount', 0) | number }}</td>
                    <td>{{ getTotalByType('sud', 0) | number }}</td>
                    <td>{{ getTotalByType('chequeCutTdy', 0) | number }}</td>
                    <td>{{ getTotalByType('chequeCutTdyAmt', 0) | number }}</td>
                    <td>{{ getTotalByType('chequeCutMtd', 0) | number }}</td>
                    <td>{{ getTotalByType('chequeCutMtdAmount', 0) | number }}</td>
                    <td>{{ getTotalByType('disTodayNumber', 0) | number }}</td>
                    <td>{{ getTotalByType('disTodayAmount', 0) | number }}</td>
                    <td>{{ getTotalByType('disMtdNumber', 0) | number }}</td>
                    <td>{{ getTotalByType('disMtdAmount', 0) | number }}</td>
                    <td>{{ getTotalByType('fdnumber', 0) | number }}</td>
                    <td>{{ getTotalByType('fdvolume', 0) | number }}</td>
                    <td>{{ getTotalByType('homeloan', 0) | number }}</td>
                    <td>{{ getTotalByType('nonHomeloan', 0) | number }}</td>
                    <td>{{ getTotalByType('chequeHandoverLoanCount', 0) | number }}</td>
                    <td>{{ getTotalByType('chequeHandoverPendingAmount', 0) | number }}</td>
                  </tr>
                  <tr style="background: #B0C4DE; font-size: 14px; color: #020202;">
                    <th colspan="30">Inorganic</th>
                  </tr>
                  <ng-container *ngFor="let zone of zoneDashboardData | grdFilter: {zoneName: searchText}; let i = index">
                    <tr *ngIf="zone.chequeHandoverCount == 1">
                      <td><a href="#" (click)="regionDashboard('zone', zone.zoneID)">{{zone.zoneName}}</a></td>
                      <td>{{zone.fosnumber | number}}</td>
                      <td>{{zone.zeroLogin | number}}</td>
                      <td>{{zone.zeroFd | number}}</td>
                      <td>{{zone.chequeHandoverOthers | number}}</td>
                      <td>{{zone.loginTodayNumber | number}}</td>
                      <td>{{zone.loginTodayAmount | number}}</td>
                      <td>{{zone.loginMtdNumber | number}}</td>
                      <td>{{zone.loginMtdAmount | number}}</td>
                      <td>{{zone.wipFileNo | number}}</td>
                      <td>{{zone.rejectFileno | number}}</td>
                      <td>{{zone.sanTodayNumber | number}}</td>
                      <td>{{zone.sanTodayAmount | number}}</td>
                      <td>{{zone.sanMtdNumber | number}}</td>
                      <td>{{zone.sanMtdAmount | number}}</td>
                      <td>{{zone.sud | number}}</td>
                      <td>{{zone.chequeCutTdy | number}}</td>
                      <td>{{zone.chequeCutTdyAmt | number}}</td>
                      <td>{{zone.chequeCutMtd | number}}</td>
                      <td>{{zone.chequeCutMtdAmount | number}}</td>
                      <td>{{zone.disTodayNumber | number}}</td>
                      <td>{{zone.disTodayAmount | number}}</td>
                      <td>{{zone.disMtdNumber | number}}</td>                     
                      <td>{{zone.disMtdAmount | number}}</td>
                      <td>{{zone.fdnumber | number}}</td>
                      <td>{{zone.fdvolume | number}}</td>
                      <td>{{zone.homeloan | number}}</td>
                      <td>{{zone.nonHomeloan | number}}</td>
                      <td>{{zone.chequeHandoverLoanCount | number}}</td>
                      <td>{{zone.chequeHandoverPendingAmount | number}}</td>
                    </tr>
                  </ng-container>

                  <!-- Display inorganic total -->
                  <tr style="background: #F2F3F4; font-weight: bold;color: #020202;">
                    <td>INORGANIC TOTAL</td>
                    <td>{{ getTotalByType('fosnumber', 1) | number }}</td>
                    <td>{{ getTotalByType('zeroLogin', 1) | number }}</td>
                    <td>{{ getTotalByType('zeroFd', 1) | number }}</td>
                    <td>{{ getTotalByType('chequeHandoverOthers', 1) | number }}</td>
                    <td>{{ getTotalByType('loginTodayNumber', 1) | number }}</td>
                    <td>{{ getTotalByType('loginTodayAmount', 1) | number }}</td>
                    <td>{{ getTotalByType('loginMtdNumber', 1) | number }}</td>
                    <td>{{ getTotalByType('loginMtdAmount', 1) | number }}</td>
                    <td>{{ getTotalByType('wipFileNo', 1) | number }}</td>
                    <td>{{ getTotalByType('rejectFileno', 1) | number }}</td>
                    <td>{{ getTotalByType('sanTodayNumber', 1) | number }}</td>
                    <td>{{ getTotalByType('sanTodayAmount', 1) | number }}</td>
                    <td>{{ getTotalByType('sanMtdNumber', 1) | number }}</td>
                    <td>{{ getTotalByType('sanMtdAmount', 1) | number }}</td>
                    <td>{{ getTotalByType('sud', 1) | number }}</td>
                    <td>{{ getTotalByType('chequeCutTdy', 1) | number }}</td>
                    <td>{{ getTotalByType('chequeCutTdyAmt', 1) | number }}</td>
                    <td>{{ getTotalByType('chequeCutMtd', 1) | number }}</td>
                    <td>{{ getTotalByType('chequeCutMtdAmount', 1) | number }}</td>
                    <td>{{ getTotalByType('disTodayNumber', 1) | number }}</td>
                    <td>{{ getTotalByType('disTodayAmount', 1) | number }}</td>
                    <td>{{ getTotalByType('disMtdNumber', 1) | number }}</td>
                    
                    <td>{{ getTotalByType('disMtdAmount', 1) | number }}</td>
                    <td>{{ getTotalByType('fdnumber', 1) | number }}</td>
                    <td>{{ getTotalByType('fdvolume', 1) | number }}</td>
                    <td>{{ getTotalByType('homeloan', 1) | number }}</td>
                    <td>{{ getTotalByType('nonHomeloan', 1) | number }}</td>
                    <td>{{ getTotalByType('chequeHandoverLoanCount', 1) | number }}</td>
                    <td>{{ getTotalByType('chequeHandoverPendingAmount', 1) | number }}</td>
                  </tr>
                    <tr style="background: #E6E6E6; font-weight: bold;">
                      <td>TOTAL</td>
                      <td>{{zoneDashboardData | total:'fosnumber' | number}}</td>
                      <td>{{zoneDashboardData | total: 'zeroLogin' | number}}</td>
                      <td>{{zoneDashboardData | total: 'zeroFd' | number}}</td>
                      <td>{{zoneDashboardData | total:'chequeHandoverOthers' | number}}</td>
                      <td>{{zoneDashboardData | total:'loginTodayNumber' | number}}</td>
                      <td>{{zoneDashboardData | total:'loginTodayAmount' | number}}</td>
                      <td>{{zoneDashboardData | total:'loginMtdNumber' | number}}</td>
                      <td>{{zoneDashboardData | total:'loginMtdAmount' | number}}</td>
                      <td>{{zoneDashboardData | total: 'wipFileNo' | number}}</td>
                      <td>{{zoneDashboardData | total: 'rejectFileno' | number}}</td>
                      <td>{{zoneDashboardData | total:'sanTodayNumber' | number}}</td>
                      <td>{{zoneDashboardData | total:'sanTodayAmount' | number}}</td>
                      <td>{{zoneDashboardData | total:'sanMtdNumber' | number}}</td>
                      <td>{{zoneDashboardData | total:'sanMtdAmount' | number}}</td>
                      <td>{{zoneDashboardData | total:'sud' | number}}</td>
                      <td>{{zoneDashboardData | total:'chequeCutTdy' | number}}</td>
                      <td>{{zoneDashboardData | total:'chequeCutTdyAmt' | number}}</td>
                      <td>{{zoneDashboardData | total:'chequeCutMtd' | number}}</td>
                      <td>{{zoneDashboardData | total:'chequeCutMtdAmount' | number}}</td>
                      <td>{{zoneDashboardData | total:'disTodayNumber' | number}}</td>
                      <td>{{zoneDashboardData | total:'disTodayAmount' | number}}</td>
                      <td>{{zoneDashboardData | total:'disMtdNumber' | number}}</td>
                      <td>{{zoneDashboardData | total:'disMtdAmount' | number}}</td>
                      <td>{{zoneDashboardData | total:'fdnumber' | number}}</td>
                      <td>{{zoneDashboardData | total:'fdvolume' | number}}</td>
                      <td>{{zoneDashboardData | total:'homeloan' | number}}</td>
                      <td>{{zoneDashboardData | total:'nonHomeloan' | number}}</td>
                      <td>{{zoneDashboardData | total:'chequeHandoverLoanCount' | number}}</td>
                      <!-- <td>{{zoneDashboardData | total:'chequeHandoverOthers' | number}}</td> -->
                      <td>{{zoneDashboardData | total:'chequeHandoverPendingAmount' | number}}</td>
                    </tr>


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="showRegionData">
          <div class="card-header" id="headingTwo">
            <h4 class="inte-box-title display_flex col-12 sanction-dashboard-color" data-toggle="collapse"
              data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              {{showRegionName}}
            </h4>
          </div>
          <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#dashboardAccordion">
            <div class="card-body" *ngIf="showRegionData">
              <div class="table-responsive lead-fixed-table-long">
                <table  style="font-size: 10px;" id="example" class="table my_bank_table table-bordered mb-4 leadTableScrollable region-tbl-bg">
                  <thead>
                    <tr>

                      <th rowspan="2">Region</th>
                      <th colspan="4">FOS</th>
                      <th colspan="6">Login</th>
                      <th colspan="5">Sanction</th>
                      <th colspan="4">Cheque Cut</th>
                      <th colspan="8">Disbursement</th>
                      <th colspan="3">Cheque Handover Pending</th>
                    </tr>
                    <tr>
                      <th>FOS#</th>
                      <th>Zero Login</th>
                      <th>Zero FD</th>
                      <th>Zero CC</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>WIP#</th>
                      <th>Reject#</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>SUD</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>FD#</th>
                      <th>MTD Amt</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>FD#</th>
                      <th>FD Vol</th>
                      <th>HomeLoan</th>
                      <th>Non-HomeLoan</th>
                      
                      <th>Loan#</th>
                      <!-- <th>no of cheque</th> -->
                      <th>Amt</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let region of regionDashboardData| grdFilter: {regionName: searchText} ;let i=index">

                      <td><a href="#"
                          (click)="branchDashboard('region',region.regionID,region.zoneID)">{{region.regionName}}</a>
                      </td>
                      <td>{{region.fosnumber | number}}</td>
                      <td>{{region.zeroLogin | number}}</td>
                      <td>{{region.zeroFd | number}}</td>
                      <td>{{region.chequeHandoverOthers | number}}</td>
                      <td>{{region.loginTodayNumber | number}}</td>
                      <td>{{region.loginTodayAmount | number}}</td>
                      <td>{{region.loginMtdNumber | number}}</td>
                      <td>{{region.loginMtdAmount | number}}</td>
                      <td>{{region.wipFileNo | number}}</td>
                      <td>{{region.rejectFileno | number}}</td>
                      <td>{{region.sanTodayNumber | number}}</td>
                      <td>{{region.sanTodayAmount | number}}</td>
                      <td>{{region.sanMtdNumber | number}}</td>
                      <td>{{region.sanMtdAmount | number}}</td>
                      <td>{{region.sud | number}}</td>
                      <td>{{region.chequeCutTdy | number}}</td>
                      <td>{{region.chequeCutTdyAmt | number}}</td>
                      <td>{{region.chequeCutMtd | number}}</td>
                      <td>{{region.chequeCutMtdAmount | number}}</td>
                      <td>{{region.disTodayNumber | number}}</td>
                      <td>{{region.disTodayAmount | number}}</td>
                      <td>{{region.disMtdNumber | number}}</td>
                      <td>{{region.disMtdAmount | number}}</td>
                      <td>{{region.fdnumber | number}}</td>
                      <td>{{region.fdvolume | number}}</td>
                      <td>{{region.homeloan | number}}</td>
                      <td>{{region.nonHomeloan | number}}</td>
                      <td>{{region.chequeHandoverLoanCount | number}}</td>
                      <!-- <td>{{region.chequeHandoverOthers | number}}</td> -->
                      <td>{{region.chequeHandoverPendingAmount | number}}</td>
                    </tr>
                    <tr style="background: #8bf1ae; font-weight: bold;">
                      <td>TOTAL</td>
                      <td>{{regionDashboardData | total:'fosnumber' | number}}</td>
                      <td>{{regionDashboardData | total:'zeroLogin' | number}}</td>
                      <td>{{regionDashboardData | total:'zeroFd' | number}}</td>
                      <td>{{regionDashboardData | total:'chequeHandoverOthers' | number}}</td>
                      <td>{{regionDashboardData| total:'loginTodayNumber' | number}}</td>
                      <td>{{regionDashboardData |total:'loginTodayAmount' | number}}</td>
                      <td>{{regionDashboardData | total:'loginMtdNumber' | number}}</td>
                      <td>{{regionDashboardData | total:'loginMtdAmount' | number}}</td>
                      <td>{{regionDashboardData | total:'wipFileNo' | number}}</td>
                      <td>{{regionDashboardData | total:'rejectFileno' | number}}</td>
                      <td>{{regionDashboardData | total:'sanTodayNumber' | number}}</td>
                      <td>{{regionDashboardData | total:'sanTodayAmount' | number}}</td>
                      <td>{{regionDashboardData | total:'sanMtdNumber' | number}}</td>
                      <td>{{regionDashboardData | total:'sanMtdAmount' | number}}</td>
                      <td>{{regionDashboardData | total:'sud' | number}}</td>
                      <td>{{regionDashboardData | total:'chequeCutTdy' | number}}</td>
                      <td>{{regionDashboardData | total:'chequeCutTdyAmt' | number}}</td>
                      <td>{{regionDashboardData | total:'chequeCutMtd' | number}}</td>
                      <td>{{regionDashboardData | total:'chequeCutMtdAmount' | number}}</td>
                      <td>{{regionDashboardData | total:'disTodayNumber' | number}}</td>
                      <td>{{regionDashboardData | total:'disTodayAmount' | number}}</td>
                      <td>{{regionDashboardData | total:'disMtdNumber' | number}}</td>
                      <td>{{regionDashboardData | total:'disMtdAmount' | number}}</td>
                      <td>{{regionDashboardData | total:'fdnumber' | number}}</td>
                      <td>{{regionDashboardData | total:'fdvolume' | number}}</td>
                      <td>{{regionDashboardData | total:'homeloan' | number}}</td>
                      <td>{{regionDashboardData | total:'nonHomeloan' | number}}</td>
                      <td>{{regionDashboardData | total:'chequeHandoverLoanCount' | number}}</td>
                      <!-- <td>{{regionDashboardData | total:'chequeHandoverOthers' | number}}</td> -->
                      <td>{{regionDashboardData | total:'chequeHandoverPendingAmount' | number}}</td>


                    </tr>

                  </tbody>
                </table>
              </div>
              <div class="col-auto mt-0 mb-4" *ngIf="!(showRegionName=='All Region')">
                <button (click)="zoneDashboard()" mat-raised-button class="login-dashboard-color btn-click-effect">
                  <span>Back</span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="showBranchData">
          <div class="card-header" id="headingThree">
            <h5 class="inte-box-title display_flex col-12 disbursal-dashboard-color" data-toggle="collapse"
              data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              {{showBranchName}}
            </h5>
          </div>
          <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
            data-parent="#dashboardAccordion">
            <div class="card-body">
              <div class="table-responsive lead-fixed-table-long">
                <table style="font-size: 10px;" id="example" class="table my_bank_table table-bordered mb-4 leadTableScrollable branch-tbl-bg">
                  <thead>
                    <tr>

                      <th rowspan="2">Branch</th>
                      <th colspan="4">FOS</th>
                      <th colspan="6">Login</th>
                      <th colspan="5">Sanction</th>
                      <th colspan="4">Cheque Cut Heading</th>

                      <th colspan="8">Disbursement</th>
                      <th colspan="3">Cheque Handover Pending</th>
                    </tr>
                    <tr>
                      <th>FOS#</th>
                      <th>Zero Login</th>
                      <th>Zero FD</th>
                      <th>Zero CC</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>WIP#</th>
                      <th>Reject#</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>SUD</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>FD#</th>
                      <th>MTD Amt</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>FD#</th>
                      <th>FD Vol</th>
                      <th>HomeLoan</th>
                      <th>Non-HomeLoan</th>                     
                      <th>Loan#</th>
                      <!-- <th>no of cheque</th> -->
                      <th>Amt</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let branch of branchDashboardData| grdFilter: {branchName: searchText};let i=index">

                      <td><a href="#" (click)="getFOSData(branch.branchID)">{{branch.branchName}}</a></td>
                      <td>{{branch.fosnumber | number}}</td>
                      <td>{{branch.zeroLogin | number}}</td>
                      <td>{{branch.zeroFd | number}}</td>
                      <td>{{branch.chequeHandoverOthers | number}}</td>
                      <td>{{branch.loginTodayNumber | number}}</td>
                      <td>{{branch.loginTodayAmount | number}}</td>
                      <td>{{branch.loginMtdNumber | number}}</td>
                      <td>{{branch.loginMtdAmount | number}}</td>
                      <td>{{branch.wipFileNo | number}}</td>
                      <td>{{branch.rejectFileno | number}}</td>
                      <td>{{branch.sanTodayNumber | number}}</td>
                      <td>{{branch.sanTodayAmount | number}}</td>
                      <td>{{branch.sanMtdNumber | number}}</td>
                      <td>{{branch.sanMtdAmount | number}}</td>
                      <td>{{branch.sud | number}}</td>
                      <td>{{branch.chequeCutTdy | number}}</td>
                      <td>{{branch.chequeCutTdyAmt | number}}</td>
                      <td>{{branch.chequeCutMtd | number}}</td>
                      <td>{{branch.chequeCutMtdAmount | number}}</td>
                      <td>{{branch.disTodayNumber | number}}</td>
                      <td>{{branch.disTodayAmount | number}}</td>
                      <td>{{branch.disMtdNumber | number}}</td>
                      <td>{{branch.disMtdAmount | number}}</td>
                      <td>{{branch.fdnumber | number}}</td>
                      <td>{{branch.fdvolume | number}}</td>
                      <td>{{branch.homeloan | number}}</td>
                      <td>{{branch.nonHomeloan | number}}</td>
                      <td>{{branch.chequeHandoverLoanCount | number}}</td>
                      <!-- <td>{{branch.chequeHandoverOthers | number}}</td> -->
                      <td>{{branch.chequeHandoverPendingAmount | number}}</td>

                    </tr>
                    <tr style="background: #020202; font-weight: bold; color: #fff;">
                      <td>TOTAL</td>
                      <td>{{branchDashboardData | total:'fosnumber' | number}}</td>
                      <td>{{branchDashboardData | total:'zeroLogin' | number}}</td>
                      <td>{{branchDashboardData | total:'zeroFd' | number}}</td>
                      <td>{{branchDashboardData | total:'chequeHandoverOthers' | number}}</td>
                      <td>{{branchDashboardData| total:'loginTodayNumber' | number}}</td>
                      <td>{{branchDashboardData | total:'loginTodayAmount' | number}}</td>
                      <td>{{branchDashboardData | total:'loginMtdNumber' | number}}</td>
                      <td>{{branchDashboardData | total:'loginMtdAmount' | number}}</td>
                      <td>{{branchDashboardData | total:'wipFileNo' | number}}</td>
                      <td>{{branchDashboardData | total:'rejectFileno' | number}}</td>
                      <td>{{branchDashboardData | total:'sanTodayNumber' | number}}</td>
                      <td>{{branchDashboardData | total:'sanTodayAmount' | number}}</td>
                      <td>{{branchDashboardData | total:'sanMtdNumber' | number}}</td>
                      <td>{{branchDashboardData | total:'sanMtdAmount' | number}}</td>
                      <td>{{branchDashboardData | total:'sud' | number}}</td>
                      <td>{{branchDashboardData | total:'chequeCutTdy' | number}}</td>
                      <td>{{branchDashboardData | total:'chequeCutTdyAmt' | number}}</td>
                      <td>{{branchDashboardData | total:'chequeCutMtd' | number}}</td>
                      <td>{{branchDashboardData | total:'chequeCutMtdAmount' | number}}</td>
                      <td>{{branchDashboardData | total:'disTodayNumber' | number}}</td>
                      <td>{{branchDashboardData | total:'disTodayAmount' | number}}</td>
                      <td>{{branchDashboardData | total:'disMtdNumber' | number}}</td>
                      <td>{{branchDashboardData | total:'disMtdAmount' | number}}</td>
                      <td>{{branchDashboardData | total:'fdnumber' | number}}</td>
                      <td>{{branchDashboardData | total:'fdvolume' | number}}</td>
                      <td>{{branchDashboardData | total:'homeloan' | number}}</td>
                      <td>{{branchDashboardData | total:'nonHomeloan' | number}}</td>
                      <td>{{branchDashboardData | total:'chequeHandoverLoanCount' | number}}</td>
                      <!-- <td>{{branchDashboardData | total:'chequeHandoverOthers' | number}}</td> -->
                      <td>{{branchDashboardData | total:'chequeHandoverPendingAmount' | number}}</td>


                    </tr>

                  </tbody>
                </table>
              </div>
              <div class="col-auto mt-0 mb-4" *ngIf="!(showBranchName=='All Branch')">
                <button (click)="regionDashboard(currentType,currentZoneID)" mat-raised-button
                  class="login-dashboard-color btn-click-effect">
                  <span>Back</span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="showFOSData">
          <div class="card-header" id="headingThree">
            <h5 class="inte-box-title display_flex col-12 disbursal-dashboard-color" data-toggle="collapse"
              data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              {{showFOSName}}
            </h5>
          </div>
          <div id="collapseThree" class="collapse show" aria-labelledby="headingThree"
            data-parent="#dashboardAccordion">
            <div class="card-body">
              <div class="table-responsive lead-fixed-table-long">
                <table style="font-size: 10px;" id="example" class="table my_bank_table table-bordered mb-4 leadTableScrollable branch-tbl-bg">
                  <thead>
                    <tr>

                      <th rowspan="2">EmployeeName</th>
                      <th colspan="4">Login</th>
                      <th colspan="5">Sanction</th>
                      <th colspan="4">Cheque Cut Heading</th>

                      <th colspan="8">Disbursement</th>
                      <th colspan="3">Cheque Handover Pending</th>
                    </tr>
                    <tr>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>SUD</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>MTD#</th>
                      <th>MTD Amt</th>
                      <th>Tdy#</th>
                      <th>Tdy Amt</th>
                      <th>Mtd#</th>
                      <th>Mtd Amt</th>
                      <th>FD#</th>
                      <th>FD Vol</th>
                      <th>HomeLoan</th>
                      <th>Non-HomeLoan</th>
                      
                      <th>Loan#</th>
                      <!-- <th>no of cheque</th> -->
                      <th>Amt</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let fosdata of FOSDashboardData| grdFilter: {employeeName: searchText};let i=index">

                      <td>{{fosdata.employeeName}}</td>
                      <td>{{fosdata.loginTodayNumber | number}}</td>
                      <td>{{fosdata.loginTodayAmount | number}}</td>
                      <td>{{fosdata.loginMtdNumber | number}}</td>
                      <td>{{fosdata.loginMtdAmount | number}}</td>
                      <td>{{fosdata.sanTodayNumber | number}}</td>
                      <td>{{fosdata.sanTodayAmount | number}}</td>
                      <td>{{fosdata.sanMtdNumber | number}}</td>
                      <td>{{fosdata.sanMtdAmount | number}}</td>
                      <td>{{fosdata.sud | number}}</td>
                      <td>{{fosdata.chequeCutTdy | number}}</td>
                      <td>{{fosdata.chequeCutTdyAmt | number}}</td>
                      <td>{{fosdata.chequeCutMtd | number}}</td>
                      <td>{{fosdata.chequeCutMtdAmount | number}}</td>
                      <td>{{fosdata.disTodayNumber | number}}</td>
                      <td>{{fosdata.disTodayAmount | number}}</td>
                      <td>{{fosdata.disMtdNumber | number}}</td>
                      <td>{{fosdata.disMtdAmount | number}}</td>
                      <td>{{fosdata.fdnumber | number}}</td>
                      <td>{{fosdata.fdvolume | number}}</td>
                      <td>{{fosdata.homeloan | number}}</td>
                      <td>{{fosdata.nonHomeloan | number}}</td>
                     
                      <td>{{fosdata.chequeHandoverLoanCount | number}}</td>
                      <!-- <td>{{fosdata.chequeHandoverOthers | number}}</td> -->
                      <td>{{fosdata.chequeHandoverPendingAmount | number}}</td>
                    </tr>
                    <tr style="background: #8bf1ae; font-weight: bold;">
                      <td>TOTAL</td>
                      <td>{{FOSDashboardData | total:'loginTodayNumber' | number}}</td>
                      <td>{{FOSDashboardData | total:'loginTodayAmount' | number}}</td>
                      <td>{{FOSDashboardData | total:'loginMtdNumber' | number}}</td>
                      <td>{{FOSDashboardData | total:'loginMtdAmount' | number}}</td>
                      <td>{{FOSDashboardData | total:'sanTodayNumber' | number}}</td>
                      <td>{{FOSDashboardData | total:'sanTodayAmount' | number}}</td>
                      <td>{{FOSDashboardData | total:'sanMtdNumber' | number}}</td>
                      <td>{{FOSDashboardData | total:'sanMtdAmount' | number}}</td>
                      <td>{{FOSDashboardData | total:'sud' | number}}</td>
                      <td>{{FOSDashboardData | total:'chequeCutTdy' | number}}</td>
                      <td>{{FOSDashboardData | total:'chequeCutTdyAmt' | number}}</td>
                      <td>{{FOSDashboardData | total:'chequeCutMtd' | number}}</td>
                      <td>{{FOSDashboardData | total:'chequeCutMtdAmount' | number}}</td>
                      <td>{{FOSDashboardData | total:'disTodayNumber' | number}}</td>
                      <td>{{FOSDashboardData | total:'disTodayAmount' | number}}</td>
                      <td>{{FOSDashboardData | total:'disMtdNumber' | number}}</td>
                      <td>{{FOSDashboardData | total:'disMtdAmount' | number}}</td>
                      <td>{{FOSDashboardData | total:'fdnumber' | number}}</td>
                      <td>{{FOSDashboardData | total:'fdvolume' | number}}</td>
                      <td>{{FOSDashboardData | total:'homeloan' | number}}</td>
                      <td>{{FOSDashboardData | total:'nonHomeloan' | number}}</td>
                      <td>{{FOSDashboardData | total:'chequeHandoverLoanCount' | number}}</td>
                      <!-- <td>{{FOSDashboardData | total:'chequeHandoverOthers' | number}}</td> -->
                      <td>{{FOSDashboardData | total:'chequeHandoverPendingAmount' | number}}</td>


                    </tr>

                  </tbody>
                </table>
              </div>
              <div class="col-auto mt-0 mb-4">
                <button (click)="branchDashboard(currentRegionType,currentRegionID,currentFOSZoneID)" mat-raised-button
                  class="login-dashboard-color btn-click-effect">
                  <span>Back</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
</ng-template>
