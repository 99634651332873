<mat-dialog-content style="font-size: 16px !important;">
	<div class="confirmDialog-box-img">
	<img src="assets/images/info.png" class="info-img">
	<p>{{message}}</p>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="center" style="padding-bottom: 25px !important;">
	<button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button *ngIf="cancelButtonText != 'hide'" mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>
