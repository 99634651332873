import { NgModule } from "@angular/core";

import { IndianCurrencyPipe } from 'src/app/pipes/indian-currency.pipe';
import { GrdFilterPipe } from 'src/app/pipes/grd-filter.pipe';
import { CustomTitlePipe } from './custom-title.pipe';
import { NumberToWordPipe } from "./number-to-word.pipe";


@NgModule({
  declarations: [
    IndianCurrencyPipe,
    GrdFilterPipe,
    NumberToWordPipe,
    CustomTitlePipe
  ],
  imports: [],
  providers: [IndianCurrencyPipe,GrdFilterPipe],
  exports: [
    IndianCurrencyPipe,
    GrdFilterPipe,
    NumberToWordPipe,
    CustomTitlePipe
  ]
})
export class CustomPipeModule {}
