import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from "src/app/shared/material";
import { ChartsModule } from "ng2-charts";

import { ChartDashboardComponent } from 'src/app/pages/chart-dashboard/chart-dashboard.component';

@NgModule({
  declarations: [
    ChartDashboardComponent
  ],
  imports: [
    MaterialModule,
    ChartsModule,
    CommonModule
  ],
  exports:[
    ChartDashboardComponent
  ]
})
export class ShareableModule { }
